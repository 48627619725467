import { Job } from '@flatfile/api'
import {
  Button,
  ModalButtonRow,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'

export const NoListenerModalContent = ({
  job,
  deleteJob,
}: {
  job: Job
  deleteJob: () => void
}) => {
  return (
    <>
      <ModalHeader>Job started</ModalHeader>
      <ModalText>
        A job with operation <code>{job.operation}</code> has been initiated
        from an action, but no listener has been configured to respond to it.{' '}
        <a
          href='https://flatfile.com/docs/guides/actions'
          target='_blank'
          rel='noreferrer'
        >
          Set up a listener
        </a>{' '}
        to define what happens next.
      </ModalText>
      <ModalButtonRow>
        <Button
          type='reset'
          variant='ghost'
          onPress={() => deleteJob()}
          label={'Cancel job'}
        />
        <Button
          type='submit'
          onPress={() =>
            window.open(
              'https://flatfile.com/docs/guides/custom-actions',
              '_blank'
            )
          }
          label={'Learn more'}
        />
      </ModalButtonRow>
    </>
  )
}
