import Skeleton from 'react-loading-skeleton'
import { Container } from '../../elements/Container'
import { ContentContainer } from '../../elements/ContentContainer'
import { Expand } from '../../elements/Expand'
import { SpacePageLayoutContainer } from '../SpacePageLayout'
import { ContentSkeleton } from './ContentSkeleton'
import {
  SidebarSkeletonContainer,
  SidebarSkeletonContents,
  SidebarSkeletonSection,
} from './elements'

export const SidebarSkeleton = () => {
  return (
    <SidebarSkeletonContents>
      <SidebarSkeletonSection>
        <h1>
          <Skeleton baseColor='var(--color-skeleton)' />
        </h1>
        <p>
          <Skeleton baseColor='var(--color-skeleton)' />
        </p>
      </SidebarSkeletonSection>
      <hr />
      <SidebarSkeletonSection>
        <p>
          <Skeleton baseColor='var(--color-skeleton)' />
        </p>
        <p>
          <Skeleton baseColor='var(--color-skeleton)' />
        </p>
      </SidebarSkeletonSection>
      <hr />
      <SidebarSkeletonSection>
        <p>
          <Skeleton baseColor='var(--color-skeleton)' />
        </p>
        <p>
          <Skeleton baseColor='var(--color-skeleton)' />
        </p>
      </SidebarSkeletonSection>
    </SidebarSkeletonContents>
  )
}

export const SpacesUISkeleton = (title?: string, message?: string) => {
  const content = ContentSkeleton(title, message)
  return (
    <SpacePageLayoutContainer>
      <SidebarSkeletonContainer>
        <SidebarSkeleton />
      </SidebarSkeletonContainer>
      <ContentContainer>
        <Expand>
          <Container>{content}</Container>
        </Expand>
      </ContentContainer>
    </SpacePageLayoutContainer>
  )
}
