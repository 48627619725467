import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { Platform } from '../packages/HttpClient'
import {
  RuntimeParameters,
  hasExpired,
  setRuntimeToken,
} from '../packages/RuntimeParameters'
import { useRouteParams } from './useRouteParams'

export const useGuestToken = () => {
  let guestEmail: string
  let tokenHasExpired: boolean | undefined = undefined
  const search = window?.location.search
  const { isGuest, accessToken } = RuntimeParameters()
  const { spaceId } = useRouteParams()

  if (search.substring(0, 7) === '?email=') {
    guestEmail = search.substring(7)
  }
  tokenHasExpired = hasExpired(accessToken)

  const {
    mutate: refreshToken,
    data: refreshTokenResponse,
    isError: refreshTokenError,
    isLoading: refreshTokenLoading,
  } = useMutation(['exchangeInvitation'], () =>
    Platform.exchangeInvitation({
      exchangeInvitationRequest: {
        token: guestEmail ? '' : accessToken,
        email: guestEmail,
        spaceId,
      },
    })
  )

  useEffect(() => {
    //we should only call exchangeInvitation for magic link or if token has expired
    if ((isGuest && accessToken && tokenHasExpired) || guestEmail) {
      refreshToken()
    }
  }, [])

  useEffect(() => {
    if (
      accessToken &&
      refreshTokenResponse?.data &&
      refreshTokenResponse?.data.valid &&
      refreshTokenResponse?.data.token
    ) {
      setRuntimeToken(refreshTokenResponse?.data.token)
      window?.location.assign(window?.location.href.split('?')[0])
    }
  }, [refreshToken, refreshTokenResponse])

  const { isLoading, isRefetching } = useQuery(
    ['getSpaceAuth', spaceId],
    /* c8 ignore start */
    // ignoring this line because it will never run the query without spaceId
    () => (spaceId ? Platform.getSpaceAuth({ spaceId }) : undefined),
    /* c8 ignore stop */
    {
      enabled: isGuest && !!spaceId,
      onSuccess: (data) => {
        if (data?.data?.accessToken) {
          setRuntimeToken(data.data.accessToken)
        }
      },
    }
  )

  const guestTokenLoading = useMemo(
    () => isGuest && (isLoading || isRefetching),
    [isGuest, isLoading, isRefetching]
  )

  return {
    refreshTokenResponse,
    refreshTokenError,
    refreshTokenLoading,
    guestTokenLoading,
  }
}
