import { Banner, ProgressBar, Typography } from '@flatfile/design-system'
import { useEffect, useState } from 'react'
import Markdown from 'react-remarkable'
import { SearchFields } from '../../../default-apps/WorkbookApp/hooks/useSearchFilters'
import { useAssistant } from '../../../hooks/useAssistant'
import { CommandMenuPanelFooter } from '../CommandMenuPanelFooter/CommandMenuPanelFooter'
import { parseCommand } from '../utils'
import { EllipsisMessage } from './EllipsisMessage'
import { Code, Explain, MutationWarning } from './styles'

export const CommandMenuAiTransformPanel = ({
  value,
  onValueChange,
  searchFields,
  selection,
  dismissHandler,
  setInputDisabled,
  setInputFocus,
  keyboardSubmitActivated,
  deactivateKeyboardSubmit,
}: {
  value: string
  onValueChange: (value: string) => void
  searchFields: SearchFields
  selection: {
    type: 'all' | 'none'
    exceptions: string[]
  }
  dismissHandler: () => void
  setInputDisabled: (disabled: boolean) => void
  setInputFocus: () => void
  keyboardSubmitActivated: boolean
  deactivateKeyboardSubmit: () => void
}) => {
  enum PanelState {
    ReadyToAsk,
    Asking,
    ReadyToApply,
    Applying,
    DoneApplying,
  }

  const [panelState, setPanelState] = useState(PanelState.ReadyToAsk)

  const {
    ask,
    apply,
    mutationResponse,
    mutationIsError,
    mutationIsSuccess,
    applyIsError,
    applyIsSuccess,
    isError,
  } = useAssistant()

  const mutationData = mutationResponse?.data
  const mutateRecord = mutationData?.mutateRecord
  const mutationId = mutationData?.mutationId

  const mutationMetadata = mutationData?.metadata || { explain: '' }
  const explainMutation = mutationMetadata.explain

  useEffect(() => {
    if (keyboardSubmitActivated && panelState === PanelState.ReadyToAsk) {
      askForMutation()
      deactivateKeyboardSubmit()
    } else if (
      keyboardSubmitActivated &&
      panelState === PanelState.ReadyToApply
    ) {
      applyMutation()
      deactivateKeyboardSubmit()
    }
  }, [keyboardSubmitActivated, panelState])

  // When there's an error, re-enable the input
  useEffect(() => {
    if (mutationIsError || applyIsError) {
      setInputDisabled(false)
    }
  }, [mutationIsError, applyIsError])

  // What happens when we send a mutation request to the assistant?
  const askForMutation = () => {
    /* c8 ignore next */
    if (!value) return

    setPanelState(PanelState.Asking)
    setInputDisabled(true)

    const { command, prompt } = parseCommand(value)
    ask(prompt)
  }

  // What happens when the assistant successfully generates a mutation?
  const afterSuccessfulAskForMutation = () => {
    setPanelState(PanelState.ReadyToApply)
    setInputDisabled(false)
    setInputFocus()
  }

  // What happens when we tell the assistant to apply the mutation?
  const applyMutation = async () => {
    if (mutateRecord) {
      setPanelState(PanelState.Applying)
      setInputDisabled(true)
      apply(mutateRecord, mutationId, searchFields, selection)
    }
  }

  // What happens when the assistant is done applying the mutation?
  const afterSuccessfulApplyMutation = () => {
    setPanelState(PanelState.DoneApplying)
    setInputDisabled(false)

    dismissHandler()

    // Wipe out the value
    setPanelState(PanelState.ReadyToAsk)
    onValueChange('')
  }

  // Advance the panel state when an operation succeeds
  useEffect(() => {
    if (panelState === PanelState.Asking && mutationIsSuccess) {
      afterSuccessfulAskForMutation()
    } else if (panelState === PanelState.Applying && applyIsSuccess) {
      afterSuccessfulApplyMutation()
    }
  }, [panelState, mutationIsSuccess, applyIsSuccess])

  const messages = ['Thinking', 'Writing', 'Doing', 'Finishing']
  const instruction =
    "Describe how you'd like the AI assistant to transform your data:"

  // If the user changes the value, reset the panel state
  useEffect(() => {
    setPanelState(PanelState.ReadyToAsk)
  }, [value])

  return (
    <>
      {panelState === PanelState.ReadyToAsk && (
        <>
          <Typography type='actionMd' color='var(--color-primary)'>
            {instruction}
          </Typography>
          <CommandMenuPanelFooter
            onApply={askForMutation}
            onCancel={dismissHandler}
          />
        </>
      )}

      {(mutationIsError || applyIsError) &&
        (panelState === PanelState.Asking ||
          panelState === PanelState.Applying) && (
          <Banner
            title='Something went wrong. Please try again.'
            variant='error'
          />
        )}

      {panelState === PanelState.Asking && !isError && (
        <>
          <Typography type='actionMd' color='var(--color-primary)'>
            <EllipsisMessage messages={messages} interval={7000} />
          </Typography>
          <ProgressBar />
        </>
      )}

      {panelState === PanelState.ReadyToApply && (
        <>
          <Explain type='actionMd' color='var(--color-text-ultralight)'>
            Here's what I came up with. Read the step by step explanation and
            the code, and if it looks good, click below to apply it.
          </Explain>
          <Typography type='actionMd' color='var(--color-primary)'>
            Code
          </Typography>
          <Code>
            <Markdown source={explainMutation} options={{ html: false }} />
          </Code>
          <MutationWarning>
            Warning: this will modify your data.
          </MutationWarning>
          <CommandMenuPanelFooter
            onApply={applyMutation}
            onCancel={dismissHandler}
          />
        </>
      )}

      {panelState === PanelState.Applying && !isError && (
        <>
          <Typography type='actionMd' color='var(--color-primary)'>
            <EllipsisMessage
              messages={['Applying to your dataset']}
              interval={1}
            />
          </Typography>
          <ProgressBar />
        </>
      )}
    </>
  )
}
