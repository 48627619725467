import { ErrorState } from '../../elements/EmptyState'
import { SpacesUISkeleton } from '../../packages/SpacesUISkeleton'
import { useGuestToken } from '../../hooks/useGuestToken'
import { SpaceApp } from './app'
import { TokenExpirationPage } from './TokenExpirationPage'
import { useQuery } from '@tanstack/react-query'
import { EventSubscriber } from '../../packages/EventSubscriber'
import React from 'react'
import { PubNubProvider } from 'pubnub-react'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useTranslation } from 'react-i18next'

export function SpaceLoader() {
  const {
    refreshTokenResponse,
    refreshTokenError,
    refreshTokenLoading,
    guestTokenLoading,
  } = useGuestToken()

  const { spaceId } = useRouteParams()
  const { t } = useTranslation()
  const { data: pubnub, isLoading } = useQuery(
    ['getPubNubClient', spaceId],
    () => (spaceId ? EventSubscriber.getClient(spaceId) : undefined),
    { enabled: !!spaceId }
  )

  if (refreshTokenLoading || isLoading || guestTokenLoading) {
    return SpacesUISkeleton()
  }

  if (refreshTokenResponse && refreshTokenResponse.data?.sentTo) {
    return <TokenExpirationPage email={refreshTokenResponse.data.sentTo} />
  }

  if (refreshTokenError) {
    return <ErrorState message={t('errors.unauthorized')} />
  }

  return pubnub ? (
    <PubNubProvider client={pubnub}>
      <SpaceApp />
    </PubNubProvider>
  ) : (
    <SpaceApp />
  )
}
