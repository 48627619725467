import {
  AddRecordsRequest,
  CreateEventRequest,
  DeleteRecordsRequest,
  Filter,
  GetRecordsCsvRequest,
  GetRecordsRequest,
  UpdateRecordsRequest,
} from '@flatfile/api'
import {
  ColumnConfigProps,
  DataChangeEvent,
  RowChangeDetail,
} from '@flatfile/turntable'
import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { formatTurntableRows, recordData } from '../utils/tableUtils'
import { PAGE_SIZE } from './useSheetViewData'

export interface RecordDataPayload {
  [key: string]: { value: any }
}

export interface GetRecordsParams {
  pageSize?: number
  pageNumber?: number
  sinceVersionId?: string
  searchFields?: Record<string, string>
}

export const getRecordsQueryKey = 'getRecords'

export function useRecordMutation(sheetId: string) {
  const { httpClient } = useContext(SpaceContext)

  const { mutateAsync: mutateUpdate } = useMutation(
    ['updateRecords'],
    async (payload: UpdateRecordsRequest) => {
      return await httpClient.updateRecords(payload)
    }
  )

  const { mutateAsync: mutateAdd } = useMutation(
    ['addRecords'],
    async (payload: AddRecordsRequest) => {
      return await httpClient.addRecords(payload)
    }
  )

  const { mutateAsync: deleteRecords } = useMutation(
    ['deleteRecords'],
    async (payload: DeleteRecordsRequest) => {
      return await httpClient.deleteRecords(payload)
    }
  )

  const { mutateAsync: downloadRecords } = useMutation(
    ['downloadRecords'],
    async (payload: GetRecordsCsvRequest) => {
      return await httpClient.getRecordsCsv(payload)
    }
  )

  const { mutateAsync: createEvent } = useMutation(
    ['createEvent'],
    async (payload: CreateEventRequest) => {
      return await httpClient.createEvent(payload)
    }
  )

  const addRecords = async (
    changes: RowChangeDetail[],
    columnConfig: ColumnConfigProps[]
  ) => {
    const convertedChanges = changes.map((change: RowChangeDetail) =>
      recordData(change, columnConfig)
    )
    return await mutateAdd({
      sheetId,
      recordsData: convertedChanges,
    })
  }

  const updateRecords = async (
    changes: any,
    columnConfig: ColumnConfigProps[]
  ) => {
    const convertedChanges = changes.map((change: any) => ({
      id: change.id,
      values: recordData(change, columnConfig),
    }))

    return await mutateUpdate({
      sheetId,
      recordsUpdates: convertedChanges,
    })
  }

  const { mutateAsync: getRecords } = useMutation(
    [getRecordsQueryKey],
    async (payload: GetRecordsRequest) => await httpClient.getRecords(payload)
  )

  const fetchRecords = async (
    params: GetRecordsParams,
    columnConfig: ColumnConfigProps[]
  ) => {
    const page = params.pageNumber ?? 0
    const size = params.pageSize ?? PAGE_SIZE
    const filters = params.searchFields ?? {}

    if (filters.filterField && filters.filter === Filter.Valid) {
      return []
    }

    /*
      We determine the filter manually in this case as the response is not always
      reliable when combining filterField with no filter property.
    */
    const filter =
      filters.filterField && filters.filter !== Filter.Error
        ? Filter.Error
        : filters.filter

    const result = await getRecords({
      sheetId: sheetId!,
      pageSize: size,
      pageNumber: page + 1,
      includeMessages: true,
      sinceVersionId: params.sinceVersionId,
      ...filters,
      filter: filter as Filter,
    })

    const records = result?.data?.records ?? []
    const skip = page * size

    return formatTurntableRows(records, skip, columnConfig)
  }

  const addOrUpdateRecords = async (
    { changes }: DataChangeEvent,
    columnConfig: ColumnConfigProps[]
  ) => {
    const addRecordsArray = changes.filter((record) => !record.id)
    const updateRecordsArray = changes.filter((record) => record.id)

    if (updateRecordsArray.length) {
      await updateRecords(updateRecordsArray, columnConfig)
    }
    if (addRecordsArray.length) {
      await addRecords(addRecordsArray, columnConfig)
    }
    return true
  }

  return {
    addRecords,
    updateRecords,
    deleteRecords,
    downloadRecords,
    fetchRecords,
    addOrUpdateRecords,
    createEvent,
  }
}
