import { Workbook } from '@flatfile/api'

export const isPinnedWorkbook = (workbook: Workbook) => {
  return (
    workbook.labels?.includes('primary') || workbook.labels?.includes('pinned')
  )
}

export const sortWorkbooks = (workbooks: Workbook[]) => {
  return workbooks
    .filter((w) => !w.labels?.includes('file'))
    .sort((wba, wbb) => {
      const aPinned = isPinnedWorkbook(wba)
      const bPinned = isPinnedWorkbook(wbb)
      if (aPinned && !bPinned) {
        return -1 // wba should come before wbb
      } else if (!aPinned && bPinned) {
        return 1 // wbb should come before wba
      } else {
        return wba.name!.localeCompare(wbb.name!) // Sort alphabetically
      }
    })
}
