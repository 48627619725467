import { CustomTheme } from '@flatfile/turntable'

export const CustomTurntableTheme: CustomTheme = {
  indexColumn: {
    backgroundColor: 'var(--color-page-100)',
    borderColor: 'var(--color-border)',
    color: 'var(--color-text-light)',
    selected: {
      color: 'var(--color-primary)',
      backgroundColor: 'var(--color-primary-light)',
    },
  },
  inputs: {
    radio: {
      color: 'var(--color-primary)',
    },
    checkbox: {
      color: 'var(--color-action)',
    },
  },
  table: {
    backgroundColor: 'var(--color-page-100)',
    borderColor: 'var(--color-border)',
  },
  cell: {
    borderColor: 'var(--color-border)',
    mutatedUnderlineColor: 'var(--color-primary)',
    selected: {
      backgroundColor: 'var(--color-primary-light)',
    },
    active: {
      borderColor: 'var(--color-action)',
      spinnerColor: 'var(--color-action)',
    },
    error: {
      backgroundColor: 'var(--color-danger-light)',
      color: 'var(--color-danger)',
      borderColor: 'var(--color-danger)',
    },
    warning: {
      backgroundColor: 'var(--color-warning-light)',
      color: 'var(--color-warning)',
      borderColor: 'var(--color-warning)',
    },
  },
  boolean: {
    toggleError: 'var(--color-danger)',
    toggleWarning: 'var(--color-warning)',
    toggleChecked: 'var(--color-primary)',
  },
  loading: {
    color: 'var(--color-action)',
  },
  column: {
    header: {
      backgroundColor: 'var(--color-page-100)',
      borderColor: 'var(--color-border)',
      color: 'var(--color-text-light)',
      dragHandle: {
        idle: 'var(--color-action)',
        dragging: 'var(--color-action-lighter)',
      },
    },
  },
  filters: {
    color: 'var(--color-text)',
    backgroundColor: 'var(--color-page-100)',
    active: {
      backgroundColor: 'var(--color-action)',
      secondaryBackgroundColor: 'var(--color-action-lighter)',
    },
    error: {
      color: 'var(--color-danger)',
      secondaryColor: 'var(--color-danger-less-light)',
      activeBackgroundColor: 'var(--color-danger)',
      secondaryBackgroundColor: 'var(--color-danger-less-light)',
    },
  },
}
