import { JobStatusEnum } from '@flatfile/api'
import { useEffect } from 'react'
import { useForceUpdate } from '../api/resources/useForceUpdate'
import { JobOperationEnum } from '../contexts/JobsContext'
import { RuntimeParameters } from '../packages/RuntimeParameters'
import { useRunningJobs } from './useRunningJobs'
const { isGuest } = RuntimeParameters()
const WAIT_TIME_SPACE_CREATION_FALLBACK_MS = 10_000 // 10 Seconds

export const useJobsWithNoResponse = (id: string | undefined) => {
  const runningJobs = useRunningJobs()
  const jobsWithNoResponse = runningJobs.filter(
    (job) =>
      !isGuest &&
      !Object.values(JobOperationEnum).includes(job.operation) &&
      job.status == JobStatusEnum.Ready &&
      id &&
      job.source === id &&
      job.createdAt <
        new Date(Date.now() - WAIT_TIME_SPACE_CREATION_FALLBACK_MS)
  )
  const forceUpdate = useForceUpdate()

  //rerender to recompute job stale status
  useEffect(() => {
    const timeout = setTimeout(() => {
      forceUpdate()
    }, WAIT_TIME_SPACE_CREATION_FALLBACK_MS)

    return () => {
      clearTimeout(timeout)
    }
  }, [runningJobs])

  return jobsWithNoResponse
}
