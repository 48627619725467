import { formatRelative, parseISO } from 'date-fns'

export const formatTime = (date?: string | number | Date) => {
  if (!date) return ''

  const parsed = typeof date === 'string' ? parseISO(date) : date

  try {
    return formatRelative(parsed, Date.now())
  } catch (e) {
    return ''
  }
}
