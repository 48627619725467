import { useMemo } from 'react'
import { PlanController } from '../../../../api/controllers/PlanController'
import { Rule } from '../../../../api/controllers/RuleController'
import { updateObservable, useAction } from '../../../../api/observable'
import { MappingSidebar } from '../../../../elements/MappingComponents'
import { MatchField } from '../../../../elements/MatchField'
import { MatchIconWrap } from '../../../../elements/MatchIconWrap'
import { StatusText } from '../../../../elements/StatusText'
import { useEventCallbacks } from '../../../../hooks/useEventCallbacks'
import { RuleCard } from '../components/RuleCard'
import { CountPill, RuleHeader } from '../elements'
import { Tooltip as AMPTooltip, ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Tooltip = styled(AMPTooltip)`
  z-index: ${ZIndex.level1000};
`
const maxMissingFieldsInTooltip = 10
/**
 * Render the UI for mapping fields and handle the updates to the mapping
 * ruleset
 *
 * @param controller
 * @param onFocus
 */
export const MapFieldsPage = ({
  controller,
  onFocus,
}: {
  controller: PlanController
  onFocus?: (rule: Rule) => void
}) => {
  const [updateRule, ruleUpdater] = useAction(
    controller.updateRule(),
    (data) => {
      updateObservable('plan', data)
    }
  )
  const { t } = useTranslation()

  const groups = useMemo(() => controller.getDestinationOptions(), [controller])
  const rules = useMemo(() => controller.getRules(), [controller.plan])

  const destFields = useMemo(
    () =>
      controller.dest.fields.filter(
        (f) => !f.constraints?.some((c) => c.type === 'computed')
      ),
    [controller.destSheet]
  )

  const requiredFields = useMemo(
    () => controller.dest.requiredFields,
    [controller.destSheet]
  )

  const flattenedOptions = groups.flatMap((group) =>
    'options' in group ? group.options : group
  )

  const mappedDestinations = flattenedOptions.filter((field) =>
    rules.some(
      (rule) => !!(field as any).value && rule.dest === (field as any).value
    )
  )

  const missingFields = requiredFields.filter(
    (field) => !mappedDestinations.some((dest) => dest.value === field.key)
  )

  const missingMain = missingFields.slice(0, maxMissingFieldsInTooltip)
  const missingRest = missingFields.slice(maxMissingFieldsInTooltip)

  const fieldsAreMissing = missingFields.length > 0

  const tooltipSourceContent = (
    <div>
      {t('mapping.mapFields.incomingFields.progressPill.tooltip', {
        mappedIncomingFields: rules.filter((r) => r.dest).length,
        totalIncomingFields: rules.length,
      })}
    </div>
  )

  const tooltipDestContent = (
    <div>
      {t('mapping.mapFields.destinationFields.progressPill.tooltip.progress', {
        mappedDestinationFields: mappedDestinations.length,
        totalDestinationFields: destFields.length,
      })}
      {fieldsAreMissing && (
        <div>
          {t(
            'mapping.mapFields.destinationFields.progressPill.tooltip.missingRequiredFields',
            { missingFieldsCount: missingFields.length }
          )}
          <ul>
            {missingMain.map((field) => (
              <li key={field.label}>{field.label}</li>
            ))}
          </ul>
          {missingRest.length > 0 && (
            <div>
              {t(
                'mapping.mapFields.destinationFields.progressPill.tooltip.greaterThanTenMissing',
                { missingFieldsAfterTen: missingRest.length }
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )

  const eventHandlers = useEventCallbacks('RuleCard', {
    onFocus(rule: Rule) {
      onFocus?.(rule)
    },
    onChange(rule: Rule) {
      updateRule({ rule })
    },
  })

  return (
    <MappingSidebar>
      <StatusText>{t('mapping.mapFields.subheading')}</StatusText>
      <RuleHeader>
        <MatchField>
          {t('mapping.mapFields.incomingFields.heading')}
          <CountPill data-tooltip-id='countpill-source'>
            {rules.filter((r) => r.dest).length}
            {' of '}
            {rules.length}
            <Tooltip id='countpill-source'>{tooltipSourceContent}</Tooltip>
          </CountPill>
        </MatchField>
        <MatchIconWrap>
          <div style={{ display: 'inline-block', width: 30 }}>&nbsp;</div>
        </MatchIconWrap>
        <MatchField>
          {t('mapping.mapFields.destinationFields.heading')}
          <CountPill
            invalid={fieldsAreMissing}
            data-tooltip-id='countpill-destination'
          >
            <>
              <>
                {t('mapping.mapFields.destinationFields.progressPill.text', {
                  totalDestinationsMapped: mappedDestinations.length,
                  totalDestinationFields: destFields.length,
                })}
              </>
              <>
                {missingFields.length > 0 &&
                  t(
                    'mapping.mapFields.destinationFields.progressPill.missingRequiredFields',
                    {
                      missingFieldsCount: missingFields.length,
                    }
                  )}
              </>
            </>
            <Tooltip id='countpill-destination'>{tooltipDestContent}</Tooltip>
          </CountPill>
        </MatchField>
      </RuleHeader>
      <Tooltip
        id='field-description'
        place='right'
        float
        positionStrategy='fixed'
      />
      {rules.map((r: Rule) => (
        <RuleCard
          observable={
            ruleUpdater.params?.rule?.src === r.src ? ruleUpdater : undefined
          }
          rule={r}
          // todo: this needs to be a rule ID
          key={`rule-${r.src}_${r.dest}`}
          planController={controller}
          disabled={ruleUpdater.isLoading}
          {...eventHandlers}
        />
      ))}
    </MappingSidebar>
  )
}
