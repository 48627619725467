import styled from 'styled-components'

export const FancySelect = styled.select`
  appearance: none;
  background: var(--color-page);
  border: 1px solid var(--color-border);
  border-radius: var(--layout-border-radius);
  color: var(--color-text-light);
  font-size: var(--size-p);
  cursor: pointer;
  padding: 0.5em 0.75em;
  width: 100%;

  :active,
  :focus {
    outline-color: var(--color-action);
  }
`

export const NewSelectStyles = {
  container: (provided: any) => ({
    borderRadius: 0,
  }),
  control: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    background: 'var(--color-page)',
    border: state.hasValue
      ? '1px solid transparent'
      : '1px solid var(--color-border)',
    borderRadius: 'var(--layout-border-radius)',
    boxShadow: null,
    color: state.hasValue ? 'var(--color-text)' : 'var(--color-text-light)',
    fontSize: 'var(--size-p)',
    fontWeight: '400',
    zIndex: '1',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'var(--color-border)',
    },
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 500ms ease',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'var(--color-text-ultralight)',
  }),
  singleValue: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'var(--color-text)',
  }),
  valueContainer: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'var(--color-text)',
  }),
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    fontWeight: state.isSelected && !state.isDisabled ? 'bold' : 'unset',
    backgroundColor: state.isFocused ? 'var(--color-hover)' : 'transparent',
    color: state.isDisabled ? 'var(--color-pigeon-600)' : 'var(--color-text)',
    '&:hover': {
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    },
  }),
  menu: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: 'var(--color-page)',
    borderRadius: 0,
    color: 'var(--color-text)',
    maxWidth: '100%',
    zIndex: '0',
  }),
  menuPortal: (defaultStyles: any) => ({
    ...defaultStyles,
    zIndex: 1000000000,
  }),
}
