import { Sheet, Space } from '@flatfile/api'
import { useMemo } from 'react'
import {
  SheetEmptyState,
  SheetEmptyStateOverlay,
} from '../SheetViewEmptyStates'

interface UseEmptyStateProps {
  space: Space
  sheet?: Sheet
  tableError?: boolean
  isLoading?: boolean
  readOnlySheet?: boolean
  canEditSheet?: boolean
  canAddToSheet?: boolean
  canDeleteFromSheet?: boolean
  canImportIntoSheet?: boolean
  noAccessLimitations?: boolean
  sheetIsLockedUntilPayment?: boolean
  hideEmptyOverlay: () => void
}

export const useEmptyState = ({
  space,
  sheet,
  tableError,
  isLoading,
  readOnlySheet,
  canEditSheet,
  canAddToSheet,
  canDeleteFromSheet,
  canImportIntoSheet,
  noAccessLimitations,
  sheetIsLockedUntilPayment,
  hideEmptyOverlay,
}: UseEmptyStateProps): {
  emptyState: JSX.Element
  emptyStateOverlay: JSX.Element
} => {
  const emptyState = useMemo(
    () => (
      <SheetEmptyState
        hasError={tableError}
        hasSheet={!!sheet}
        isFirstLoad={isLoading}
        readOnlySheet={readOnlySheet}
        canAddToSheet={canAddToSheet}
        canImportIntoSheet={canImportIntoSheet}
        sheetIsLockedUntilPayment={sheetIsLockedUntilPayment}
      />
    ),
    [tableError, sheet, isLoading]
  )

  const emptyStateOverlay = useMemo(() => {
    if (readOnlySheet) {
      return <></>
    }
    return (
      <SheetEmptyStateOverlay
        hideOverlay={hideEmptyOverlay}
        canEditSheet={canEditSheet}
        canAddToSheet={canAddToSheet}
        canDeleteFromSheet={canDeleteFromSheet}
        canImportIntoSheet={canImportIntoSheet}
        noAccessLimitations={noAccessLimitations}
      />
    )
  }, [readOnlySheet, hideEmptyOverlay])

  return { emptyState, emptyStateOverlay }
}
