import {
  PopoverContext,
  PopoverMessage,
  WarningIcon,
} from '@flatfile/shared-ui'
import { useMutation } from '@tanstack/react-query'
import { useCallback, useContext, useMemo, useState } from 'react'
import { EnvironmentsContext } from '../../contexts/EnvironmentsContext'
import { SpaceContext } from '../../contexts/SpaceContext'

export const useManageProject = () => {
  const { space, availableSpaces, httpClient } = useContext(SpaceContext)
  const { showPopover } = useContext(PopoverContext)
  const { features } = useContext(EnvironmentsContext)

  const [showEndProjectModal, setShowEndProjectModal] = useState<boolean>(false)
  const [showEnableProjectModal, setShowEnableProjectModal] =
    useState<boolean>(false)

  const openEndProjectModal = useCallback(() => {
    setShowEndProjectModal(true)
  }, [setShowEndProjectModal])

  const closeEndProjectModal = useCallback(() => {
    setShowEndProjectModal(false)
  }, [setShowEndProjectModal])

  const openEnableProjectModal = useCallback(() => {
    setShowEnableProjectModal(true)
  }, [setShowEndProjectModal])

  const closeEnableProjectModal = useCallback(() => {
    setShowEnableProjectModal(false)
  }, [setShowEndProjectModal])

  const { mutateAsync: enableProject, isLoading: isEnablingProject } =
    useMutation(
      ['enableProject'],
      async () =>
        await httpClient.upgradeSpaceById({
          spaceId: space.id,
          upgradeSpaceConfig: { id: 'base' },
        })
    )

  const projectEnabled = !!space.isCollaborative
  const billingEnabled = !!features?.billingEnabled

  const canEnableFreeProject = useMemo(() => {
    const enabledSpaces = availableSpaces.filter((s) => !!s.isCollaborative)
    /*
      isCollaborative indicates that a Space is now a Project. So if none of a
      user's Spaces are Projects, then they have a free one available.

      TBD if there should be more nuance here.
    */
    return !enabledSpaces?.length
  }, [availableSpaces])

  const canEnableProject = useMemo(() => {
    return canEnableFreeProject || !!features?.projectsEnabled
  }, [canEnableFreeProject, features?.projectsEnabled])

  const handleEnableProjectError = useCallback(() => {
    showPopover({
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          There was an error enabling this Project.
        </PopoverMessage>
      ),
    })
  }, [showPopover])

  const handleEnableProject = useCallback(async () => {
    try {
      await enableProject()
      closeEnableProjectModal()
      /*
        Refresh page after enabling Project in order to show changes. In the future,
        we should be able to simply refetch Spaces.
      */
      window?.location.reload()
    } catch (e) {
      handleEnableProjectError()
    }
  }, [handleEnableProjectError, closeEnableProjectModal])

  const handleEndProject = useCallback(() => {
    /* Downgrading Projects can only be done manually for now. */

    const supportUrl = 'mailto:support@flatfile.io'
    const emailSubject = `Please close Project ID ${space.id}`
    const emailBody = `I'd like to request the Project associated with Space Id ${space.id} be closed.`

    window.location.href =
      supportUrl + '?subject=' + emailSubject + '&body=' + emailBody

    closeEndProjectModal()
  }, [space.id, closeEndProjectModal])

  return {
    billingEnabled,
    projectEnabled,
    canEnableProject,
    canEnableFreeProject,
    isEnablingProject,
    showEndProjectModal,
    showEnableProjectModal,
    openEndProjectModal,
    openEnableProjectModal,
    closeEndProjectModal,
    closeEnableProjectModal,
    onEnableProject: handleEnableProject,
    onEndProject: handleEndProject,
  }
}
