import {
  Button,
  CheckboxInput,
  Modal,
  ModalHeader,
  ModalButtonRow,
  ModalCloseButton,
  ModalText,
  Pagination,
  getFont,
  useListPagination,
} from '@flatfile/design-system'
import { Workbook } from '@flatfile/api'
import { useCallback, useMemo, useState } from 'react'
import { remove } from 'lodash'
import { VerticalDivider } from '../../../elements/VerticalDivider'
import { ISpaceUser } from '../../../hooks/useGuestManagement'
import styled from 'styled-components'

const PAGE_SIZE = 10

const LinkRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`

const ActionLink = styled.a`
  ${getFont('actionSm')}
  color: var(--color-title);
  cursor: pointer;
`

const WorkbookList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    color: var(--color-title);
  }
`

interface EditWorkbookAccessProps {
  user: ISpaceUser
  workbooks: Workbook[]
  onClose: () => void
  onSubmit: (user: ISpaceUser, workbooks: Workbook[]) => void
}

export const EditWorkbookAccessModal = ({
  user,
  workbooks,
  onClose,
  onSubmit,
}: EditWorkbookAccessProps) => {
  const getInitWorkbooks = useCallback(() => {
    return user.workbooks?.length ? user.workbooks : workbooks
  }, [user, workbooks])

  const [selectedWorkbooks, setSelectedWorkbooks] = useState<Workbook[]>(
    getInitWorkbooks()
  )
  const { currentPage, onChangePage } = useListPagination()

  const isWorkbookSelected = useCallback(
    (workbook: Workbook) => {
      return !!selectedWorkbooks.find((w) => w.id === workbook.id)
    },
    [selectedWorkbooks]
  )

  const handleSelectAll = useCallback(
    () => setSelectedWorkbooks(workbooks),
    [workbooks, setSelectedWorkbooks]
  )

  const handleSelectNone = useCallback(
    () => setSelectedWorkbooks([]),
    [setSelectedWorkbooks]
  )

  const handleSelectWorkbook = useCallback(
    (workbook: Workbook) => {
      if (isWorkbookSelected(workbook)) {
        const newSelectedWorkbooks = Array.from(selectedWorkbooks)
        remove(newSelectedWorkbooks, workbook)
        setSelectedWorkbooks(newSelectedWorkbooks)
      } else {
        setSelectedWorkbooks([...selectedWorkbooks, workbook])
      }
    },
    [selectedWorkbooks]
  )

  const handleSubmit = useCallback(
    () => onSubmit(user, selectedWorkbooks),
    [selectedWorkbooks]
  )

  const workbookList = useMemo(() => {
    const workbooksArray = Array.from(workbooks)
    const startIndex = PAGE_SIZE * (currentPage - 1)
    const endIndex = startIndex + PAGE_SIZE

    return workbooksArray.slice(startIndex, endIndex)
  }, [workbooks, currentPage])

  const content = (
    <>
      <ModalHeader>
        Edit workbook access for {user.name ?? user.email}
      </ModalHeader>
      <ModalText>
        Removing all workbooks from a user's account removes that user from the
        space.
      </ModalText>
      <LinkRow>
        <ActionLink onClick={handleSelectAll}>Select All</ActionLink>
        <VerticalDivider />
        <ActionLink onClick={handleSelectNone}>Clear All</ActionLink>
      </LinkRow>
      <WorkbookList>
        {workbookList.map((workbook) => (
          <CheckboxInput
            key={workbook.id}
            color='var(--color-primary)'
            checked={isWorkbookSelected(workbook)}
            onChange={() => handleSelectWorkbook(workbook)}
            label={workbook.name}
          />
        ))}
      </WorkbookList>
      {workbooks.length > PAGE_SIZE && (
        <Pagination
          size='sm'
          onPageChange={onChangePage}
          totalCount={workbooks.length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      )}
      <ModalButtonRow>
        <ModalCloseButton onClose={onClose} label='Cancel' />
        <Button onPress={handleSubmit} label='Save' />
      </ModalButtonRow>
    </>
  )

  return <Modal content={content} data-testid='workbook-access-modal' />
}
