import { Sheet, Workbook } from '@flatfile/api'
import { Dropdown, List } from '@flatfile/design-system'
import { Key, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { InjectResource } from '../../api/resources/InjectResource'
import { SpaceContext } from '../../contexts/SpaceContext'
import { Badge } from '../../elements/Badge'
import { ErrorState } from '../../elements/EmptyState'
import { MainContent } from '../../elements/MainContent'
import { HeadingRow, PrimaryHeading } from '../../elements/PrimaryHeading'
import { useChecklistView } from '../../hooks/useChecklistView'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { getDataTypeIcon } from '../../resources/icons/data-type-icons'
import { useTranslation } from 'react-i18next'

const DropdownRow = styled.div`
  display: flex;
  margin-bottom: 24px;

  > div {
    margin-right: 24px;
  }
`

const DropdownContainer = styled.div`
  min-width: 100px;
  max-width: 320px;
`

const FieldCell = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
`

export const ChecklistView = () => {
  const { space, sidebarCollapsed, workbooks } = useContext(SpaceContext)
  const { t } = useTranslation()
  const {
    selectedWorkbook,
    setSelectedWorkbook,
    selectedSheet,
    setSelectedSheet,
    hasNonFileWorkbooks,
    nonFileWorkbooks,
  } = useChecklistView(workbooks)

  sidebarCollapsed?.set(false)
  useDocumentTitle(t('checklist.title'), space.name)

  const sheets =
    workbooks &&
    workbooks.find((workbook) => workbook.id === selectedWorkbook)?.sheets

  useMemo(() => {
    const hasSheets = workbooks && sheets && sheets[0] && sheets[0].id
    // default sheet selection to first one in workbook if there is one
    if (hasSheets) {
      setSelectedSheet(sheets![0].id)
    }
  }, [selectedWorkbook])

  useMemo(() => {
    if (nonFileWorkbooks.length) {
      // default workbook selection to first one in space if there is one
      setSelectedWorkbook(nonFileWorkbooks[0].id)
    }
  }, [workbooks])

  const getResource = (): string | undefined => {
    if (selectedWorkbook) {
      return selectedWorkbook as string
    }
    if (hasNonFileWorkbooks) {
      return nonFileWorkbooks[0].id
    }
    return undefined
  }

  const resource = getResource()

  if (!nonFileWorkbooks.length) {
    return (
      <MainContent>
        <HeadingRow>
          <PrimaryHeading>{t('checklist.title')}</PrimaryHeading>
        </HeadingRow>
        <HeaderRow workbooks={[]} />
        <ErrorState
          title={t('checklist.errors.noWorkbooksTitle')}
          message={t('checklist.errors.noWorkbooksDescription')}
        />
      </MainContent>
    )
  }

  return (
    <MainContent>
      <HeadingRow>
        <PrimaryHeading>Data checklist</PrimaryHeading>
      </HeadingRow>
      <InjectResource<Workbook>
        id={resource}
        errorContent={(error) => (
          <ErrorState title={t('checklist.errors.cannotLoadChecklist')}>
            <p>{error}</p>
          </ErrorState>
        )}
      >
        {(workbook) => (
          <>
            <HeaderRow
              workbooks={nonFileWorkbooks}
              sheets={sheets}
              selectedWorkbook={selectedWorkbook}
              selectedSheet={selectedSheet}
              handleWorkbookChange={setSelectedWorkbook}
              handleSheetChange={setSelectedSheet}
            />
            <InnerChecklistView workbook={workbook} sheetId={selectedSheet} />
          </>
        )}
      </InjectResource>
    </MainContent>
  )
}

export const disabledCheck = (value?: Workbook[] | Sheet[]): boolean => {
  if (!value || value.length <= 1) {
    return true
  }
  return false
}

export const HeaderRow = ({
  workbooks,
  sheets,
  selectedWorkbook,
  selectedSheet,
  handleWorkbookChange,
  handleSheetChange,
}: {
  workbooks: Workbook[]
  sheets?: Sheet[]
  selectedWorkbook?: Key
  selectedSheet?: Key
  handleWorkbookChange?: (key: Key | undefined) => void
  handleSheetChange?: (key: Key | undefined) => void
}) => {
  const { t } = useTranslation()
  const options = workbooks.map((workbook) => ({
    key: workbook.id!,
    label: workbook.name!,
  }))

  const sheetOptions =
    sheets && sheets.length
      ? sheets?.map((sheet) => ({
          key: sheet.id!,
          label: sheet.name!,
        }))
      : []

  return (
    <DropdownRow data-testid='checklist-view-header-row'>
      <DropdownContainer>
        <Dropdown
          searchable
          data-testid='workbook-select'
          id='workbook-selection'
          onSelectionChange={handleWorkbookChange}
          selectedKey={selectedWorkbook}
          label={t('checklist.dropdowns.headings.workbook')}
          isDisabled={disabledCheck(workbooks)}
          optionsList={options}
        />
      </DropdownContainer>
      <DropdownContainer>
        <Dropdown
          searchable
          data-testid='sheet-select'
          id='sheet-selection'
          selectedKey={selectedSheet}
          onSelectionChange={handleSheetChange}
          label={t('checklist.dropdowns.headings.sheet')}
          isDisabled={disabledCheck(sheets)}
          optionsList={sheetOptions}
        />
      </DropdownContainer>
    </DropdownRow>
  )
}

export const InnerChecklistView = ({
  workbook,
  sheetId,
}: {
  workbook: Workbook
  sheetId: Key | undefined
}) => {
  const { t } = useTranslation()
  const headers = [
    { key: 'icon', content: '', width: '50px' },
    {
      key: 'field',
      content: t('checklist.table.headings.field'),
      width: '290px',
    },
    { key: 'description', content: t('checklist.table.headings.description') },
  ]

  const currentSheet = useMemo(() => {
    return workbook?.sheets?.find((sheet) => sheet.id === sheetId)
  }, [workbook, sheetId])

  const listData = useMemo(() => {
    const list = currentSheet?.config?.fields.map((field, i) => {
      const data = {
        icon: getDataTypeIcon(field.type) ?? null,
        field: (
          <FieldCell>
            <span>{field.label}</span>
            {field.constraints?.find((x) => x.type === 'required') && (
              <Badge data-required>
                {t('checklist.table.fieldTagRequired')}
              </Badge>
            )}
            {field.constraints?.find((x) => x.type === 'unique') && (
              <Badge data-unique>{t('checklist.table.fieldTagUnique')}</Badge>
            )}
            {field.constraints?.find((x) => x.type === 'computed') && (
              <Badge data-computed>
                {t('checklist.table.fieldTagComputed')}
              </Badge>
            )}
          </FieldCell>
        ),
        description: field.description ?? '',
      }
      return { data }
    })
    return list ?? []
  }, [currentSheet?.config])

  if (!currentSheet) {
    return <ErrorState title={t('checklist.errors.cannotLoadSheet')} />
  }

  return <List headers={headers} data={listData} />
}
