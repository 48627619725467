import { Account, Environment } from '@flatfile/api'
import { useQuery } from '@tanstack/react-query'
import { createContext, useContext, useMemo } from 'react'
import { SpaceContext } from './SpaceContext'

export type Features = Record<string, boolean>

export interface EnvironmentsContextType {
  environments?: Environment[]
  environment?: Environment
  accountId?: string
  account?: Account
  features?: Features
}

export const EnvironmentsContext = createContext<EnvironmentsContextType>({})

export const EnvironmentsContextProvider = (props: {
  children: JSX.Element
}) => {
  const { environment, environments, features, accountId, account } =
    useEnvironmentsContext()
  return (
    <EnvironmentsContext.Provider
      value={{ environment, environments, features, accountId, account }}
    >
      {props.children}
    </EnvironmentsContext.Provider>
  )
}

export const useEnvironmentsContext = () => {
  const { httpClient, space } = useContext(SpaceContext)
  const { data } = useQuery(['getEnvironments'], () =>
    httpClient.getEnvironments()
  )
  const { data: accountData } = useQuery(
    ['getCurrentAccount'],
    () => httpClient.getCurrentAccount(),
    {}
  )

  const environments = data?.data
  const environment = useMemo(() => {
    return environments?.find((env) => env.id === space.environmentId)
  }, [environments, space])

  const account = accountData?.data

  return {
    environment,
    environments: environments,
    features: environment?.features as Features,
    accountId: environment?.accountId,
    account,
  }
}
