import { Workbook } from '@flatfile/api'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SpaceContext } from '../contexts/SpaceContext'
import { StatusText } from '../elements/StatusText'
import { RadioInput } from '../elements/RadioInput'
import { SheetIconInvert } from '../resources/icons/SheetIcon'
import { DetailRow } from '../elements/DetailRow'
import { SelectList, SelectListItem } from '../elements/SelectList'
import { useTranslation } from 'react-i18next'
import { validDate } from '../utils/validDate'

export interface SheetSelectorProps {
  sourceWorkbook: Workbook
  onSourceChange: (workbookId: string, sheetId: string) => void
  onDestinationChange: (workbookId: string, sheetId: string) => void
  fileName: string
}

const DotSeparator = styled.span`
  margin: 0 10px;
`

const DisabledParagraph = styled.p`
  opacity: 30%;
`

const HorizontalListItem = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      {children}
      <DotSeparator>·</DotSeparator>
    </>
  )
}

export function SheetSelector({
  sourceWorkbook,
  onSourceChange,
  onDestinationChange,
  fileName,
}: SheetSelectorProps) {
  const { workbooks, workbookRefetch } = useContext(SpaceContext)
  const [sourceSheet, setSourceSheet] = useState<string>()
  const [destSheet, setDestSheet] = useState<string>()
  const { t } = useTranslation()

  useEffect(workbookRefetch, [])

  useEffect(() => {
    if (sourceWorkbook?.sheets?.[0]) {
      setSourceSheet(sourceWorkbook!.sheets?.[0]!.id)
    }
  }, [sourceWorkbook])

  return (
    <ColWrapper>
      {sourceWorkbook?.sheets?.length! > 1 && (
        <div>
          <StatusText data-testid='source-info'>
            {t('mapping.sheetSelection.importFromHeading')}
          </StatusText>
          <SelectList>
            <SelectListItem key={sourceWorkbook.id}>
              <DetailRow>
                <SheetIconInvert />
                <p>{sourceWorkbook.name}</p>
              </DetailRow>
              {sourceWorkbook.sheets?.map((s) => (
                <RadioInput key={s.id}>
                  <label key={s.id}>
                    <input
                      type='radio'
                      data-testid={'select-sheet'}
                      checked={sourceSheet === s.id}
                      onChange={() => {
                        setSourceSheet(s.id)
                        onSourceChange(sourceWorkbook.id, s.id)
                      }}
                    />
                    <p>{s.name}</p>{' '}
                    <span>
                      {t('mapping.sheetSelection.columns', {
                        count: s.config?.fields?.length,
                      })}
                    </span>
                  </label>
                </RadioInput>
              ))}
            </SelectListItem>
          </SelectList>
        </div>
      )}
      <div>
        <StatusText data-testid='import-info'>
          {t('mapping.sheetSelection.importToHeading', { fileName: fileName })}
        </StatusText>
        <SelectList>
          {workbooks
            .filter((w) => !w.name?.startsWith('[file]')) // @todo filter on backend?
            .map((w) => {
              const result = w.sheets?.reduce(
                (prev, curr) => {
                  const rowsCount =
                    Number(prev.workbookRowsCount) +
                    (curr?.countRecords?.total || 0)
                  const colCount =
                    Number(prev.workbookColumnsCount) +
                    (curr?.config?.fields?.length || 0)

                  return {
                    workbookRowsCount: rowsCount.toString(),
                    workbookColumnsCount: colCount.toString(),
                  }
                },
                { workbookRowsCount: '0', workbookColumnsCount: '0' }
              )
              return (
                <SelectListItem key={w.id}>
                  <DetailRow>
                    <SheetIconInvert />
                    <p>{w.name}</p>
                    {result?.workbookRowsCount && (
                      <HorizontalListItem>
                        <span>
                          {t('mapping.sheetSelection.records', {
                            count: Number(result.workbookRowsCount),
                          })}
                        </span>
                      </HorizontalListItem>
                    )}
                    {result?.workbookColumnsCount && (
                      <HorizontalListItem>
                        <span>
                          {t('mapping.sheetSelection.columns', {
                            count: Number(result.workbookColumnsCount),
                          })}
                        </span>
                      </HorizontalListItem>
                    )}
                    {w.sheets && (
                      <HorizontalListItem>
                        <span>
                          {t('mapping.sheetSelection.sheets', {
                            count: w.sheets.length,
                          })}
                        </span>
                      </HorizontalListItem>
                    )}
                    {w.updatedAt && (
                      <span>
                        {t('mapping.sheetSelection.lastUpdated', {
                          date: validDate(
                            w.updatedAt,
                            t('mapping.sheetSelection.lastUpdatedDateFormat') ??
                              'yyyy-mm-dd'
                          ),
                        })}
                      </span>
                    )}
                  </DetailRow>
                  {w.sheets?.map((s) => {
                    const isDisabled =
                      s.config?.readonly ||
                      (s.config?.access &&
                        !s.config?.access.includes('import') &&
                        !s.config?.access.includes('*'))
                    return (
                      <RadioInput key={s.id}>
                        <label key={s.id}>
                          <input
                            disabled={isDisabled}
                            type='radio'
                            data-testid={'select-sheet'}
                            checked={destSheet === s.id}
                            onChange={() => {
                              setDestSheet(s.id)
                              onDestinationChange(w.id, s.id)
                            }}
                          />
                          {isDisabled ? (
                            <DisabledParagraph>{s.name}</DisabledParagraph>
                          ) : (
                            <p>{s.name}</p>
                          )}
                          {s.countRecords?.total ? (
                            <>
                              <HorizontalListItem>
                                <span>
                                  {t('mapping.sheetSelection.records', {
                                    count: s.countRecords.total,
                                  })}
                                </span>
                              </HorizontalListItem>
                              <HorizontalListItem>
                                <span>
                                  {t('mapping.sheetSelection.columns', {
                                    count: s.config?.fields?.length,
                                  })}
                                </span>
                              </HorizontalListItem>
                              {s.countRecords && (
                                <span>
                                  {t('mapping.sheetSelection.errors', {
                                    count: s.countRecords.error,
                                  })}
                                </span>
                              )}
                            </>
                          ) : (
                            <span>
                              {t('mapping.sheetSelection.sheetHasNoData')}
                            </span>
                          )}
                        </label>
                      </RadioInput>
                    )
                  })}
                </SelectListItem>
              )
            })}
        </SelectList>
      </div>
    </ColWrapper>
  )
}

const ColWrapper = styled.div`
  display: flex;
  > div {
    flex-grow: 1;
  }
`
