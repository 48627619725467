import { useContext } from 'react'
import styled from 'styled-components'
import { SpaceContext } from '../contexts/SpaceContext'

export const EmptyWorkbooksWrap = styled.div`
  padding: 1rem 1.25rem;
  border: 1px solid var(--color-sunflower-500);
  border-radius: 2px;
  margin-bottom: 1rem;
  background-color: var(--color-sunflower-200);
`

export const EmptyWorkbookAlert = () => {
  const spaceContext = useContext(SpaceContext)
  const spaceHasWorkbooksOrDocuments =
    spaceContext?.workbooks?.length || spaceContext?.documents?.length

  if (spaceHasWorkbooksOrDocuments) {
    return null
  }

  return (
    <EmptyWorkbooksWrap data-testid='empty-workbooks-banner'>
      This space does not have any Workbooks. Learn about{' '}
      <u>
        <a
          target='_blank'
          href='https://flatfile.com/docs/quickstart/meet-the-workbook'
          rel='noreferrer'
        >
          adding Workbooks
        </a>
      </u>
    </EmptyWorkbooksWrap>
  )
}
