import { Typography } from '@flatfile/design-system'
import styled from 'styled-components'

export const Code = styled.div`
  pre {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--cmdk-gray12);
    background: var(--cmdk-gray3);
    font-family: SFMono-Regular, Menlo, Consolas, 'PT Mono', 'Liberation Mono',
      Courier, monospace;
    font-weight: 400;
    font-size: 85%;
    line-height: 1.45;
    padding: 16px;
    border-radius: 6px;
    overflow: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
`

export const Explain = styled(Typography)`
  padding: 20px 0;
`

export const FFQL = styled.div`
  padding: 8px 16px;
  font-family: monospace;
  white-space: pre;
`

export const MutationWarning = styled.span`
  margin: 20px 0;
  color: var(--color-danger);
`
