import React from 'react'
import styled from 'styled-components'

/**
 * Animated Ellipsis styled component.
 *
 * This is a styled div that renders an animated ellipsis using CSS keyframes.
 * It uses a stepping animation to cycle through different content states to
 * create the ellipsis animation.
 */
const Ellipsis = styled.div`
  padding: 8px 16px;

  ::after {
    display: inline-block;
    animation: dotty steps(1, end) 1s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }
`

/**
 * Ellipsis Message component.
 *
 * This component takes an array of messages and an interval time as props and
 * cycles through the messages based on the given interval. Each message is
 * rendered with an animated ellipsis trailing after it.
 *
 * @param {string[]} props.messages - Array of messages to be displayed.
 * @param {number} props.interval - Time interval (in milliseconds) between switching messages.
 *
 * @example
 * <EllipsisMessage messages={['Loading', 'Still loading']} interval={1000} />
 */
export const EllipsisMessage = ({
  messages,
  interval,
}: {
  messages: string[]
  interval: number
}) => {
  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    let ival: NodeJS.Timer

    ival = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex === messages.length - 1) {
          return prevIndex
        } else {
          return prevIndex + 1
        }
      })
    }, interval)

    return () => {
      clearInterval(ival)
    }
  }, [index])

  return <Ellipsis>{messages[index]}</Ellipsis>
}
