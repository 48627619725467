import {
  Button,
  Modal,
  ModalHeader,
  ModalText,
  ModalButtonRow,
  ModalCloseButton,
} from '@flatfile/design-system'

interface EnableProjectProps {
  onClose: () => void
  onConfirm: () => void
  canEnableFreeProject: boolean
  isEnablingProject: boolean
}

export const EnableProjectModal = ({
  onClose,
  onConfirm,
  canEnableFreeProject,
  isEnablingProject,
}: EnableProjectProps) => {
  const content = (
    <>
      <ModalHeader>Enable Project</ModalHeader>
      <ModalText>
        {canEnableFreeProject
          ? 'This will use up your free Project.'
          : 'You will be billed as per your contract.'}
      </ModalText>
      <ModalButtonRow>
        <ModalCloseButton onClose={onClose} label='Cancel' />
        <Button
          onPress={onConfirm}
          label={isEnablingProject ? 'Enabling...' : 'Enable'}
          loading={isEnablingProject}
        />
      </ModalButtonRow>
    </>
  )

  return <Modal content={content} data-testid='enable-project-modal' />
}
