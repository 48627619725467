import { AMP_TOOLTIP_ID, Button, ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'

const TriggerBackground = styled.div`
  border-radius: 2px;
  background-color: var(--color-sidebar-footer-text);
  height: 20px;
  width: 18px;
  padding: 1px;
  position: relative;
`

const TriggerSwitch = styled.div`
  border-radius: 2px;
  background-color: var(--color-sidebar-bg);
  transition: ease-in-out all 0.1s;
  position: absolute;
  height: 18px;
  width: 8px;
`

const TriggerButton = styled.div<{ collapsed?: boolean }>`
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${ZIndex.level200};
  top: 32px;
  right: 1.25em;

  .styled-button {
    padding: 4px;
    min-width: 0px;
    height: 28px;
  }

  .styled-button.ghost:hover {
    background: var(--color-sidebar-focus-bg) !important;
    ${TriggerBackground} {
      background-color: var(--color-sidebar-focus-text);
    }
    ${TriggerSwitch} {
      background-color: var(--color-sidebar-focus-bg);
    }
  }

  ${TriggerSwitch} {
    ${({ collapsed }) => (collapsed ? 'left: 1px;' : 'right: 1px;')}
  }
`

export const SidebarExpandTrigger = ({
  collapsed,
  adminOnly,
  onClick,
}: {
  collapsed?: boolean
  adminOnly?: boolean
  onClick: () => void
}) => {
  const adminString = adminOnly
    ? ". You're seeing the sidebar because you're an admin. Guests will not see the sidebar."
    : ''
  const tooltipString = collapsed ? 'Show sidebar' : 'Hide sidebar'
  return (
    <TriggerButton
      collapsed={collapsed}
      data-tooltip-id={AMP_TOOLTIP_ID}
      data-tooltip-content={`${tooltipString}${adminString}`}
    >
      <Button
        variant='ghost'
        size='sm'
        onPress={onClick}
        data-testid='expand-button'
        label={
          (
            <TriggerBackground>
              <TriggerSwitch />
            </TriggerBackground>
          ) as unknown as string
        }
      />
    </TriggerButton>
  )
}
