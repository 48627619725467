import { Typography } from '@flatfile/design-system'
import { OnSearchValue, formatSearchTerm } from '@flatfile/turntable'
import { useEffect } from 'react'
import styled from 'styled-components'
import { CommandMenuPanelFooter } from '../CommandMenuPanelFooter/CommandMenuPanelFooter'

interface ICommandMenuCommandsPanel {
  inputValue: string
  dismissHandler: () => void
  onSearchByValue: OnSearchValue
  keyboardSubmitActivated: boolean
  deactivateKeyboardSubmit: () => void
}

enum PanelType {
  FILTER = 'filter',
  IN = 'in',
  SEARCH = 'search',
}

const getPanelType = (checkValue: string) => {
  const inType = /^\/(in)/.test(checkValue)
  const filterType = /^\/(filter)/.test(checkValue)
  switch (true) {
    case inType:
      return PanelType.IN
    case filterType:
      return PanelType.FILTER
    default:
      return PanelType.SEARCH
  }
}

export const CommandMenuCommandsPanel = (props: ICommandMenuCommandsPanel) => {
  const {
    inputValue,
    dismissHandler,
    onSearchByValue,
    keyboardSubmitActivated,
    deactivateKeyboardSubmit,
  } = props
  const panelType = getPanelType(inputValue)

  useEffect(() => {
    if (keyboardSubmitActivated) {
      searchItemSelected(inputValue)
      deactivateKeyboardSubmit()
    }
  }, [keyboardSubmitActivated, inputValue])

  const getCleansedCommandPrompt = (inputValue: string): string => {
    let cleansedCommandPrompt = inputValue

    if (panelType === PanelType.FILTER) {
      cleansedCommandPrompt = 'filter: ' + cleansedCommandPrompt.substring(8)
    }
    if (panelType === PanelType.IN) {
      cleansedCommandPrompt = 'in: ' + cleansedCommandPrompt.substring(4)
    }
    if (
      panelType === PanelType.SEARCH &&
      cleansedCommandPrompt.charAt(0) === '/'
    ) {
      cleansedCommandPrompt = cleansedCommandPrompt.substring(7)
    }

    return cleansedCommandPrompt
  }

  const searchItemSelected = (inputValue: string) => {
    const searchTerm = getCleansedCommandPrompt(inputValue)
    const newSearchParam = formatSearchTerm(searchTerm)
    onSearchByValue(newSearchParam)
    dismissHandler()
  }

  const FilterPrompt = () => (
    <PromptFlexWrapper>
      <Typography type='actionMd' color='var(--color-primary)'>
        Apply Filter.
      </Typography>
      <a
        href='https://flatfile.com/docs/developer-tools/flatfile_query_language'
        target='_blank'
        rel='noreferrer'
      >
        Learn more about FFQL filters
      </a>
    </PromptFlexWrapper>
  )

  const InPrompt = () => (
    <PromptFlexWrapper>
      <Typography type='actionMd' color='var(--color-primary)'>
        Apply search within field.
      </Typography>
      <Typography type='actionMd' color='var(--color-text-ultralight)'>
        {`i.e. "first_name" Jane Doe`}
      </Typography>
    </PromptFlexWrapper>
  )

  const SearchPrompt = () => (
    <Typography type='actionMd' color='var(--color-text-ultralight)'>
      <Typography type='actionMd' color='var(--color-text-ultralight)'>
        Apply search.
      </Typography>
    </Typography>
  )

  return (
    <>
      {panelType === PanelType.FILTER && <FilterPrompt />}
      {panelType === PanelType.IN && <InPrompt />}
      {panelType === PanelType.SEARCH && <SearchPrompt />}

      <CommandMenuPanelFooter
        onCancel={dismissHandler}
        onApply={() => searchItemSelected(inputValue)}
      />
    </>
  )
}

const PromptFlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`
