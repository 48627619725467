import { resetRuntimeParameters, RuntimeParameters } from './RuntimeParameters'

export function logout(loc: Location = location) {
  const { returnUrl } = RuntimeParameters()
  resetRuntimeParameters()
  if (returnUrl?.length) {
    loc.replace(`${new URL(returnUrl).origin}/account/logout`)
  } else {
    loc.reload()
  }
}
