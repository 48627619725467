import { Job } from '@flatfile/api'
import { useContext } from 'react'
import { JobController } from '../../../api/controllers/JobController'
import { useController } from '../../../api/controllers/useController'
import { useObservable } from '../../../api/observable'
import { guard } from '../../../api/resources/GuardResources'
import { EnvironmentsContext } from '../../../contexts/EnvironmentsContext'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { Container } from '../../../elements/Container'
import { getDevBanner } from '../../../elements/DevBanner'
import { MapRouter } from './MapRouter'

export function MapGuard({ job }: { job: Job }) {
  const spaceContext = useContext(SpaceContext)
  const controller = useController(JobController, job, spaceContext.httpClient)
  const { environment } = useContext(EnvironmentsContext)

  const [plan] = useObservable(controller.getPlan(), undefined, 'plan')
  const [sheets] = useObservable(controller.getSheets(), undefined, 'sheets')
  const [preview] = useObservable(
    controller.getPreviewRecords(),
    undefined,
    'preview'
  )

  // todo - make 2nd loads behave differently
  // todo - fix this loading UI
  return (
    <Container>
      {guard([plan, sheets, preview]) ?? (
        <>
          {getDevBanner(environment)}
          <MapRouter
            spaceUrl={`/space/${spaceContext.space.id}`}
            plan={plan.ensured.data}
            sheets={sheets.ensured.data}
            preview={preview.ensured.data}
            job={job}
          />
        </>
      )}
    </Container>
  )
}
