import {
  differenceInMinutes,
  differenceInSeconds,
  differenceInHours,
} from 'date-fns'

export const differenceInTime = (start: Date, end: Date) => {
  const hours = differenceInHours(end, start)
  if (hours > 0) {
    return hours + ' hr'
  }
  const minutes = differenceInMinutes(end, start)
  if (minutes > 0) {
    return minutes + ' min'
  }

  return Math.max(1, differenceInSeconds(end, start)) + ' sec'
}
