import { CustomTheme } from '@flatfile/turntable'
import { merge } from 'lodash'
import tinycolor from 'tinycolor2'
import { CustomTurntableTheme } from '../../../resources/table-theme'

export interface ISidebarConfig {
  /**
   * Landing page upon loading a space
   */
  defaultPage?: {
    documentId?: string
    checklist?: boolean
    workbook?: {
      workbookId: string
      sheetId?: string
    }
  }
  /**
   * Toggle branding logo
   */
  showPoweredByFlatfile?: boolean
  /**
   * Ability for guests to invite guests
   */
  showGuestInvite?: boolean
  /**
   * Toggle data checklist for space
   */
  showDataChecklist?: boolean
  /**
   * Whether the sidebar is visible when you open a workbook
   */
  showSidebar?: boolean
}

export interface ThemeConfig {
  /**
   * Global styles
   */
  root?: {
    [key: string]: string
  }
  /**
   * Sidebar styles
   */
  sidebar?: {
    [key: string]: string
  }
  /**
   * document styles
   */
  document?: {
    [key: string]: string
  }
  /**
   * Data table styles
   */
  table?: CustomTheme
}
export interface SpaceMeta {
  sidebarConfig?: ISidebarConfig
  theme?: ThemeConfig
}

const root = document.querySelector(':root') as HTMLElement

const setStyle = (cssVar: string, value: string | null) => {
  if (root) return root.style?.setProperty(cssVar, value)
}

const setColor = (cssVar: string, color: string | undefined) => {
  if (color && tinycolor(color).isValid()) {
    setStyle(cssVar, color)
  } else {
    setStyle(cssVar, null)
  }
}

const getShade = (
  color: string | undefined,
  action: 'lighten' | 'darken',
  amount: number
) => {
  if (
    amount < 0 ||
    amount > 100 ||
    color === undefined ||
    !tinycolor(color).isValid()
  )
    return

  return action === 'lighten'
    ? tinycolor(color).lighten(amount).toHexString()
    : tinycolor(color).darken(amount).toHexString()
}

const hasSufficientColorContrast = (colorOne: string, colorTwo: string) => {
  const luminanceColorOne = tinycolor(colorOne).getLuminance()
  const luminanceColorTwo = tinycolor(colorTwo).getLuminance()
  const contrastRatio =
    (Math.max(luminanceColorOne, luminanceColorTwo) + 0.05) /
    (Math.min(luminanceColorOne, luminanceColorTwo) + 0.05)

  return contrastRatio >= 1.05
}

export const getLighterColors = (
  color: string | undefined,
  noShades: number
): string[] => {
  if (!color) return Array(noShades).fill(undefined)

  let lightestColor
  let lighteningValue
  // Find the lightest discernible shade from white
  for (let i = 95; i >= 10; i -= 5) {
    const shade = getShade(color, 'lighten', i)
    if (shade && hasSufficientColorContrast(shade, '#fff')) {
      lightestColor = shade
      lighteningValue = i
      break
    }
  }

  if (!lighteningValue) {
    return Array(noShades).fill(undefined) // If no discernible shade found, return undefined for each shade
  }

  // Generate the darker shades
  const shades = []
  const step = Math.round(lighteningValue / noShades)

  for (let i = 0; i <= noShades - 1; i++) {
    const lighteningAmount = Math.max(lighteningValue - i * step, 0)
    const shade =
      lighteningAmount !== 0
        ? getShade(color, 'lighten', lighteningAmount)
        : color
    if (shade) {
      shades.push(shade)
    }
  }

  return shades
}

export const setTheme = (meta: SpaceMeta) => {
  //root vars
  const primaryColor = meta?.theme?.root?.primaryColor
  const dangerColor = meta?.theme?.root?.dangerColor
  const warningColor = meta?.theme?.root?.warningColor
  const successColor = meta?.theme?.root?.successColor

  const fontFamily = meta?.theme?.root?.fontFamily
  const buttonBorderRadius = meta?.theme?.root?.buttonBorderRadius

  const primaryColorShades = getLighterColors(primaryColor, 4)

  const primaryColorMap = {
    '--color-primary-light':
      meta?.theme?.root?.primaryLightColor ?? primaryColorShades[0],
    '--color-primary-less-light':
      meta?.theme?.root?.primaryLessLightColor ?? primaryColorShades[1],
    '--color-action-lighter':
      meta?.theme?.root?.actionLighterColor ?? primaryColorShades[2],
    '--color-action': meta?.theme?.root?.actionColor ?? primaryColorShades[3],
    '--color-primary': primaryColor,
    '--color-primary-darkest':
      meta?.theme?.root?.primaryDarkestColor ??
      getShade(primaryColor, 'darken', 10),
  }

  const dangerColorShades = getLighterColors(dangerColor, 2)

  const dangerColorMap = {
    '--color-danger': dangerColor,
    '--color-danger-light':
      meta?.theme?.root?.dangerLightColor ?? dangerColorShades[0],
    '--color-danger-less-light':
      meta?.theme?.root?.dangerLessLightColor ?? dangerColorShades[1],
  }

  const successColorShades = getLighterColors(successColor, 1)
  const successColorMap = {
    '--color-success': successColor,
    '--color-success-light':
      meta?.theme?.root?.successLightColor ?? successColorShades[0],
  }

  const warningColorShades = getLighterColors(warningColor, 2)
  const warningColorMap = {
    '--color-warning': warningColor,
    '--color-warning-less-light':
      meta?.theme?.root?.warningLessLightColor ?? warningColorShades[1],
    '--color-warning-light': warningColorShades[0],
  }

  const colorMap = {
    ...primaryColorMap,
    ...warningColorMap,
    ...dangerColorMap,
    ...successColorMap,
    // sidebar colors
    '--color-sidebar-bg': meta?.theme?.sidebar?.backgroundColor,
    '--color-sidebar-text': meta?.theme?.sidebar?.textColor,
    '--color-sidebar-title': meta?.theme?.sidebar?.titleColor,
    '--color-sidebar-focus-bg': meta?.theme?.sidebar?.focusBgColor,
    '--color-sidebar-focus-bg-alpha': meta?.theme?.sidebar?.focusBgColor
      ? tinycolor(meta?.theme?.sidebar?.focusBgColor)
          .setAlpha(0.3)
          .toRgbString()
      : undefined,
    '--color-sidebar-focus-text': meta?.theme?.sidebar?.focusTextColor,
    '--color-sidebar-footer-text': meta?.theme?.sidebar?.footerTextColor,
    '--color-sidebar-border': meta?.theme?.sidebar?.borderColor,
    // document colors
    '--color-document-border': meta?.theme?.document?.borderColor,
  }

  //set colors
  for (const [key, value] of Object.entries(colorMap)) {
    setColor(key, value)
  }

  if (fontFamily) {
    setStyle('--text-font', fontFamily)
  }
  setStyle('--button-border-radius', buttonBorderRadius ?? null)
}
export const getTurntableTheme = (meta: SpaceMeta | undefined) => {
  const customTheme = meta?.theme?.table
  return customTheme
    ? merge(CustomTurntableTheme, customTheme)
    : CustomTurntableTheme
}

export const isImagePathValid = async (
  path: string | undefined
): Promise<boolean> => {
  return new Promise((resolve) => {
    if (path === undefined) async () => resolve(false)
    else {
      const img = new Image()
      img.src = path
      /* c8 ignore start */
      img.onload = async () => resolve(true)
      img.onerror = async () => resolve(false)
      /* c8 ignore stop */
    }
  })
}
