import { JobModeEnum, Workbook } from '@flatfile/api'
import { JobOperationEnum } from '../contexts/JobsContext'
import { useRunningJobs } from './useRunningJobs'

export const useIsWorkbookLocked = (
  workbook: Workbook,
  modes: JobModeEnum[] = [JobModeEnum.Foreground]
) => {
  const ids = [
    workbook.id,
    ...(workbook.sheets?.map((sheet) => sheet.id) ?? []),
  ]
  const runningJobs = useRunningJobs()
  const blockingJobs = runningJobs
    .filter((j) => j.mode && modes.includes(j.mode))
    .filter((j) =>
      ids.some(
        (id) =>
          (j.operation == JobOperationEnum.Map && id && j.destination === id) ||
          (id && j.source === id)
      )
    )
  return blockingJobs.length > 0
}
