import { JobModeEnum } from '@flatfile/api'
import { useBlockingJobs } from './useBlockingJobs'

export const useSheetHasJob = (
  sheetId: string | undefined,
  workbookId: string | undefined,
  modes: JobModeEnum[] = [JobModeEnum.Foreground, JobModeEnum.Background]
) => {
  const sheetJobs = useBlockingJobs(sheetId, modes)
  const sheetHasJob = sheetJobs.length > 0
  const workbookJobs = useBlockingJobs(workbookId, modes)
  const workbookHasJob = workbookJobs.length > 0
  const hasJob = sheetHasJob || workbookHasJob
  return hasJob
}
