import React from 'react'
import { CommandChip, CommandItem, CommandTypography } from './elements'

export function CommandMenuItem({
  children,
  onMenuItemSelection,
  displayCommand = false,
  color,
  commandColor,
  id,
}: {
  children: React.ReactNode
  description?: string
  color: string
  commandColor: string
  displayCommand?: boolean
  onMenuItemSelection?: (item: any) => void
  id?: string
}) {
  return (
    <>
      <CommandItem key={id} value={id} onSelect={onMenuItemSelection}>
        {displayCommand && (
          <CommandChip $commandColor={commandColor}>
            <CommandTypography type='b2' color={color}>
              {id}
            </CommandTypography>
          </CommandChip>
        )}
        <CommandTypography type='b1' color='var(--color-text-ultralight)'>
          {children}
        </CommandTypography>
      </CommandItem>
    </>
  )
}
