import { Action } from '@flatfile/api'
import { ProgressBar } from '@flatfile/design-system'
import styled from 'styled-components'

const OuterBox = styled.div``
const TextBox = styled.div`
  padding-bottom: 1.5em;
`
const BottomBox = styled.div`
  padding-bottom: 1em;
`
export const ForegroundJobModalContent = ({
  job,
  resource,
}: {
  job: any
  resource: any
}) => {
  const action =
    job && resource && resource.actions
      ? resource.actions.find((a: Action) => a.operation === job.operation)
      : undefined
  const jobName = action?.label || 'Job'
  const resourceName = resource?.name || 'this resource'

  return (
    <OuterBox>
      <TextBox>
        <span>
          <span className='font-semibold'>{jobName}</span> {' running on '}
          <span className='font-semibold'>{resourceName}</span>
        </span>
      </TextBox>
      <ProgressBar />
      <BottomBox />
    </OuterBox>
  )
}
