import { Workbook } from '@flatfile/api'
import { Button } from '@flatfile/design-system'
import { useMemo } from 'react'
import { PrimaryActionButtons } from './elements'
import { useIsWorkbookLocked } from '../../hooks/useIsWorkbookLocked'
import { BulkRowActionItem } from './hooks/useBulkRowActions'

export const PrimaryActions = ({
  actions,
  workbook,
}: {
  workbook: Workbook
  actions?: BulkRowActionItem[]
}) => {
  if (!actions?.length) {
    return null
  }

  const locked = useIsWorkbookLocked(workbook)
  const sortedActions = useMemo(() => actions.reverse(), [actions])
  return (
    <PrimaryActionButtons>
      {sortedActions.map((action, i) => {
        return (
          <div key={action.label}>
            <Button
              isDisabled={locked}
              variant={action.primary ? 'primary' : 'secondary'}
              data-testid={
                action.primary
                  ? 'sheet-button-primary'
                  : 'sheet-button-secondary'
              }
              onPress={action.preAction}
              label={action.label}
            />
          </div>
        )
      })}
    </PrimaryActionButtons>
  )
}
