export const flatfileIcon = (
  <svg
    width='.825em'
    height='.825em'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.9173 13.121C5.62328 13.415 5.25574 13.562 4.8147 13.562H3.30781H1.13936C0.808576 13.562 0.551302 13.4518 0.330781 13.2312C0.147014 13.0107 0 12.7534 0 12.4227V6.79939V1.13936C0 0.808576 0.147014 0.551302 0.330781 0.330781C0.551302 0.147014 0.845329 0 1.13936 0H4.52067H7.90199C8.23277 0 8.49005 0.147014 8.71057 0.330781C8.93109 0.551302 9.04135 0.808576 9.04135 1.13936V5.54977V9.33537C9.04135 9.73966 8.89433 10.1439 8.60031 10.4012L7.27718 11.7243L5.9173 13.121Z'
      fill='currentColor'
    />
    <path
      d='M10.8063 5.9173C10.5122 5.62328 10.3652 5.25574 10.3652 4.85145V3.30781V1.13936C10.3652 0.808576 10.5122 0.551302 10.696 0.330781C10.9165 0.147014 11.2106 0 11.5046 0H17.1279H22.7879C23.1187 0 23.376 0.147014 23.5965 0.330781C23.817 0.551302 23.9273 0.808576 23.9273 1.13936V4.52067V7.90199C23.9273 8.23277 23.7802 8.49005 23.5965 8.71057C23.376 8.93109 23.1187 9.04135 22.7879 9.04135H18.3775H14.5551C14.1508 9.04135 13.7466 8.89433 13.4525 8.60031L12.1294 7.27718L10.8063 5.9173Z'
      fill='currentColor'
    />
    <path
      d='M18.0456 10.879C18.3396 10.585 18.7071 10.438 19.1482 10.438H20.6551H22.7868C23.1175 10.438 23.3748 10.585 23.5953 10.7688C23.8159 10.9893 23.9261 11.2466 23.9261 11.5773V17.2006V22.8239C23.9261 23.1547 23.7791 23.412 23.5953 23.6325C23.3748 23.853 23.1175 23.9633 22.7868 23.9633H19.4054H16.0241C15.6933 23.9633 15.4361 23.8162 15.2155 23.6325C14.995 23.412 14.8848 23.1547 14.8848 22.8239V18.4135V14.6279C14.8848 14.2236 15.0318 13.8193 15.3258 13.562L16.6489 12.2389L18.0456 10.879Z'
      fill='currentColor'
    />
    <path
      d='M13.121 18.0828C13.415 18.3768 13.562 18.7443 13.562 19.1854V20.6923V22.8607C13.562 23.1915 13.415 23.4488 13.2312 23.6693C13.0107 23.8898 12.7534 24.0001 12.4227 24.0001H6.79939H1.13936C0.808576 24.0001 0.551302 23.8531 0.330781 23.6693C0.147014 23.4488 0 23.1548 0 22.8607V19.4794V16.0981C0 15.7673 0.147014 15.51 0.330781 15.2895C0.551302 15.069 0.808576 14.9587 1.13936 14.9587H5.54977H9.33537C9.73966 14.9587 10.1439 15.1058 10.4012 15.3998L11.7243 16.7229L13.121 18.0828Z'
      fill='currentColor'
    />
  </svg>
)

export default flatfileIcon
