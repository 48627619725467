import { Space } from '@flatfile/api'
import {
  AMP_TOOLTIP_ID,
  Button,
  ButtonType,
  ButtonSize,
} from '@flatfile/design-system'
import * as React from 'react'
import { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { useFileUpload } from '../hooks/useFileUpload'
import { usePaymentAccess } from '../../../hooks/usePaymentAccess'
import { useTranslation } from 'react-i18next'

const FileInput = styled.input`
  display: none;
`

export const FileUploadButton: React.FC<{
  space: Space
  hideIcon?: boolean
  variant?: ButtonType
  size?: ButtonSize
  label?: string
  isDisabled?: boolean
  onFileUpload?: () => void
}> = ({
  space,
  size = 'sm',
  variant,
  hideIcon,
  label,
  isDisabled,
  onFileUpload,
}) => {
  const { fileUploadIsLockedUntilPayment } = usePaymentAccess()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploading, setUploading] = React.useState(false)
  const onClickUpload = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [fileInputRef])

  const { handleFileUpload } = useFileUpload(space)
  const { t } = useTranslation()

  const onChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setUploading(true)
      const file = event.target?.files?.[0]
      await handleFileUpload(file)
      onFileUpload && onFileUpload()
      event.target.value = ''
      setUploading(false)
    },
    [handleFileUpload, onFileUpload]
  )

  return (
    <>
      <div
        data-tooltip-content={
          fileUploadIsLockedUntilPayment
            ? t('files.errors.uploadDisabledHeading')
            : undefined
        }
        data-tooltip-id={AMP_TOOLTIP_ID}
      >
        <Button
          variant={variant ?? 'tertiary-border'}
          iconLeft={hideIcon ? undefined : 'documentAdd'}
          onPress={onClickUpload}
          label={
            uploading
              ? t('files.buttons.addFile.inProgressText')
              : label ?? t('files.buttons.addFile.text')
          }
          data-testid='add-files-button'
          loading={uploading}
          size={size}
          isDisabled={isDisabled || fileUploadIsLockedUntilPayment}
        />
      </div>
      <FileInput
        ref={fileInputRef}
        type='file'
        onChange={onChange}
        data-testid='add-files-input'
      />
    </>
  )
}
