import { useContext } from 'react'
import { SpaceContext } from '../contexts/SpaceContext'
import { ErrorState } from '../elements/EmptyState'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import { useRouteParams } from '../hooks/useRouteParams'
import { Document } from './Document'

export function DocumentView() {
  const { documentId } = useRouteParams()
  const { documents, sidebarCollapsed, space } = useContext(SpaceContext)

  sidebarCollapsed?.set(false)

  const doc = documents.find((d) => d.id === documentId)
  useDocumentTitle(doc?.title, space.name)
  if (!doc) {
    return (
      <ErrorState
        title='Document not found'
        message={`This document doesn't seem to exist.`}
        refresh={false}
      />
    )
  }
  return <Document document={doc} />
}
