import * as Api from '@flatfile/api'
import {
  DefaultApi,
  GetJobsRequest,
  Job,
  JobConfig,
  Pagination,
  PipelineJobConfig,
  Sheet,
} from '@flatfile/api'
import { DEFAULT_PAGE_SIZE } from '@flatfile/design-system'
import { Observable } from '../observable'
import { uniq } from 'lodash'
import { Platform } from '../../packages/HttpClient'

export const FILE_DELETED = 'File deleted'

export class JobController {
  constructor(public readonly job: Api.Job, private _httpClient?: DefaultApi) {}

  /**
   * Get an individual job
   *
   * @param jobId
   * @param httpClient
   */
  public static getJob(jobId: string, httpClient: DefaultApi): Observable<Job> {
    return new Observable<Job>(() => {
      return httpClient.getJob({ jobId })
    })
  }

  /**
   * Get all jobs for space
   */
  public static getJobsForSpace() {
    return new Observable<Job[], GetJobsRequest>(
      async ({ spaceId, pageNumber, sortDirection, pageSize }) => {
        return Platform.getJobs({
          spaceId,
          sortDirection,
          pageNumber,
          pageSize: pageSize || DEFAULT_PAGE_SIZE,
        })
      }
    )
  }

  /**
   * Returns just the Plan component of a JobPlan
   *
   * @todo this API response should only return the plan, not the job
   */
  public getPlan(): Observable<Api.Plan> {
    return new Observable(() =>
      Platform.getJobExecutionPlan({ jobId: this.job.id }).then(({ data }) => ({
        data: data?.plan!,
      }))
    )
  }

  /**
   * Obtain the actual sheet configurations for the current matching job
   */
  public getSheets(): Observable<SheetMap> {
    if (!this.job.source || !this.job.destination) {
      throw new Error(
        'Sheets not available until sheet selection has completed'
      )
    }
    return new Observable(async () => {
      const [src, dest] = await Promise.all([
        Platform.getSheet({
          sheetId: (this.job.config as PipelineJobConfig)?.sourceSheetId,
        }),
        Platform.getSheet({
          sheetId: (this.job.config as PipelineJobConfig)?.destinationSheetId,
        }),
      ])
      return { data: { src: src.data!, dest: dest.data! } }
    })
  }

  /**
   * Get the preview records for the source sheet
   */
  getPreviewRecords(): Observable<Record<string, string[]>> {
    return new Observable(async () => {
      return Platform.getRecords({
        sheetId: (this.job.config as PipelineJobConfig)?.sourceSheetId,
        pageSize: 1000,
      }).then((res) => {
        return {
          data: this.buildPreview(res.data?.records || []),
        }
      })
    })
  }

  public buildPreview(records: Api.RecordsWithLinks): Record<string, string[]> {
    const preview: Record<string, string[]> = {}
    records.forEach((record) => {
      Object.entries(record.values).forEach(([key, { value }]) => {
        if (!preview[key]) {
          preview[key] = []
        }
        if (preview[key].length === 10) {
          return
        }
        if (value !== null && preview[key].length < 10) {
          preview[key] = uniq([...preview[key], value as string])
        }
      })
    })
    return preview
  }

  public static async create(jobConfig: JobConfig) {
    const res = await Platform.createJob({
      jobConfig,
    })

    return new this(res.data!)
  }
}

export type SheetMap = { src: Sheet; dest: Sheet }
export interface JobsWithPagination {
  jobs: Array<JobWithResourceName>
  pagination: Pagination
}
export type JobWithResourceName = {
  resourceName?: string
} & Job
