import { JobModeEnum, JobStatusEnum } from '@flatfile/api'
import { useContext } from 'react'
import { JobOperationEnum, JobsContext } from '../contexts/JobsContext'

export const useFinishedJobs = (id: string | undefined) => {
  const { activeJobs } = useContext(JobsContext)
  const modes: JobModeEnum[] = [JobModeEnum.Foreground]
  const completedJobs = Object.values(activeJobs ?? [])
    .filter(
      (j) =>
        j.status == JobStatusEnum.Complete || j.status == JobStatusEnum.Failed
    )
    .filter((j) => j.mode && modes.includes(j.mode))
    .filter((j) =>
      j.operation == JobOperationEnum.Map ||
      j.operation == JobOperationEnum.FindReplace
        ? false // no outcome modal for find-replace and mapping jobs
        : id && j.source === id
    )
  return completedJobs
}
