import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Space, Workbook, UpdateGuestRequest } from '@flatfile/api'
import { useCallback, useContext, useMemo } from 'react'
import { SpaceContext } from '../contexts/SpaceContext'
import { sortWorkbooks } from '../utils/workbooks'
import {
  Guest,
  GuestConfigSpacesInner,
  GuestConfigSpacesInnerWorkbooksInner,
  User,
} from '@flatfile/api'

const getGuestsQueryKey = 'getGuests'

export interface IInviteGuests {
  guestEmails: string[]
  message: string
}

export type ISpaceUser = {
  id: string
  email: string
  name?: string
  createdAt?: Date
  lastAccessed?: Date
  environmentId?: string
  isPending?: boolean
  spaces?: GuestConfigSpacesInner[]
  workbooks?: Workbook[]
}

export const useGuestManagement = ({
  space,
  onRemoveError,
  onUpdateError,
  onInviteError,
}: {
  space: Space
  onRemoveError?: () => void
  onUpdateError?: () => void
  onInviteError?: () => void
}) => {
  const queryClient = useQueryClient()
  const { httpClient, workbooks } = useContext(SpaceContext)

  const filteredWorkbooks = useMemo(() => sortWorkbooks(workbooks), [workbooks])

  const {
    data: guests,
    refetch: refetchGuests,
    isLoading: isLoadingGuests,
    isError: hasGuestError,
  } = useQuery(
    [getGuestsQueryKey],
    async () => {
      const guests = await httpClient.getGuests({ spaceId: space.id })
      return (
        guests.data?.filter((guest) => guest.name !== 'Anonymous Guest') ?? []
      )
    },
    { enabled: !!space }
  )
  const {
    data: allUsers,
    refetch: refetchUsers,
    isLoading: isLoadingUsers,
    isError: hasUserError,
  } = useQuery(
    ['getUsers'],
    async () => {
      const users = await httpClient.getUsers()
      return users?.data
    },
    { enabled: !!space }
  )

  const refetchUserData = async () => {
    await refetchUsers()
    await refetchGuests()
  }

  const { mutate: addGuests } = useMutation(
    ['addGuests'],
    (guestInvite: IInviteGuests) =>
      httpClient.addGuestToSpace({
        guestConfig: guestInvite.guestEmails.map((email) => ({
          email,
          name: email,
          environmentId: space.environmentId,
          spaces: [{ id: space.id }],
        })),
      }),

    {
      onSuccess: async (data, variables) => {
        const inviteGuests = data.data?.map((guest) => ({
          guestId: guest.id,
          spaceId: space.id,
          message: variables.message,
        }))
        if (inviteGuests?.length) {
          await httpClient.inviteGuests({
            inviteGuestsRequestInner: inviteGuests,
          })
          await queryClient.invalidateQueries({ queryKey: [getGuestsQueryKey] })
        }
      },
      onError: () => {
        if (onInviteError) onInviteError()
      },
    }
  )

  const { mutate: removeGuest } = useMutation(
    ['removeGuest'],
    async (email: string) => {
      const guest = guests?.find((g) => g.email === email)
      if (guest) {
        const updatedSpaces = guest.spaces.filter((s) => {
          return s.id !== space.id
        })
        // if the guest has access to no other spaces, they can be completely deleted
        // otherwise, we'll just do an update
        if (updatedSpaces.length) {
          await httpClient.updateGuest({
            guestId: guest.id,
            guestConfigUpdate: {
              environmentId: guest.environmentId,
              email: guest.email,
              name: guest.name,
              spaces: updatedSpaces,
            },
          })
        } else {
          await httpClient.deleteGuest({ guestId: guest.id })
        }
      } else {
        throw new Error(`No guest found with email: ${email}`)
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [getGuestsQueryKey] })
      },
      onError: () => {
        if (onRemoveError) onRemoveError()
      },
    }
  )

  const { mutate: updateGuest } = useMutation(
    ['updateGuest'],
    async (params: UpdateGuestRequest) => {
      await httpClient.updateGuest(params)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [getGuestsQueryKey] })
      },
      onError: () => {
        if (onUpdateError) onUpdateError()
      },
    }
  )

  const getGuestSpaceInfo = useCallback(
    (guest: Guest) => {
      const guestSpace = guest.spaces?.find((s) => s.id === space.id)
      const guestWorkbooks = guestSpace?.workbooks?.reduce(
        (memo: Workbook[], w: GuestConfigSpacesInnerWorkbooksInner) => {
          const workbook = filteredWorkbooks.find((wb) => wb.id === w.id)
          if (workbook) memo.push(workbook)
          return memo
        },
        []
      )
      return {
        lastAccessed: guestSpace?.lastAccessed,
        workbooks: guestWorkbooks,
      }
    },
    [space.id]
  )

  const users: ISpaceUser[] = useMemo(() => {
    return (
      guests?.map((guest: Guest) => {
        const user = allUsers?.find((u: User) => u.email === guest.email)
        return {
          ...guest,
          ...getGuestSpaceInfo(guest),
          isPending: !user,
        }
      }) ?? []
    )
  }, [allUsers, guests])

  return {
    guests,
    users,
    addGuests,
    removeGuest,
    updateGuest,
    refetchUserData,
    workbooks: filteredWorkbooks,
    isLoading: isLoadingGuests || isLoadingUsers,
    isError: hasGuestError || hasUserError,
  }
}
