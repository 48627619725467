import styled from 'styled-components'
import {
  Button,
  Modal,
  ModalButtonRow,
  ModalCloseButton,
  ModalHeader,
  ModalSubHeading,
  ModalText,
} from '@flatfile/design-system'
import { useTranslation } from 'react-i18next'
import { FileInfo, FileMode } from '../types'
import { capitalizeFirst } from '../../../utils/capitalizeFirst'

const DeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const DeleteModalFilesList = styled.div`
  display: flex;
  flex-direction: column;
`

export const DeleteFilesModal = ({
  files,
  mode,
  onClose,
  onConfirm,
  loading,
}: {
  files: FileInfo[]
  mode: FileMode
  onClose: () => void
  onConfirm: () => void
  loading: boolean
}) => {
  const { t } = useTranslation()

  const text =
    mode === 'import'
      ? t('files.deleteModal.uploadsDescription', { count: files.length })
      : t('files.deleteModal.downloadDescription', { count: files.length })

  return (
    <Modal
      content={
        <>
          <DeleteModalContainer data-testid='delete-file-modal'>
            <ModalHeader>
              {t('files.deleteModal.heading', { count: files.length })}
            </ModalHeader>
            <ModalText>
              {text}
              <DeleteModalFilesList>
                <ModalSubHeading>
                  {capitalizeFirst(
                    t('files.deleteModal.subheading', { count: files.length })
                  )}
                </ModalSubHeading>
                {files.map((fileInfo, index) => (
                  <span key={`${fileInfo.fileName}-${index}`}>
                    {fileInfo.fileName}
                  </span>
                ))}
              </DeleteModalFilesList>
            </ModalText>
          </DeleteModalContainer>
          <ModalButtonRow>
            <ModalCloseButton onClose={onClose} />
            <Button
              variant='warning'
              loading={loading}
              onPress={onConfirm}
              data-testid='delete-file'
              label={t('files.buttons.delete')}
            />
          </ModalButtonRow>
        </>
      }
    />
  )
}
