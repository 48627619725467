import { isArray } from 'lodash'

export type Primitive = boolean | number | null | string

/**
 * Make sure that a possibly nullable result becomes an empty array
 *
 * @param src
 */
export function arr<T>(src: T | T[] | undefined | null): T[] {
  if (src && isArray(src)) {
    return src
  } else if (src !== undefined && src !== null) {
    return [src]
  }
  return []
}
