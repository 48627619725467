import { Typography } from '@flatfile/design-system'
import { Command } from 'cmdk'
import styled from 'styled-components'

export const CommandItem = styled(Command.Item)`
  content-visibility: auto;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  user-select: none;
  will-change: background, color;

  &[aria-selected='true'] {
    background: var(--color-pigeon-100);
  }

  &[aria-disabled='true'] {
    color: var(--cmdk-gray8);
    cursor: not-allowed;
  }

  &:active {
    transition-property: background;
    background: var(--cmdk-gray4);
  }

  button {
    padding: 0;
    height: auto;
  }
`

export const CommandTypography = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`

export const CommandChip = styled.span<{
  $commandColor: string
}>`
  background-color: ${(props) => props.$commandColor};
  padding: 2px 10px;
  border-radius: 4px;

  > div {
    font-weight: 600;
  }
`
