import {
  Button,
  ButtonProps,
  List,
  Pagination,
  ActionsTooltip,
} from '@flatfile/design-system'
import { useContext, useEffect } from 'react'
import { ButtonRow } from '../../elements/ButtonRow'
import { ErrorState } from '../../elements/EmptyState'
import { MainContent } from '../../elements/MainContent'
import { HeadingRow, PrimaryHeading } from '../../elements/PrimaryHeading'
import { VerticalDivider } from '../../elements/VerticalDivider'
import { InviteGuestModal } from './modals/InviteGuestModal'
import { RemoveGuestModal } from './modals/RemoveGuestModal'
import { EndProjectModal } from './modals/EndProjectModal'
import { EnableProjectModal } from './modals/EnableProjectModal'
import { EditWorkbookAccessModal } from './modals/EditWorkbookAccessModal'
import { SpaceContext } from '../../contexts/SpaceContext'
import { RuntimeParameters } from '../../packages/RuntimeParameters'
import { UpgradeBanner, EndProjectFooter } from './components/UpgradeElements'
import { ListContainer } from './components/ListElements'
import { useManageUsers, listHeaders, PAGE_SIZE } from './useManageUsers'
import { useManageProject } from './useManageProject'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

export const ManageUsersRoute = () => {
  const { isGuest } = RuntimeParameters()
  const { sidebarCollapsed } = useContext(SpaceContext)

  useEffect(() => {
    sidebarCollapsed?.set(false)
  }, [])

  if (isGuest) {
    return (
      <MainContent data-testid='guest-error-view'>
        <ErrorState
          title='You do not have permission to view this page'
          message='Only admins can manage guests. If you think you are seeing this in error, please contact support.'
          refresh={false}
        />
      </MainContent>
    )
  }

  return <ManageUsersView />
}

export const ManageUsersView = () => {
  useDocumentTitle('Manage Guests')

  const {
    billingEnabled,
    projectEnabled,
    canEnableProject,
    canEnableFreeProject,
    isEnablingProject,
    onEnableProject,
    onEndProject,
    openEndProjectModal,
    openEnableProjectModal,
    closeEndProjectModal,
    closeEnableProjectModal,
    showEndProjectModal,
    showEnableProjectModal,
  } = useManageProject()

  return (
    <MainContent data-testid='admin-view'>
      <HeadingRow>
        <PrimaryHeading>Manage Guests</PrimaryHeading>
      </HeadingRow>

      {!projectEnabled && billingEnabled && (
        <UpgradeBanner
          canEnableProject={canEnableProject}
          onClickEnableProject={openEnableProjectModal}
        />
      )}
      {showEndProjectModal && (
        <EndProjectModal
          onClose={closeEndProjectModal}
          onConfirm={onEndProject}
        />
      )}
      {showEnableProjectModal && (
        <EnableProjectModal
          onClose={closeEnableProjectModal}
          onConfirm={onEnableProject}
          canEnableFreeProject={canEnableFreeProject}
          isEnablingProject={isEnablingProject}
        />
      )}

      <ManageGuestsList
        billingEnabled={billingEnabled}
        projectEnabled={projectEnabled}
      />

      {projectEnabled && billingEnabled && (
        <EndProjectFooter onClickEndProject={openEndProjectModal} />
      )}
    </MainContent>
  )
}

const InviteGuestsButton = ({
  showTooltip,
  showLimitTooltip,
  ...props
}: ButtonProps & { showTooltip?: boolean; showLimitTooltip?: boolean }) => {
  if (showTooltip) {
    /* c8 ignore start */
    const text = showLimitTooltip
      ? 'Please upgrade this Space to a Project in order to invite more than one guest.'
      : 'Please configure shared link or magic link guest authentication on this space in order to invite guests.'
    const infoUrl = showLimitTooltip
      ? 'https://flatfile.com/docs/core-paths/collaborate'
      : 'https://flatfile.com/docs/developer-tools/environment#guest-authentication'
    return (
      <ActionsTooltip
        text={text}
        actions={[
          {
            text: 'Learn more',
            onClick: () => window.open(infoUrl, '_blank'),
          },
        ]}
      >
        <Button {...props} data-testid='invite-guests-button' />
      </ActionsTooltip>
    )
    /* c8 ignore stop */
  }

  return <Button {...props} data-testid='invite-guests-button' />
}

const ManageGuestsList = ({
  billingEnabled,
  projectEnabled,
}: {
  billingEnabled: boolean
  projectEnabled: boolean
}) => {
  const { space } = useContext(SpaceContext)

  const {
    showInviteModal,
    showRemoveModal,
    openInviteModal,
    openRemoveModal,
    closeInviteModal,
    closeRemoveModal,
    closeEditModal,
    onSendInvites,
    onResendSelectedInvites,
    onRemoveUsers,
    onUpdateUser,
    isRemovingUsers,
    isLoadingUsers,
    hasLoadError,
    users,
    workbooks,
    selectedUsers,
    editUser,
    currentPage,
    onChangePage,
    listData,
    magicLinkEnabled,
    sharedLinkEnabled,
    showCopyMessage,
    copyInviteLink,
    guestInviteLink,
    hasInviteLimit,
    hasReachedInviteLimit,
  } = useManageUsers({ space, billingEnabled, projectEnabled })

  const disableButtons = hasLoadError
  const disableInviteButtons =
    disableButtons || hasReachedInviteLimit || !magicLinkEnabled
  const showCopyLink = sharedLinkEnabled && !magicLinkEnabled
  const showInviteTooltip =
    hasReachedInviteLimit || (!magicLinkEnabled && !sharedLinkEnabled)

  return (
    <>
      {showInviteModal && (
        <InviteGuestModal
          guestInviteLink={guestInviteLink}
          sendInvite={onSendInvites}
          onClose={closeInviteModal}
          copyInviteLink={copyInviteLink}
          showCopyMessage={showCopyMessage}
          hasInviteLimit={hasInviteLimit}
        />
      )}
      {showRemoveModal && (
        <RemoveGuestModal
          onClose={closeRemoveModal}
          onConfirm={onRemoveUsers}
          isRemovingUsers={isRemovingUsers}
          users={selectedUsers}
        />
      )}
      {!!editUser && (
        <EditWorkbookAccessModal
          user={editUser}
          workbooks={workbooks}
          onClose={closeEditModal}
          onSubmit={onUpdateUser}
        />
      )}

      <ButtonRow>
        <Button
          variant='tertiary'
          iconLeft='userRemove'
          onPress={openRemoveModal}
          label='Revoke access'
          isDisabled={selectedUsers.length < 1 || disableButtons}
          size='sm'
        />
        {!showCopyLink && (
          <Button
            variant='tertiary'
            iconLeft='mail'
            onPress={onResendSelectedInvites}
            label='Resend invite'
            isDisabled={
              selectedUsers.length < 1 ||
              (disableInviteButtons && !hasReachedInviteLimit)
            }
            size='sm'
          />
        )}
        <VerticalDivider />
        {showCopyLink ? (
          <Button
            variant='tertiary'
            iconLeft='link'
            onPress={copyInviteLink}
            isDisabled={disableButtons}
            label='Copy Space link'
            size='sm'
          />
        ) : (
          <InviteGuestsButton
            showTooltip={showInviteTooltip}
            showLimitTooltip={hasReachedInviteLimit}
            variant='tertiary'
            iconLeft='userAdd'
            onPress={openInviteModal}
            isDisabled={disableInviteButtons}
            label='Invite guest'
            size='sm'
          />
        )}
      </ButtonRow>

      <ListContainer>
        <List
          headers={listHeaders}
          data={listData}
          loading={isLoadingUsers}
          error={hasLoadError}
          components={{
            error: (
              <ErrorState title='Unable to load the users for this space' />
            ),
            empty: <>No guests to display</>,
          }}
        />
      </ListContainer>

      {users?.length > PAGE_SIZE && (
        <Pagination
          onPageChange={onChangePage}
          totalCount={users?.length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      )}
    </>
  )
}
