import { Filter, GetRecordsRequest, RecordCountsResponse } from '@flatfile/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { MutableRefObject, useContext, useMemo, useRef } from 'react'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { getHasSearchFilters } from './useSheetViewData'

export const getRecordCountsQueryKey = 'getRecordCounts'
export const getFilteredRecordCountsQueryKey = 'getFilteredRecordCounts'

export function useRecordCountsData(params: GetRecordsRequest) {
  const { httpClient } = useContext(SpaceContext)
  const lastFetched = useRef<number>(0)
  const filteredParams = params.filterField
    ? { ...params, filter: Filter.Error }
    : params

  const getFilteredRecordCounts = async () => {
    const { sheetId, filter, ...searchFields } = filteredParams
    if (getHasSearchFilters(searchFields)) {
      return await httpClient.getCounts(filteredParams)
    }
    return {}
  }

  const sheetParams = { sheetId: params.sheetId }
  const {
    data: countsData,
    refetch: refetchCounts,
    isError: hasCountsError,
    isRefetching: isRefetchingSheetCounts,
    isLoading: isLoadingSheetCounts,
    dataUpdatedAt: countsUpdatedAt,
  } = useQuery(
    ['getRecordCounts'],
    async () => httpClient.getCounts(sheetParams),
    {
      retry: false,
    }
  )

  const { mutateAsync: refetchErrorsByFieldCounts, data: errorByFieldCounts } =
    useMutation(
      async () => await httpClient.getCounts({ ...sheetParams, byField: true }),
      {
        onSuccess: () => {
          lastFetched.current = Date.now()
        },
      }
    )

  const {
    data: filteredCountsData,
    refetch: refetchFilteredCounts,
    isError: hasFilteredCountsError,
    isRefetching: isRefetchingFilteredCounts,
    isLoading: isLoadingFilteredCounts,
  } = useQuery(
    ['getFilteredRecordCounts', filteredParams],
    getFilteredRecordCounts,
    { retry: false }
  )

  const counts = useMemo(() => {
    return determineCounts({
      isLoadingSheetCounts,
      countsData,
      errorByFieldCounts,
      lastFetched,
      countsUpdatedAt,
    })
  }, [
    countsData?.data?.counts,
    isLoadingSheetCounts,
    hasCountsError,
    errorByFieldCounts,
  ])

  const filteredCounts = useMemo(() => {
    if (isLoadingFilteredCounts) return undefined
    return filteredCountsData?.data?.counts ?? counts
  }, [filteredCountsData?.data?.counts, isLoadingFilteredCounts, counts])

  return {
    counts,
    filteredCounts,
    refetchCounts,
    refetchFilteredCounts,
    hasCountsError: hasCountsError || hasFilteredCountsError,
    isLoadingCounts:
      isLoadingSheetCounts ||
      isLoadingFilteredCounts ||
      isRefetchingSheetCounts ||
      isRefetchingFilteredCounts,
    refetchErrorsByFieldCounts,
  }
}

//returns the counts object that was most recently fetched (countsData or errorByFieldCounts)
export const determineCounts = ({
  isLoadingSheetCounts,
  countsData,
  errorByFieldCounts,
  lastFetched,
  countsUpdatedAt,
}: {
  isLoadingSheetCounts: boolean
  countsData: RecordCountsResponse | undefined
  errorByFieldCounts: RecordCountsResponse | undefined
  lastFetched: MutableRefObject<number>
  countsUpdatedAt: number
}) => {
  if (isLoadingSheetCounts) return undefined
  const counts = countsData?.data?.counts
  const errorCounts = errorByFieldCounts?.data?.counts

  if (!errorCounts && !lastFetched.current) return counts
  if (countsUpdatedAt > lastFetched.current) return counts

  if (lastFetched.current > countsUpdatedAt) {
    return errorCounts
  }
}
