import { Job, Plan } from '@flatfile/api'
import { uniq } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SheetMap } from '../../../api/controllers/JobController'
import { PlanController } from '../../../api/controllers/PlanController'
import { Rule } from '../../../api/controllers/RuleController'
import { WorkbookController } from '../../../api/controllers/WorkbookController'
import { useController } from '../../../api/controllers/useController'
import { updateObservable, useAction } from '../../../api/observable'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { ActionContainer } from '../../../elements/ActionContainer'
import { DataPreview } from '../../../elements/DataPreview'
import {
  DataPreviewPanel,
  DataPreviewWrapper,
} from '../../../elements/MappingComponents'
import { SubHeader } from '../../../elements/SubHeader'
import { useEventCallbacks } from '../../../hooks/useEventCallbacks'
import { useNavigate } from '../../../hooks/useNavigate'
import { SheetActionBar } from '../../../packages/SheetActionBar'
import { arr } from '../../../resources/common'
import { MapEnumsPage } from './pages/MapEnumsPage'
import { MapFieldsPage } from './pages/MapFieldsPage'

const NoData = styled.span`
  color: var(--color-midnight-400);
`

/**
 * Handle the different mapping steps and moving between them as well as the
 * final submission job.
 *
 * @param jobController
 * @param plan
 * @param sheets
 * @param spaceUrl
 */
export function MapRouter({
  job,
  plan,
  sheets,
  spaceUrl,
  preview,
}: Props_MapRouter) {
  const MAP_STEP = {
    FIELDS: 0,
    CATEGORIES: 1,
  }
  const [pendingRequests, setPendingRequests] = useState<number>(0)

  const onUpdatePendingRequests = (n: number) => {
    setPendingRequests(n)
  }

  const { httpClient, space } = useContext(SpaceContext)
  const [index, setIndex] = useState(MAP_STEP.FIELDS)
  const workbookController = useController(WorkbookController, httpClient)
  const controller = useController(
    PlanController,
    plan,
    job,
    sheets,
    httpClient,
    onUpdatePendingRequests
  )

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [previewKey, setPreviewKey] = useState<string>()

  const emptyPreview = Array.from({ length: 10 }, (_, i) =>
    i === 0 ? <NoData>No Data</NoData> : <span>&nbsp;</span>
  )

  const previewData = useMemo(() => {
    const items = previewKey ? arr(uniq(preview[previewKey])) : []
    const onlyEmptyValues = items.filter((v) => !!v).length === 0

    return onlyEmptyValues ? emptyPreview : items
  }, [previewKey, preview])

  const [getWorkbookCounts] = useAction(
    workbookController.getAllWorkbooksWithCounts({
      spaceId: space.id,
    }).lazy,
    (data) => {
      updateObservable('workbookWithCounts', data)
    }
  )

  const continueOnSuccess = useCallback(() => {
    const destSheet = sheets.dest.id
    navigate(`${spaceUrl}/workbook/${job.destination}/sheet/${destSheet}`)
    getWorkbookCounts()
  }, [job])

  const [executeJob, jobAction] = useAction(
    controller.execute(),
    continueOnSuccess
  )

  useEffect(() => {
    const handleError = () => {
      alert(jobAction.error)
    }
    jobAction.addEventListener('error', handleError)
    return () => {
      jobAction.removeEventListener('error', handleError)
    }
  }, [jobAction])

  /**
   * @todo make sure this only triggers when there is a category type in the matching ruleset
   */
  const doContinue = useCallback(async () => {
    const enumRules = controller.getRules({ enum: true })
    if (index === MAP_STEP.FIELDS && enumRules.length) {
      setIndex(MAP_STEP.CATEGORIES)
    } else {
      executeJob()
    }
  }, [index])

  const goBack = useCallback(() => {
    if (index === MAP_STEP.CATEGORIES) {
      setIndex(MAP_STEP.FIELDS)
    } else {
      navigate(-1)
    }
  }, [index])

  const { onFocus } = useEventCallbacks('MapRouter', {
    onFocus(rule: Rule) {
      setPreviewKey(rule.src!)
    },
  })

  const isRecomputing = pendingRequests > 0

  return (
    <>
      <SheetActionBar
        headerText={
          index === MAP_STEP.FIELDS
            ? t('mapping.mapFields.title')
            : t('mapping.mapEnums.title')
        }
        exit={{
          label: t('mapping.headerButtons.exit'),
          onPress: () => navigate(spaceUrl),
        }}
        back={{ label: t('mapping.headerButtons.back'), onPress: goBack }}
        next={{
          label: t('mapping.headerButtons.next'),
          onPress: doContinue,
          loading: jobAction.isLoading || isRecomputing,
        }}
      />
      <ActionContainer>
        {index === MAP_STEP.FIELDS ? (
          <MapFieldsPage controller={controller} onFocus={onFocus} />
        ) : (
          <MapEnumsPage controller={controller} onFocus={onFocus} />
        )}
        <DataPreviewPanel>
          {previewKey && (
            <DataPreviewWrapper>
              <SubHeader data-testid={'data-preview'}>
                {t('mapping.dataPreview.heading', { previewKey: previewKey })}
              </SubHeader>
              {previewData && previewData.length ? (
                previewData.map((x, i) => (
                  <DataPreview key={i}>{x}</DataPreview>
                  /* c8 ignore start */
                ))
              ) : (
                <DataPreview>
                  <NoData>{t('mapping.dataPreview.noData')}</NoData>
                </DataPreview>
                /* c8 ignore stop */
              )}
            </DataPreviewWrapper>
          )}
        </DataPreviewPanel>
      </ActionContainer>
    </>
  )
}

type Props_MapRouter = {
  job: Job
  preview: Record<string, string[]>
  sheets: SheetMap
  plan: Plan
  spaceUrl: string
}
