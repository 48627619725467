import { EventDomainEnum, EventTopic, SpacePattern } from '@flatfile/api'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { WorkbookController } from '../../api/controllers/WorkbookController'
import { useController } from '../../api/controllers/useController'
import { useObservable } from '../../api/observable'
import { resources } from '../../api/resources'
import { EnvironmentsContextProvider } from '../../contexts/EnvironmentsContext'
import {
  JobOperationEnum,
  JobsContextProvider,
} from '../../contexts/JobsContext'
import { PopoverProvider } from '@flatfile/shared-ui'
import {
  SpaceContextProvider,
  SpaceContextType,
} from '../../contexts/SpaceContext'
import { ErrorState } from '../../elements/EmptyState'
import { SpacesUISkeleton } from '../../packages/SpacesUISkeleton'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import {
  BaseEvent,
  initPubnubSubscription,
  useEventSubscriber,
} from '../../hooks/useEventSubscriber'
import { useRouteParams } from '../../hooks/useRouteParams'
import { useSidebarCollapsed } from '../../hooks/useSidebarCollapsed'
import { ApiPlatform } from '../../packages/HttpClient'
import { RuntimeParameters } from '../../packages/RuntimeParameters'
import { SpacePageLayout } from '../../packages/SpacePageLayout'
import { FileUploadOverlay } from '../FilesApp/components/FileUploadOverlay'
import { SpaceMeta, setTheme } from './Theme/ThemeUtils'

export function SpaceApp() {
  const { spaceId, workbookId, sheetId } = useRouteParams()
  const { accessToken, isGuest } = RuntimeParameters()
  const httpClient = useMemo(() => {
    return ApiPlatform(accessToken)
  }, [accessToken])

  const workbookController = useController(WorkbookController, httpClient)
  const [workbookWithCounts] = useObservable(
    workbookController.getAllWorkbooksWithCounts({
      spaceId: spaceId || '',
    }),
    undefined,
    'workbookWithCounts'
  )
  const navigate = useNavigate()

  if (!spaceId) {
    throw new Error('Space id missing from params')
  }

  const [currentSpaceId, setCurrentSpaceId] = useState<string>(spaceId)
  initPubnubSubscription(currentSpaceId)

  useEventSubscriber('*', (topic, event) => {
    resources.emitResourceEvent(event as BaseEvent)
  })

  useEventSubscriber([EventTopic.JoboutcomeAcknowledged], (topic, event) => {
    window.parent.postMessage({ topic: 'job:outcome-acknowledged' }, '*')
  })

  const spacesQuery = useQuery(['getAllSpaces', httpClient], async () => {
    return (await httpClient.getAllSpaces()).data!
  })

  const spaceQuery = useQuery(['getSpace', currentSpaceId], async () => {
    return (await httpClient.getSpaceById({ spaceId: currentSpaceId })).data!
  })

  const workbooksQuery = useQuery(
    ['getAllWorkbooks', currentSpaceId],
    async () => {
      return (
        await httpClient.getAllWorkbooks({
          spaceId: currentSpaceId,
        })
      ).data!
    }
  )

  const documentsQuery = useQuery(
    ['getSpaceDocuments', currentSpaceId],
    async () => {
      return (
        await httpClient.getSpaceDocuments({
          spaceId: currentSpaceId,
        })
      ).data!
    }
  )

  const [spaceConfig, setSpaceConfig] = useState<SpacePattern>()
  const spaceConfigQuery = useQuery(
    ['getSpaceConfig', spaceQuery.data],
    async () => {
      const allConfigs = await httpClient.getAllSpaceConfigs()
      return {
        data: allConfigs.data?.find((x: any) => {
          return x.id === spaceQuery.data?.spaceConfigId // @todo new API endpoint to get just one config
        }),
      }
    },
    {
      enabled: !!spaceQuery.data,
      onSuccess: (res) => {
        if (res.data) {
          setSpaceConfig(res.data)
        }
      },
    }
  )

  const isLoading = useMemo(
    () =>
      spaceQuery.isLoading ||
      spaceQuery.isRefetching ||
      workbooksQuery.isLoading ||
      workbooksQuery.isRefetching ||
      documentsQuery.isLoading ||
      documentsQuery.isRefetching ||
      spaceConfigQuery.isLoading ||
      spaceConfigQuery.isRefetching ||
      spacesQuery.isLoading ||
      spacesQuery.isRefetching,
    [spaceQuery, workbooksQuery, documentsQuery, spaceConfigQuery, spacesQuery]
  )

  const isError = useMemo(
    () =>
      spaceQuery.isError ||
      workbooksQuery.isError ||
      documentsQuery.isError ||
      spaceConfigQuery.isError ||
      spacesQuery.isError,
    [spaceQuery, workbooksQuery, documentsQuery, spaceConfigQuery, spacesQuery]
  )

  useEffect(() => {
    workbookWithCounts.refetch()
  }, [])

  useEventSubscriber(
    [
      EventTopic.Workbookcreated,
      EventTopic.Workbookdeleted,
      EventTopic.Workbookupdated,
      EventTopic.Sheetdeleted,
      //sheet updated and sheet added do not currently have endpoints
    ],
    (event) => {
      const eventResponse = JSON.parse(event.message) ?? {}
      const { context } = eventResponse

      if (context?.spaceId === currentSpaceId) {
        workbookWithCounts.refetch()
      }
    }
  )

  useEventSubscriber(
    [
      EventTopic.Documentcreated,
      EventTopic.Documentdeleted,
      EventTopic.Documentupdated,
    ],
    (event) => {
      const eventResponse = JSON.parse(event.message) ?? {}
      const { context } = eventResponse
      if (context?.spaceId === currentSpaceId) {
        documentsQuery.refetch()
      }
    }
  )

  useEventSubscriber(
    [EventTopic.Jobcompleted, EventTopic.Jobcreated, EventTopic.Jobupdated],
    async (event) => {
      const eventResponse = JSON.parse(event.message) ?? {}
      const { context, payload, topic } = eventResponse

      if (
        context?.spaceId === currentSpaceId &&
        payload?.type === EventDomainEnum.Space &&
        payload?.operation === JobOperationEnum.Configure
      ) {
        if (topic === EventTopic.Jobcompleted) {
          await spaceQuery.refetch()
          await workbooksQuery.refetch()
          await documentsQuery.refetch()
          await spaceConfigQuery.refetch()
        }
      }
    }
  )
  const showSidebar = (spaceQuery.data?.metadata as SpaceMeta)?.sidebarConfig
    ?.showSidebar
  const sidebarCollapsed = useSidebarCollapsed(
    typeof showSidebar === 'boolean' ? !showSidebar : undefined,
    isGuest
  )

  useEventSubscriber([EventTopic.Spaceupdated], async (event) => {
    const eventResponse = JSON.parse(event.message) ?? {}
    const { context } = eventResponse
    if (context?.spaceId === currentSpaceId) {
      await spaceQuery.refetch()
    }
  })

  const workbookRefetch = () => workbookWithCounts.refetch()

  const spaceContext = useMemo((): SpaceContextType => {
    const workbooksList = workbookWithCounts.data?.length
      ? workbookWithCounts.data
      : workbooksQuery.data!
    return {
      documents: documentsQuery.data ?? [],
      httpClient,
      sidebarCollapsed,
      space: spaceQuery.data!,
      setCurrentSpace: (id) => {
        setCurrentSpaceId(id)
        navigate(`/space/${currentSpaceId}`)
      },
      availableSpaces: spacesQuery.data!,
      spaceConfig: spaceConfig!,
      workbooks: workbooksList,
      workbookRefetch,
      metadata: spaceQuery.data?.metadata as SpaceMeta,
    }
  }, [sidebarCollapsed, workbookWithCounts])

  const currentWorkbook =
    spaceContext.workbooks?.length &&
    spaceContext.workbooks.find((w) => w.id === workbookId)

  const currentSheet =
    currentWorkbook &&
    currentWorkbook?.sheets?.length &&
    currentWorkbook?.sheets.find((s) => s.id === sheetId)

  const enableFileUpload =
    window.location.pathname.includes('files') ||
    (window.location.pathname.includes('sheet') &&
      currentSheet &&
      !currentSheet?.config?.readonly &&
      currentSheet &&
      (!currentSheet?.config?.access ||
        currentSheet?.config?.access?.includes('import') ||
        currentSheet?.config?.access?.includes('*')))

  useDocumentTitle(spaceContext?.space?.name)

  useEffect(() => {
    setTheme(spaceQuery.data?.metadata as SpaceMeta)
  }, [spaceQuery.data?.metadata])

  if (isError) {
    return <ErrorState />
  }

  if (isLoading) {
    return SpacesUISkeleton()
  }

  return (
    <SpaceContextProvider value={spaceContext}>
      <EnvironmentsContextProvider>
        <JobsContextProvider>
          <PopoverProvider>
            <SpacePageLayout>
              {enableFileUpload ? (
                <FileUploadOverlay>
                  <Outlet />
                </FileUploadOverlay>
              ) : (
                <Outlet />
              )}
            </SpacePageLayout>
          </PopoverProvider>
        </JobsContextProvider>
      </EnvironmentsContextProvider>
    </SpaceContextProvider>
  )
}
