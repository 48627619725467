import { Document, Space, Workbook } from '@flatfile/api'

interface DefaultPageProps {
  documentId?: string
  checklist?: boolean
  workbook?: {
    workbookId?: string
    sheetId?: string
  }
}

interface GetAndValidateDefaultPageParams {
  documents: Document[]
  workbooks: Workbook[]
  defaultPageObj: DefaultPageProps
}

export const getAndValidateDefaultPage = ({
  documents,
  workbooks,
  defaultPageObj,
}: GetAndValidateDefaultPageParams) => {
  const {
    documentId,
    checklist,
    workbook: { sheetId, workbookId } = {
      sheetId: undefined,
      workbookId: undefined,
    },
  } = defaultPageObj

  const hasSheetsInFirstWorkbook = (): boolean => {
    const firstWorkbook = workbooks[0]
    return (firstWorkbook?.sheets && firstWorkbook?.sheets?.length > 0) || false
  }

  switch (true) {
    case Boolean(sheetId && workbookId):
      // return the single workbook that matches provided id
      const workbook = workbooks?.find((workbook) => workbook.id === workbookId)
      // validate that sheetId does exist in that workbook
      return (
        workbook &&
        workbook.id &&
        workbook.sheets?.some((sheet) => sheet.id === sheetId) &&
        `/workbook/${workbook?.id}/sheet/${sheetId}`
      )
    case Boolean(workbookId):
      // validate the workbookId does exist in workbooks Array
      return (
        workbooks.some((workbook) => workbook.id === workbookId) &&
        `/workbook/${workbookId}`
      )

    case Boolean(documentId):
      // validate the document does exist on the space
      return (
        documents.some((document) => document.id === documentId) &&
        `/document/${documentId}`
      )
    // validate the workbook has sheets before routing to checklist page
    case checklist && hasSheetsInFirstWorkbook():
      return `/checklist`
    // if none of the above conditions are met, fallback to primary routing hierarchy
    default:
      return false
  }
}

export const getRouteForSpace = (
  space: Space,
  documents: Document[],
  workbooks: Workbook[],
  defaultPage: DefaultPageProps,
  hasNonFileWorkbooks: boolean
) => {
  const defaultPageExists =
    defaultPage?.documentId || defaultPage?.checklist || defaultPage?.workbook

  const defaultRoute = getAndValidateDefaultPage({
    documents,
    workbooks,
    defaultPageObj: defaultPage,
  })

  switch (true) {
    case Boolean(defaultPageExists && defaultRoute):
      return defaultRoute
    case Boolean(space?.primaryWorkbookId):
      return `/workbook/${space.primaryWorkbookId}`
    case Boolean(workbooks?.length && hasNonFileWorkbooks):
      return `/workbook/${workbooks[0].id}`
    case Boolean(documents?.length):
      return `/document/${documents[0].id}`
    default:
      return '/files'
  }
}
