import { Sheet, Space, Workbook } from '@flatfile/api'
import { fromMaybe } from '@flatfile/shared-ui'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { resources } from '../../api/resources'
import { ErrorState } from '../../elements/EmptyState'
import { useRouteParams } from '../../hooks/useRouteParams'
import { SpacesUISkeleton } from '../SpacesUISkeleton'

const getHandlers = (
  resourceId: string | undefined,
  navigate: NavigateFunction,
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>
) => {
  return {
    sh: async () => {
      const sheetId = resourceId
      const sheet = await resources
        .getWithPromise<Sheet>(sheetId)
        .catch(() => ({ maybeData: undefined }))
      const workbookId = sheet.maybeData?.workbookId
      const workbook = await (!workbookId
        ? Promise.resolve({ maybeData: undefined })
        : resources
            .getWithPromise<Workbook>(workbookId)
            .catch(() => ({ maybeData: undefined })))
      const spaceId = workbook.maybeData?.spaceId
      if (sheetId && workbookId && spaceId) {
        navigate(`/space/${spaceId}/workbook/${workbookId}/sheet/${sheetId}`, {
          replace: true,
        })
      } else {
        setErrorMessage('Sheet not found')
      }
    },
    wb: async () => {
      const workbookId = resourceId
      const workbook = await resources.getWithPromise<Workbook>(workbookId)
      const spaceId = workbook.maybeData?.spaceId
      if (workbookId && spaceId) {
        navigate(`/space/${spaceId}/workbook/${workbookId}`, {
          replace: true,
        })
      } else {
        setErrorMessage('Workbook not found')
      }
    },
    sp: async () => {
      const spaceId = resourceId
      const space = await resources
        .getWithPromise<Space>(spaceId)
        .catch(() => ({ maybeData: undefined }))
      if (space.maybeData) {
        navigate(`/space/${spaceId}`, {
          replace: true,
        })
      } else {
        setErrorMessage('Space not found')
      }
    },
    fl: async () => {
      const fileId = resourceId
      const file = await resources
        .getWithPromise<Workbook>(fileId)
        .catch(() => ({ maybeData: undefined }))
      const spaceId = file.maybeData?.spaceId
      if (file && spaceId) {
        navigate(`/space/${spaceId}/files`, {
          replace: true,
        })
      } else {
        setErrorMessage('File not found')
      }
    },
  }
}

export const ResourceRedirector: FC<{}> = () => {
  const { resourceId } = useRouteParams()
  const navigate = useNavigate()
  const [_, resourceType] = fromMaybe(resourceId, '').split('_')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const handlers = getHandlers(resourceId, navigate, setErrorMessage)
  useEffect(() => {
    const fetchData = async () => {
      if (!['sh', 'wb', 'sp', 'fl'].includes(resourceType)) {
        setErrorMessage('Not a valid ID')
      } else {
        const key = resourceType as 'sh' | 'wb' | 'sp' | 'fl'
        await handlers[key]()
      }
    }
    fetchData()
  }, [])

  if (errorMessage) {
    return <ErrorState title={errorMessage} />
  }
  return SpacesUISkeleton()
}
