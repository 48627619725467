import {
  DefaultApi,
  Document,
  Space,
  SpacePattern,
  Workbook,
} from '@flatfile/api'
import { createContext, useMemo } from 'react'
import { SpaceMeta } from '../default-apps/SpaceApp/Theme/ThemeUtils'

import { SidebarCollapsed } from '../hooks/useSidebarCollapsed'
import { ApiPlatform, Platform } from '../packages/HttpClient'
import { RuntimeParameters } from '../packages/RuntimeParameters'

export const mockSpace: Space = {
  id: '1',
  name: 'A Space',
  spaceConfigId: 'cfg_0',
  environmentId: 'env_0',
  createdAt: new Date('2020-01-01T00:00:00.000Z'),
  updatedAt: new Date('2020-01-01T00:00:00.000Z'),
  guestAuthentication: [],
}
export interface SpaceContextType {
  documents: Document[]
  httpClient: DefaultApi
  sidebarCollapsed?: SidebarCollapsed
  space: Space
  availableSpaces: Space[]
  setCurrentSpace: (spaceId: string) => void
  spaceConfig: SpacePattern
  workbooks: Workbook[]
  metadata?: SpaceMeta
  workbookRefetch: () => void
}

export const SpaceContext = createContext<SpaceContextType>({
  httpClient: Platform,
  space: mockSpace,
  availableSpaces: [mockSpace],
  setCurrentSpace: () => {},
  workbookRefetch: () => {},
  spaceConfig: {
    id: mockSpace.spaceConfigId as string,
    name: 'Foo bar',
    slug: 'slug slug',
    blueprints: [],
  },
  documents: [],
  workbooks: [],
  metadata: undefined,
})

export const SpaceContextProvider = (props: {
  children: JSX.Element
  value: SpaceContextType
}) => {
  const { httpClient } = useSpaceContext()
  return (
    <SpaceContext.Provider value={{ ...props.value, httpClient }}>
      {props.children}
    </SpaceContext.Provider>
  )
}

export const useSpaceContext = () => {
  const { accessToken } = RuntimeParameters()
  const httpClient = useMemo(() => {
    return ApiPlatform(accessToken)
  }, [accessToken])
  return { httpClient }
}
