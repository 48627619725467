import { JobModeEnum } from '@flatfile/api'
import { JobOperationEnum } from '../contexts/JobsContext'
import { useRunningJobs } from './useRunningJobs'

export const useBlockingJobs = (
  id: string | undefined,
  modes: JobModeEnum[] = [JobModeEnum.Foreground]
) => {
  const runningJobs = useRunningJobs()
  const blockingJobs = runningJobs
    .filter((j) => j.mode && modes.includes(j.mode))
    .filter((j) =>
      j.operation == JobOperationEnum.Map
        ? id && j.destination === id
        : id && j.source === id
    )
  return blockingJobs
}
