import { Document as SpaceDocument } from '@flatfile/api'
import { useRef } from 'react'
import Markdown from 'react-remarkable'
import { DocumentContainer } from '../elements/DocumentContainer'
import { HeadingRow } from '../elements/PrimaryHeading'

export function Document({ document: doc }: { document: SpaceDocument }) {
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <DocumentContainer ref={containerRef}>
      <HeadingRow />
      <Markdown source={doc.body} options={{ html: true }} />
    </DocumentContainer>
  )
}
