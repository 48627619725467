import { useContext } from 'react'
import {
  Button,
  ModalText,
  ModalButtonRow,
  ModalHeader,
} from '@flatfile/design-system'
import { SpaceContext } from '../contexts/SpaceContext'
import { Action } from '@flatfile/api'
import { JobsContext } from '../contexts/JobsContext'

export const AcknowledgeModalContent = ({
  job,
  resource,
  locallyOutcomeAck,
}: {
  job: any
  resource: any
  locallyOutcomeAck: (jobId: string) => void
}) => {
  const { httpClient } = useContext(SpaceContext)

  const { jobsRequest } = useContext(JobsContext)
  const onAck = (id: string) => async () => {
    locallyOutcomeAck(id)
    await httpClient.acknowledgeJobOutcome({
      jobId: id,
    })
    jobsRequest?.reload()
  }

  const action =
    job && resource && resource.actions
      ? resource.actions.find((a: Action) => a.operation === job.operation)
      : undefined
  const resourceName = resource?.name || 'this resource'
  const jobName = action?.label || 'Job'

  const outcomeHeading =
    job?.outcome?.heading ||
    (job.status === 'failed' ? 'Job Failed' : 'Success!')

  const outcomeMessage =
    job?.outcome?.message || `${jobName} ran on ${resourceName} successfully`
  const buttonText = job?.outcome?.buttonText || 'Continue'
  return (
    <>
      <ModalHeader>{outcomeHeading}</ModalHeader>
      <ModalText>{outcomeMessage}</ModalText>
      <ModalButtonRow>
        <Button variant='primary' onPress={onAck(job.id)} label={buttonText} />
      </ModalButtonRow>
    </>
  )
}
