import { Sheet, Workbook } from '@flatfile/api'
import { Icon } from '@flatfile/design-system'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SpaceContext } from '../../contexts/SpaceContext'
import { AdminBadge } from '../../elements/Badge'
import { checklistIcon } from '../../resources/icons/checklist'
import { fileIcon } from '../../resources/icons/file'
import { flatfileIcon } from '../../resources/icons/flatfile-icon'
import { sheetIcon } from '../../resources/icons/sheet'
import { isPinnedWorkbook, sortWorkbooks } from '../../utils/workbooks'
import { ElementOrSpinner } from '../ElementOrSpinner'
import { ReadOnlyIcon } from '../ReadOnly'
import { RuntimeParameters } from '../RuntimeParameters'
import { logout } from '../logout'
import { SidebarHeading } from './SidebarHeading'
import { SidebarLogo } from './SidebarLogo'
import { useTranslation } from 'react-i18next'
import {
  SidebarContents,
  SidebarDivider,
  SidebarFooter,
  SidebarFooterLink,
  SidebarHeader,
  SidebarItem,
  SidebarItemEmptyText,
  SidebarItemText,
  SidebarList,
} from './elements'

export const ADMIN_TOOLTIP_CONTENT = 'This is an admin only feature'

export const Sidebar = () => {
  const { t } = useTranslation()
  const spaceContext = useContext(SpaceContext)
  const spaceRoot = `/space/${spaceContext.space.id}`
  const { accessToken, returnUrl, isGuest } = RuntimeParameters()
  const logoutClick = logout.bind(undefined, location)
  const [showPoweredByFlatfile, setShowPoweredByFlatfile] = useState(true)
  const [showDataChecklist, setShowDataChecklist] = useState(true)
  const [showGuestInvite, setShowGuestInvite] = useState(!isGuest)

  const showAdminPages = !isGuest // TODO: add admin role check when that is available

  const workbooks = useMemo(
    () => sortWorkbooks(spaceContext.workbooks),
    [spaceContext.workbooks]
  )

  useEffect(() => {
    const showPoweredBy =
      spaceContext.metadata?.sidebarConfig?.showPoweredByFlatfile
    const showChecklist =
      spaceContext.metadata?.sidebarConfig?.showDataChecklist
    const showInvite = spaceContext.metadata?.sidebarConfig?.showGuestInvite
    if (
      showPoweredBy !== undefined &&
      showPoweredBy !== showPoweredByFlatfile &&
      isGuest
    )
      setShowPoweredByFlatfile(showPoweredBy)
    if (
      showChecklist !== undefined &&
      showChecklist !== showDataChecklist &&
      isGuest
    )
      setShowDataChecklist(showChecklist)
    if (showInvite !== undefined && showInvite !== showGuestInvite && isGuest) {
      setShowGuestInvite(showInvite)
    }
  }, [spaceContext.metadata?.sidebarConfig])

  return (
    <>
      <SidebarContents data-testid='sidebar-contents'>
        <SidebarHeader>
          <SidebarLogo logo={spaceContext.metadata?.theme?.sidebar?.logo} />
          <SidebarHeading spaceContext={spaceContext} />
        </SidebarHeader>
        <SidebarList>
          {spaceContext.documents?.map((doc) => (
            <SidebarItem key={doc.id} to={`${spaceRoot}/document/${doc.id}`}>
              <SidebarItemText>{doc.title}</SidebarItemText>
            </SidebarItem>
          ))}
          {spaceContext.documents.length > 0 && <SidebarDivider />}
          {showDataChecklist && (
            <SidebarItem to={`${spaceRoot}/checklist`}>
              {checklistIcon}
              {t('sidebar.checklist')}
            </SidebarItem>
          )}
          <SidebarItem to={`${spaceRoot}/files`}>
            {fileIcon}
            {t('sidebar.files')}
          </SidebarItem>
          {showAdminPages && (
            <SidebarItem to={`${spaceRoot}/secrets`}>
              <Icon name='key' />
              Secrets
              <AdminBadge />
            </SidebarItem>
          )}
          {showGuestInvite && !isGuest && (
            <SidebarItem
              data-testid='guest-link'
              to={`${spaceRoot}/manage`}
              title='Visible to admins only'
            >
              <Icon name='userGroup' />
              Manage guests
              <AdminBadge />
            </SidebarItem>
          )}
          <SidebarDivider />
          {workbooks.map((workbook) => (
            <SidebarWorkbookItem
              key={workbook.id}
              workbook={workbook}
              spaceRoot={spaceRoot}
              single={workbooks.length === 1}
            />
          ))}
        </SidebarList>
      </SidebarContents>
      <SidebarFooter>
        {!isGuest ? (
          <SidebarFooterLink
            href={returnUrl}
            target='_top'
            data-testid='back-to-dashboard'
          >
            &larr; Back to Dashboard
          </SidebarFooterLink>
        ) : (
          showPoweredByFlatfile && (
            <SidebarFooterLink
              href={`https://www.flatfile.com?utm_source=full_space&utm_medium=in_app&utm_campaign=referrer&utm_content=${spaceContext.space.id}`}
              target='_blank'
              data-testid='referrer-link'
              rel='noreferrer'
            >
              {flatfileIcon}
              Powered by <b>Flatfile</b>
            </SidebarFooterLink>
          )
        )}
        {!isGuest && accessToken && (
          <SidebarFooterLink href='' onClick={logoutClick}>
            Logout &rarr;
          </SidebarFooterLink>
        )}
      </SidebarFooter>
    </>
  )
}

const SidebarSheetItem = ({ sheet, to }: { sheet: Sheet; to: string }) => {
  return (
    <SidebarItem to={to}>
      <ElementOrSpinner id={sheet.id}>{sheetIcon}</ElementOrSpinner>
      <SidebarItemText>{sheet.name}</SidebarItemText>
      {(sheet.config?.readonly ||
        (sheet.config.access && sheet.config.access.length == 0)) && (
        <ReadOnlyIcon />
      )}
    </SidebarItem>
  )
}

const SidebarWorkbookItem = ({
  workbook,
  spaceRoot,
  single,
}: {
  workbook: Workbook
  spaceRoot: string
  single?: boolean
}) => {
  const { t } = useTranslation()
  const baseLink = `${spaceRoot}/workbook/${workbook.id}`
  const location = useLocation()
  const isActive = (p: string) => {
    return location.pathname.startsWith(p)
  }

  if (single) {
    return (
      <>
        {workbook.sheets?.map((s) => (
          <SidebarSheetItem
            key={s.id}
            to={`${baseLink}/sheet/${s.id}`}
            sheet={s}
          />
        ))}
      </>
    )
  }

  if (!workbook.sheets?.length) {
    return (
      <SidebarItem key={workbook.id}>
        <SidebarItemEmptyText>
          {t('sidebar.emptyWorkbook')}
        </SidebarItemEmptyText>
      </SidebarItem>
    )
  }

  return (
    <SidebarItem
      data-testid={`${workbook.name}-list-item`}
      active={isActive(baseLink)}
      to={`${baseLink}/sheet/${workbook.sheets![0].id}`}
      list={
        <SidebarList>
          {workbook.sheets?.map((s) => (
            <SidebarSheetItem
              key={s.id}
              to={`${baseLink}/sheet/${s.id}`}
              sheet={s}
            />
          ))}
        </SidebarList>
      }
    >
      <ElementOrSpinner id={workbook.id}>
        {isPinnedWorkbook(workbook) ? (
          <Icon name='pin' data-testid='pin-icon' />
        ) : (
          <Icon name='database' />
        )}
      </ElementOrSpinner>
      <SidebarItemText>{workbook.name}</SidebarItemText>
    </SidebarItem>
  )
}
