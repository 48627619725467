import { JobStatusEnum } from '@flatfile/api'
import { useContext, useMemo } from 'react'
import { JobsContext } from '../contexts/JobsContext'

export const useRunningJobs = () => {
  const { activeJobs } = useContext(JobsContext)
  const runningJobs = useMemo(
    () =>
      Object.values(activeJobs ?? []).filter(
        (j) =>
          j.status == JobStatusEnum.Ready || j.status == JobStatusEnum.Executing
      ),
    [activeJobs]
  )
  return runningJobs
}
