import { Configuration, DefaultApi } from '@flatfile/api'
import { RuntimeParameters } from './RuntimeParameters'

const { accessToken } = RuntimeParameters()

function ClientConfig(token?: string) {
  return new Configuration({
    accessToken: token ?? accessToken,
    basePath: import.meta.env.VITE_BASE_API_URL ?? 'http://localhost:3000/v1',
  })
}

export const Platform = new DefaultApi(ClientConfig())
// TODO: Understand and document the right way to use the client
/* c8 ignore start */
export const ApiPlatform = (token: string) =>
  new DefaultApi(ClientConfig(token))
/* c8 ignore stop */

const [files, pipelines, spaces, workbooks, environments] = [
  DefaultApi,
  DefaultApi,
  DefaultApi,
  DefaultApi,
  DefaultApi,
  DefaultApi,
].map((ApiConstructor) => new ApiConstructor(ClientConfig())) as [
  DefaultApi,
  DefaultApi,
  DefaultApi,
  DefaultApi,
  DefaultApi,
  DefaultApi
]

export const HttpClient = {
  files,
  pipelines,
  spaces,
  workbooks,
  environments,
}
export type HttpClient = typeof HttpClient

export type HttpClientType = typeof HttpClient
