import { Job, Property, Sheet } from '@flatfile/api'
import { Primitive } from '../../resources/common'

export interface FieldOption {
  label: JSX.Element | string
  value: string
  description?: string
  required?: boolean
}

export class SheetController {
  constructor(private sheet: Sheet, private job: Job) {}

  /**
   * Get a specific property from a blueprint by key. If the blueprint
   * does not have a label yet, make the key the label.
   *
   * @note it's questionable to make the key the label here - blueprint
   *       does not require label so our UI shold probably not either
   *
   * @param key Property key
   */
  getProperty(key?: string | null): Property | null {
    const prop = this.sheet.config?.fields.find((field) => field.key === key)

    return prop ? this.__normalizeProperty(prop) : null
  }

  /**
   * Return the number of fields in this sheet, always an int
   */
  get fieldCount(): number {
    return this.sheet.config?.fields.length || 0
  }

  /**
   * Return the fields in this sheet
   */
  get fields(): Property[] {
    return this.sheet.config?.fields || []
  }

  /**
   * Return the required fields in this sheet
   */
  get requiredFields(): Property[] {
    return (
      this.sheet.config?.fields.filter(
        (field) =>
          field.constraints?.some((c) => c.type === 'required') || false
      ) || []
    )
  }

  /**
   * Given an enum property key, return a list of options compatible with
   * react-select
   *
   * @todo clean up API return types - for some reason there's a stub
   *       EnumPropertyOptionValue in the API with no typing
   *
   * @param key
   */
  getEnumOptions(
    key: string
  ): Array<{ label: string; value: Primitive; description?: string }> {
    const field = this.sheet.config?.fields.find((f) => f.key === key)

    if (field?.type !== 'enum') {
      throw new Error('Trying to read options from a non enum type')
    }

    return (
      field.config.options.map((option) => ({
        value: option.value as Primitive,
        label: option.label || (option.value as string),
        description: option?.description,
      })) || []
    )
  }

  /**
   * Return a list of fields formatted for react-select.
   */
  getFieldOptions(): Array<FieldOption> {
    return (
      this.sheet.config?.fields
        .filter((f) => !f.constraints?.some((c) => c.type === 'computed'))
        .map((field) => ({
          value: field.key,
          label: field.label || field.key,
          description: field.description,
          required: field.constraints?.some((f) => f.type === 'required'),
        })) || []
    )
  }

  /**
   * Make sure label is populated if it's not yet
   * @param prop
   * @private
   */
  private __normalizeProperty(
    prop: Property
  ): Property & Required<{ label: string }> {
    return { ...prop, label: prop.label || prop.key }
  }
}

type UniqueFieldValues = Record<string, Primitive[]>
