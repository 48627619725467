import { ReactElement } from 'react'
import { ErrorState } from '../../elements/EmptyState'
import { Observable } from '../observable'
import { guard } from './GuardResources'
import { useResource } from './useResource'
import { useTranslation } from 'react-i18next'

export type InnerTypes<T extends ReadonlyArray<any>> = {
  -readonly [P in keyof T]: T[P] extends infer Element
    ? Element extends Observable<infer U, any>
      ? U
      : Element
    : never
}

export type Writable<T extends ReadonlyArray<any>> = {
  -readonly [P in keyof T]: T[P]
}

export function InjectResource<T extends { updatedAt: Date }>(props: {
  id: string | undefined
  children: (l: T) => any
  loadingContent?: ReactElement
  emptyContent?: ReactElement
  errorContent?: (err?: string) => ReactElement
  reloadInBackground?: boolean
  emptyProp?: string
}) {
  const { id, ...rest } = props
  const { t } = useTranslation()
  const errorString = t('errors.missingParameter')
  if (!id) {
    return (
      <>
        {props.errorContent ? (
          props.errorContent(errorString)
        ) : (
          <ErrorState title={errorString} />
        )}
      </>
    )
  } else return <InjectResourceInner id={id} {...rest} />
}

export function InjectResourceInner<T extends { updatedAt: Date }>(props: {
  id: string
  children: (l: T) => JSX.Element
  loadingContent?: ReactElement
  emptyContent?: ReactElement
  errorContent?: (err?: string) => ReactElement
  reloadInBackground?: boolean
  emptyProp?: string
}): JSX.Element {
  const { id, children, ...options } = props
  const [_, observable] = useResource<T>(id, false)
  return guard(observable, options) ?? children(observable.data! as T)
}

export function InjectResources<
  TA extends ReadonlyArray<{ updatedAt: Date }>
>(props: {
  ids: readonly (string | undefined)[]
  children: (l: TA) => any
  loadingContent?: ReactElement
  emptyContent?: ReactElement
  errorContent?: (err?: string) => ReactElement
  reloadInBackground?: boolean
  emptyProp?: string
}) {
  const { ids, ...rest } = props
  const { t } = useTranslation()
  const errorString = t('errors.missingParameter')
  if (!ids || ids.some((id) => !id)) {
    return (
      <>
        {props.errorContent ? (
          props.errorContent(errorString)
        ) : (
          <ErrorState title={errorString} />
        )}
      </>
    )
  } else
    return <InjectResourcesInner ids={ids as readonly string[]} {...rest} />
}

export function InjectResourcesInner<
  TA extends ReadonlyArray<{ updatedAt: Date }>
>(props: {
  ids: readonly string[]
  children: (l: TA) => any
  loadingContent?: ReactElement
  emptyContent?: ReactElement
  errorContent?: (err?: string) => ReactElement
  reloadInBackground?: boolean
  emptyProp?: string
}) {
  const { ids, children, ...options } = props
  const observables = ids.map((id) => useResource(id, false)[1])
  const guards = guard(observables as any, options)
  if (guards) {
    return guards
  }
  return children(observables.map((o: any) => o.data) as Writable<TA>)
}
