import {
  Button,
  ModalText,
  ModalButtonRow,
  ModalHeader,
} from '@flatfile/design-system'
import { useState } from 'react'

export interface ModalProps {
  closeModal?: () => void
  onSubmit?: (arg?: any) => void
  onCancel?: () => void
  text?: {
    heading: string
    description: string
    confirmButtonText: string
    loadingText?: string
    errorMessage?: string
  }
  hasError?: boolean
  isSubmitting?: boolean
}

export const ActionConfirmModalContent = (props: ModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const { text, onSubmit } = props

  const handleSubmit = async () => {
    if (onSubmit) {
      setSubmitting(true)
      await onSubmit()
      setSubmitting(false)
    }
  }

  const handleCancel = () => {
    if (props.onCancel) props.onCancel()
    if (props.closeModal) props.closeModal()
  }

  const isSubmitting = submitting || props.isSubmitting

  return (
    <>
      <ModalHeader>{text?.heading}</ModalHeader>
      <ModalText>{text?.description}</ModalText>
      <ModalButtonRow>
        <Button
          variant='tertiary-border'
          onPress={handleCancel}
          isDisabled={isSubmitting}
          label='Cancel'
        />
        {isSubmitting ? (
          <Button label={text?.loadingText || 'Loading...'} loading />
        ) : (
          <Button
            type='submit'
            onPress={handleSubmit}
            label={text?.confirmButtonText || 'Confirm'}
          />
        )}
      </ModalButtonRow>
    </>
  )
}
