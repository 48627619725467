import { Workbook } from '@flatfile/api'
import { DataBufferProvider, TableProviders } from '@flatfile/turntable'
import { useContext, useMemo } from 'react'
import { InjectResource } from '../../api/resources/InjectResource'
import { FileUploadRedirectProvider } from '../../contexts/FileUploadRedirectContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ErrorState } from '../../elements/EmptyState'
import { useRouteParams } from '../../hooks/useRouteParams'
import { SheetView } from './SheetView'
import { useRecordMutation } from './hooks/useRecordMutation'

export const SheetRoute = () => {
  const { space } = useContext(SpaceContext)
  const { workbookId, sheetId } = useRouteParams()
  const { fetchRecords, addOrUpdateRecords } = useRecordMutation(sheetId!)

  const importToSheet = useMemo(
    () =>
      sheetId && workbookId
        ? {
            workbookId,
            sheetId,
          }
        : undefined,
    [sheetId, workbookId]
  )

  return (
    <InjectResource<Workbook>
      id={workbookId}
      errorContent={() => {
        return <ErrorState message='Unable to fetch workbook.' />
      }}
    >
      {(workbook) => {
        return (
          <FileUploadRedirectProvider importToSheet={importToSheet}>
            <DataBufferProvider
              onLoadRows={fetchRecords}
              onUpdateRows={addOrUpdateRecords}
            >
              <TableProviders tableId={sheetId}>
                <SheetView
                  spaceUrl={`/space/${space.id}`}
                  workbook={workbook!}
                />
              </TableProviders>
            </DataBufferProvider>
          </FileUploadRedirectProvider>
        )
      }}
    </InjectResource>
  )
}
