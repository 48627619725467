import { ErrorContext, EventTopic, RecordCounts, Sheet } from '@flatfile/api'
import {
  PopoverContext,
  PopoverMessage,
  WarningIcon,
  fromMaybe,
} from '@flatfile/shared-ui'
import {
  ColumnConfigProps,
  OnSearchValue,
  RowData,
  useDataBufferContext,
  useTableScroller,
} from '@flatfile/turntable'
import { useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { useEventSubscriber } from '../../../hooks/useEventSubscriber'
import { useSheetHasJob } from '../../../hooks/useSheetHasJob'
import { getCurrentRowCount, getToolbarCounts } from '../utils/tableUtils'
import {
  getFilteredRecordCountsQueryKey,
  getRecordCountsQueryKey,
  useRecordCountsData,
} from './useRecordCountsData'
import { getRecordsQueryKey } from './useRecordMutation'
import { SearchFields } from './useSearchFilters'
import { useTranslation } from 'react-i18next'

interface UseSheetDataProps {
  sheetId: string
  sheet: Sheet | undefined
  columnConfig: ColumnConfigProps[]
  searchFields: SearchFields
  readOnlySheet?: boolean
  canDeleteFromSheet: boolean
  canAddToSheet: boolean
  handleSearchByValue: OnSearchValue
}

export interface ToolbarRecordCounts extends RecordCounts {
  filtered?: number
}
export type OnRefetchRows = (options?: { counts: boolean }) => Promise<void>

export const PAGE_SIZE = 100

export const getHasSearchFilters = (filters: SearchFields) => {
  return Object.values(filters).some((f) => !!f)
}

export const useSheetViewData = ({
  sheetId,
  sheet,
  columnConfig,
  searchFields,
  readOnlySheet,
  canDeleteFromSheet,
  canAddToSheet,
  handleSearchByValue,
}: UseSheetDataProps) => {
  const [rowCount, setRowCount] = useState<number>(0)
  const [toolbarCounts, setToolbarCounts] = useState<
    ToolbarRecordCounts | undefined
  >(undefined)
  const [showEmptyOverlay, setShowEmptyOverlay] = useState(false)
  const { t } = useTranslation()
  const versionIdsRef = useRef<{
    lastVersionId?: string
    errorCountsVersionId?: string
  }>({
    lastVersionId: undefined,
    errorCountsVersionId: undefined,
  })

  const isEmptyLoading = useRef(true)

  const queryClient = useQueryClient()
  const { httpClient } = useContext(SpaceContext)
  const { showPopover, hidePopover } = useContext(PopoverContext)
  const { buffer, initLoad, isLoading, isErrored, onLoadRows } =
    useDataBufferContext()
  const { scrollToTop } = useTableScroller()
  const sheetHasJob = useSheetHasJob(sheetId, sheet?.workbookId)

  const hasBlockingJobs = useRef(sheetHasJob)

  useEffect(() => {
    hasBlockingJobs.current = sheetHasJob
  }, [sheetHasJob])

  const {
    counts,
    filteredCounts,
    refetchCounts,
    refetchFilteredCounts,
    isLoadingCounts,
    hasCountsError,
    refetchErrorsByFieldCounts,
  } = useRecordCountsData({
    sheetId,
    ...searchFields,
  })

  const cancelGetRecordsQuery = () => {
    queryClient.cancelQueries({ queryKey: [getRecordsQueryKey] })
  }

  const cancelCountsQueries = () => {
    queryClient.cancelQueries({
      queryKey: [getRecordCountsQueryKey, getFilteredRecordCountsQueryKey],
    })
  }

  const clearBufferHistories = () => {
    /* Removes history from the buffer so that fresh data gets loaded */
    const bufferId = buffer._bufferId
    const bufferKeys = Array.from(buffer._recordsCache.keys())
    bufferKeys.forEach((key) => {
      buffer._pageCache.delete(key)

      if (key !== bufferId) {
        /*
          For the current page, we don't remove these values in order to prevent
          visual disruption.
        */
        buffer._cellHistoryCache.delete(key)
        buffer._recordsCache.delete(key)
        buffer._totalRecordsCache.delete(key)
      }
    })
  }

  const hideEmptyOverlay = useCallback(
    () => setShowEmptyOverlay(false),
    [setShowEmptyOverlay]
  )

  const handleEmptyOverlay = () => {
    if (!getHasSearchFilters(searchFields) && !buffer.bufferedRecords.length) {
      setShowEmptyOverlay(true)
    } else {
      setShowEmptyOverlay(false)
    }
  }

  const refetchRows: OnRefetchRows = async (options) => {
    cancelGetRecordsQuery()

    if (options?.counts) {
      cancelCountsQueries()

      await refetchCounts()
      await refetchFilteredCounts()
    }

    buffer.onDataLoadError = undefined
    await buffer.loadPages()

    buffer.onDataLoadError = handlePageLoadError
    handleEmptyOverlay()
  }

  const handleQueryLoadError = () => {
    const popover = {
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          {t('sheet.popovers.errors.invalidFFQL')}
        </PopoverMessage>
      ),
      action: {
        onClick: () => {
          handleSearchByValue({ searchValue: '', searchField: '', q: '' })
          hidePopover()
        },
        label: 'Clear',
      },
      onClose: hidePopover,
    }
    showPopover(popover)
    return popover
  }

  const handlePageLoadError = () => {
    const popover = {
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessage>
          {t('sheet.popovers.errors.recordLoadFail')}
        </PopoverMessage>
      ),
      action: {
        onClick: async () => {
          hidePopover()
          await refetchRows()
        },
        label: 'Retry',
      },
      onClose: hidePopover,
    }
    showPopover(popover)
    return popover
  }

  const handleCellUpdateError = useCallback(
    (error: ErrorContext) => {
      /**
       * 304 Not Modified on cell edit means the update has not been made because it would
       * not modify the result. This can happen when agent code fills in a null value and the user
       * tries to delete the agent generated value. The back end compares null with null and determines
       * the update does not need to happen, so a 304 status is returned.
       */
      if (error.response?.status === 304) {
        return
      }
      const popover = {
        icon: <WarningIcon name='alertTriangle' />,
        message: (
          <PopoverMessage>
            {t('sheet.popovers.errors.cellUpdateFailed')}
          </PopoverMessage>
        ),
        onClose: hidePopover,
      }
      showPopover(popover)
    },
    [showPopover, hidePopover]
  )

  const handleInitLoad = async () => {
    await initLoad()
    isEmptyLoading.current = false
    handleEmptyOverlay()
    buffer.onDataLoadError = handlePageLoadError
    buffer.onDataUpdateError = handleCellUpdateError
  }

  useEffect(() => {
    if (isErrored && searchFields.q) {
      handleQueryLoadError()
    }
  }, [isErrored])

  useEffect(() => {
    const onLoad = async () => {
      cancelGetRecordsQuery()
      scrollToTop()
      handleEmptyOverlay()

      buffer.initialize({
        bufferId: `${fromMaybe(sheetId, '')}:${JSON.stringify(searchFields)}`,
        params: { searchFields },
        pageSize: PAGE_SIZE,
        columnConfig,
        totalRecords: rowCount,
        onDataAddSuccess: () => refetchRows({ counts: true }),
      })
      buffer._lockActiveRecords = true

      if (!buffer.bufferedRecords.length) {
        isEmptyLoading.current = true
      } else {
        buffer.refreshCurrentPages()
      }

      await handleInitLoad()
    }
    onLoad()
  }, [searchFields, columnConfig, sheetId])

  useEffect(() => {
    buffer.setTotalRecords(rowCount)
    /* Removes cell edit history from the current buffer because the data shape has changed */
    buffer._cellHistoryCache.delete(buffer._bufferId)
  }, [rowCount])

  const hasError = !sheet || isErrored
  const hasNoData = hasError || isLoading || isLoadingCounts

  const handleVersionUpdated = async ({
    sinceVersionId,
  }: {
    sinceVersionId: string
  }) => {
    const bufferId = buffer._bufferId
    const result = await onLoadRows({ sinceVersionId }, columnConfig)
    const bufferedRecords = buffer.bufferedRecords
    const records = result?.reduce((memo: RowData[], record: RowData) => {
      const bufferedRecord = bufferedRecords.find(
        (r: RowData) => r.id === record.id
      )
      if (bufferedRecord) {
        memo.push({
          ...record,
          position: bufferedRecord.position,
          rowIndex: bufferedRecord.rowIndex,
        })
      }
      return memo
    }, [] as RowData[])
    buffer.addRecords({ records, bufferId })
  }

  const handleRecordsChangedEvent = async (event: any) => {
    const eventResponse = fromMaybe(JSON.parse(event.message), {})
    const { context } = eventResponse
    const correctSheet = context && context.sheetId === sheetId

    if (!correctSheet) {
      return
    }
    if (hasBlockingJobs.current) {
      return
    }

    if (context.versionId) {
      versionIdsRef.current.lastVersionId = context.versionId
    }
    /*
      Temporarily removing fetching with sinceVersionId, as a versionId can be
      provided when we want to make a full refetch.

      if (context.versionId) {
        clearBufferHistories()
        await handleVersionUpdated({ sinceVersionId: context.versionId })
      } else {
    */
    clearBufferHistories()
    await refetchRows({ counts: true })
    /*
      }
    */
  }

  const handleJobCompletedEvent = async (event: any) => {
    const eventResponse = fromMaybe(JSON.parse(event.message), {})
    const { context } = eventResponse
    const correctSheet =
      context &&
      (context.sheetId === sheetId ||
        (context.workbookId && context.workbookId === sheet?.workbookId))

    if (!correctSheet) {
      return
    }

    clearBufferHistories()
    await refetchRows({ counts: true })
  }

  const getErrorsByFieldCounts = async () => {
    if (
      !counts?.errorsByField ||
      versionIdsRef.current.lastVersionId !==
        versionIdsRef.current.errorCountsVersionId
    ) {
      await refetchErrorsByFieldCounts()
      versionIdsRef.current.errorCountsVersionId =
        versionIdsRef.current.lastVersionId
    }
  }

  useEventSubscriber(
    [EventTopic.Commitcreated, EventTopic.Layercreated],
    handleRecordsChangedEvent
  )

  useEventSubscriber([EventTopic.Jobcompleted], handleJobCompletedEvent)

  useEffect(() => {
    if ((hasError && !isLoading) || (isLoading && isEmptyLoading.current)) {
      setRowCount(0)
    } else if (toolbarCounts && !hasError) {
      setRowCount(getCurrentRowCount(searchFields, toolbarCounts))
    }
  }, [toolbarCounts, searchFields, hasError, isLoading, isEmptyLoading])

  useEffect(() => {
    if (counts && filteredCounts) {
      const countsWithFiltered = getToolbarCounts(
        searchFields,
        counts,
        filteredCounts
      )

      if (!isEqual(countsWithFiltered, toolbarCounts)) {
        setToolbarCounts(countsWithFiltered)
      }
    }
  }, [counts, searchFields, filteredCounts])

  const additionalRowCount = useMemo(() => {
    const empty = hasNoData && isEmptyLoading.current
    if (empty || readOnlySheet || !canAddToSheet) {
      return undefined
    }
    if (!canDeleteFromSheet) {
      return 1
    }
    return 15
  }, [
    hasNoData,
    readOnlySheet,
    canAddToSheet,
    canDeleteFromSheet,
    rowCount,
    isEmptyLoading,
  ])

  const errorFields = useMemo(() => {
    return columnConfig.reduce<{ key: string; label: string; badge: number }[]>(
      (memo, column) => {
        const count = counts?.errorsByField?.[column.value]
        if (count) {
          memo.push({
            key: column.value,
            label: column.label ?? column.value,
            badge: count,
          })
        }
        return memo
      },
      []
    )
  }, [columnConfig, counts])

  return {
    counts: toolbarCounts,
    filteredCounts,
    isLoading,
    isLoadingCounts,
    isLoadingEmpty: (isLoading || isLoadingCounts) && isEmptyLoading.current,
    isEmpty: hasNoData,
    hasError,
    tableError: isErrored,
    hasCountsError,
    refetchRows,
    rowCount,
    errorFields,
    additionalRowCount,
    showEmptyOverlay,
    hideEmptyOverlay,
    onVersionUpdated: handleVersionUpdated,
    onQueryLoadError: handleQueryLoadError,
    onRecordsChangedEvent: handleRecordsChangedEvent,
    onPageLoadError: handlePageLoadError,
    onCellUpdateError: handleCellUpdateError,
    onJobCompletedEvent: handleJobCompletedEvent,
    getErrorsByFieldCounts,
  }
}
