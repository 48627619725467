import { AMP_TOOLTIP_ID } from '@flatfile/design-system'
import { ADMIN_TOOLTIP_CONTENT } from '../packages/Sidebar'

import styled from 'styled-components'

export interface BadgeProps {
  'data-required'?: boolean
  'data-unique'?: boolean
  'data-computed'?: boolean
}

export const Badge = styled.span<BadgeProps>`
  border-radius: var(--layout-border-radius);
  border: 1px solid var(--color-border);
  font-style: normal;
  font-weight: 600;
  font-size: var(--size-small);
  line-height: 1;
  padding: 0.25em 0.5em;
  word-break: normal;

  &[data-required] {
    color: var(--color-danger);
  }

  &[data-unique] {
    color: var(--color-text-light);
  }
`

export const ListItemBadge = styled(Badge)`
  border-radius: 12px;
  margin-left: auto;
  border-color: var(--color-sidebar-border);
`

export const AdminBadge = () => {
  return (
    <ListItemBadge
      data-tooltip-id={AMP_TOOLTIP_ID}
      data-tooltip-content={ADMIN_TOOLTIP_CONTENT}
    >
      Admin
    </ListItemBadge>
  )
}
