import * as React from 'react'
import { Icon, Button } from '@flatfile/design-system'
import styled, { css } from 'styled-components'

export const EmptyStateWrapper = styled.div<{
  dashed?: boolean
  error: boolean
}>`
  align-self: flex-start;
  width: 100%;
  padding: var(--spacing-page-gutter);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;

  hr {
    border: none;
    height: 1px;
    background-color: var(--color-border);
  }

  a {
    color: var(--color-text-light);
    text-decoration: underline;
  }

  a:hover {
    color: var(--color-action);
  }

  & > * {
    transition: color 0.25s ease;
  }

  h2 {
    color: var(--color-title);
    font-size: var(--size-h2);
    font-weight: 500;
    margin-bottom: 0.5em;
  }

  p {
    color: var(--color-text-light);
    font-size: var(--size-p);
    margin-bottom: 1.5em;
  }

  .content-wrapper {
    margin: auto;
    max-width: 27.5em;
    text-align: center;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--layout-border-radius);
    height: 2.5em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
    width: 2.5em;
  }

  ${(props) =>
    props.dashed &&
    css`
      border: 2px dashed var(--color-border);
      border-radius: 0.25em;
    `}

  ${(props) =>
    props.error &&
    css`
      .content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 31.25em;
        margin-top: 0.5em;
      }

      .title-wrapper {
        display: flex;
        align-items: center;
        min-height: 2.25em;
        margin-bottom: 0.5em;
        margin-left: 2.4em;
      }

      .icon-wrapper,
      h2 {
        margin: 0;
      }

      .icon-wrapper svg {
        width: 1.25em;
        color: var(--color-text-light);
      }

      .icon-wrapper {
        height: 2.25em;
        width: 1.25em;
        padding-top: 2px;
        justify-content: flex-start;
        position: absolute;
        margin-left: -2.4em;
      }

      h2 {
        font-family: var(--text-font);
        font-weight: 600;
      }

      p {
        line-height: 17px;
        font-size: var(--size-small);
        margin-bottom: 1em;
      }

      .content-wrapper,
      p,
      h2 {
        text-align: left;
      }

      button {
        margin-top: 0.75em;
      }
    `}
`

interface EmptyStateProps {
  icon?: React.ReactElement
  title?: string
  message?: string
  border?: boolean
  refresh?: boolean
  children?: React.ReactNode | undefined
  type?: 'default' | 'error'
  dataTestId?: string
}

export const EmptyState = ({
  icon,
  title,
  message,
  border,
  children,
  type = 'default',
  dataTestId = 'empty-state',
}: EmptyStateProps) => {
  const error = type === 'error'
  return (
    <EmptyStateWrapper data-testid={dataTestId} dashed={border} error={error}>
      <div className='content-wrapper'>
        <div className='content-inner'>
          <div className='title-wrapper'>
            {icon && <div className='icon-wrapper'>{icon}</div>}
            {title && <h2>{title}</h2>}
          </div>
          {message && <p>{message}</p>}
          {children}
        </div>
      </div>
    </EmptyStateWrapper>
  )
}

const onClickRefresh = () => {
  window?.location.reload()
}

export const ErrorState = ({
  children,
  title,
  icon,
  refresh = true,
  ...props
}: EmptyStateProps) => {
  return (
    <EmptyState
      {...props}
      type='error'
      title={title || 'Something went wrong'}
      icon={icon || <Icon name='alertTriangle' />}
    >
      {children}
      {refresh && (
        <>
          <p>
            Refresh the page to try again. If the problem persists, contact
            support.
          </p>
          <Button variant='secondary' onPress={onClickRefresh} label='Retry' />
        </>
      )}
    </EmptyState>
  )
}
