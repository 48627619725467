import { Workbook } from '@flatfile/api'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FileController } from '../../api/controllers/FileController'
import { useController } from '../../api/controllers/useController'
import { Observable, useAction, useObservable } from '../../api/observable'
import { ContentLoader } from '../../api/observable/ContentLoader'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ActionContainer } from '../../elements/ActionContainer'
import { ActionContent } from '../../elements/ActionContent'
import { Container } from '../../elements/Container'
import { ContentSkeleton } from '../../packages/SpacesUISkeleton'
import { ErrorState } from '../../elements/EmptyState'
import { Reveal } from '../../elements/Reveal'
import { useNavigate } from '../../hooks/useNavigate'
import { useRouteParams } from '../../hooks/useRouteParams'
import { SheetActionBar } from '../../packages/SheetActionBar'
import { SheetSelector } from '../../packages/SheetSelector'
import { useTranslation } from 'react-i18next'

export const ImportFileView = () => {
  const { space, workbooks, httpClient } = useContext(SpaceContext)
  const controller = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { fileId } = useRouteParams() as { fileId: string }
  const [request, file] = useObservable(controller.getFile(fileId))

  const [selectedSheetId, setSelectedSheetId] = useState<string>()
  const [sourceSheetId, setSourceSheetId] = useState<string>()
  const selectedWorkbookId = useMemo(
    () => findWorkbookBySheetId(workbooks, selectedSheetId),
    [workbooks, selectedSheetId]
  )

  const [confirmAndContinue, continueAction] = useAction(
    controller.mapToSheet(),
    (job) => {
      navigate(`/space/${space.id}/job/${job.id}`)
    }
  )

  useEffect(() => {
    if (file?.workbook?.sheets?.[0]) {
      setSourceSheetId(file.workbook!.sheets?.[0]!.id)
    }
  }, [file])

  // @todo if importFile is set and only one workbook + sheet, go straight to mapping

  const renderFiles = useCallback(() => {
    if (request.isLoading) {
      return ContentSkeleton()
    } else if (file) {
      return (
        <SheetSelector
          fileName={file.name}
          sourceWorkbook={file.workbook!}
          onDestinationChange={(_w, sheetId) => setSelectedSheetId(sheetId)}
          onSourceChange={(_w, sheetId) => setSourceSheetId(sheetId)}
        />
      )
    } else {
      return (
        <ErrorState
          title={t('mapping.sheetSelection.cannotLoadFile')}
          dataTestId='empty-error'
        />
      )
    }
  }, [file, request, selectedSheetId, setSelectedSheetId])

  return (
    <Container>
      <TopActionBar
        continueAction={continueAction}
        confirmAndContinue={() =>
          confirmAndContinue({
            sourceWorkbookId: file.workbookId!,
            sourceSheetId: sourceSheetId!,
            destinationWorkbookId: selectedWorkbookId!,
            destinationSheetId: selectedSheetId!,
          })
        }
        continueDisabled={!selectedSheetId || !sourceSheetId}
      />
      <ActionContainer>
        <ActionContent>
          <Reveal>
            <ContentLoader
              observe={request}
              errorContent={
                <ErrorState
                  title={t('mapping.sheetSelection.cannotLoadFile')}
                  dataTestId='loading-error'
                />
              }
            >
              {renderFiles}
            </ContentLoader>
          </Reveal>
        </ActionContent>
      </ActionContainer>
    </Container>
  )
}

/**
 * Get the first workbook holding this SheetId
 *
 * @param workbooks
 * @param sheetId
 */
export function findWorkbookBySheetId(workbooks: Workbook[], sheetId?: string) {
  return workbooks.find((x) => x.sheets?.some((s) => s.id === sheetId))?.id
}

/**
 * The top full screen header for the mapping scene
 *
 * @todo this should be more universal
 *
 * @param spaceUrl
 * @param continueAction
 * @param confirmAndContinue
 * @param continueDisabled
 * @constructor
 */
const TopActionBar: FC<{
  continueAction: Observable
  confirmAndContinue: () => void
  continueDisabled?: boolean
}> = ({ continueAction, confirmAndContinue, continueDisabled }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <SheetActionBar
      headerText={t('mapping.sheetSelection.title')}
      back={{
        label: t('mapping.headerButtons.back'),
        onPress: () => {
          navigate(-1)
        },
      }}
      next={{
        label: t('mapping.headerButtons.next'),
        onPress: confirmAndContinue,
        loading: continueAction.isLoading,
        disabled: continueDisabled,
      }}
    />
  )
}
