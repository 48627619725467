import { Environment } from '@flatfile/api'
import { ZIndex } from '@flatfile/design-system'
import styled from 'styled-components'

const BannerTab = styled.div`
  background-color: var(--color-dev-banner);
  font-size: 14px;
  vertical-align: bottom;
  color: var(--color-white);
  text-align: center;
  width: 152px;
  padding: 0px 24px 3px;
  margin: 0 auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
  font-weight: 500;
`

const BannerTop = styled.div`
  width: 100%;
  position: absolute;
  height: 4px;
  left: 0px;
  top: 0px;
  background-color: var(--color-dev-banner);
  z-index: ${ZIndex.level1200};
`

export const getDevBanner = (environment: undefined | Environment) => {
  return environment && !environment?.isProd ? (
    <BannerTop>
      <BannerTab data-test='dev-banner'>Developer mode</BannerTab>
    </BannerTop>
  ) : null
}
