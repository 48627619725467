import { Space } from '@flatfile/api'
import { Spinner } from '@flatfile/design-system'
import {
  CheckmarkIcon,
  PopoverContext,
  PopoverMessage,
  PopoverMessageFullWidth,
  WarningIcon,
} from '@flatfile/shared-ui'
import { useCallback, useContext, useEffect, useState } from 'react'
import { usePaymentAccess } from '../../../hooks/usePaymentAccess'
import { useTranslation } from 'react-i18next'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { useController } from '../../../api/controllers/useController'
import { FileController } from '../../../api/controllers/FileController'

export interface UploadProgress {
  progress: number
  speed: number
}

interface FileUpload {
  name?: string
  progress?: number
  error?: string
  complete?: boolean
}

export type FileUploads = Record<string, FileUpload>

export const useFileUpload = (space: Space) => {
  const { fileUploadIsLockedUntilPayment } = usePaymentAccess()
  const { showPopover } = useContext(PopoverContext)
  const { httpClient } = useContext(SpaceContext)
  const [fileUploads, setFileUploads] = useState<FileUploads>({})
  const { t } = useTranslation()
  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({
    progress: 0,
    speed: 0,
  })
  const [fileName, setFileName] = useState<string | undefined>()

  useEffect(() => {
    if (fileName && uploadProgress.progress < 100) {
      showPopover({
        icon: <Spinner />,
        message: (
          <>
            <PopoverMessage>
              {t('files.uploadFile.popovers.uploadInProgress', {
                fileName: fileName,
              })}
            </PopoverMessage>
            <span>
              {`(${uploadProgress.progress}%) ${uploadProgress.speed} KB/s`}
            </span>
          </>
        ),
        onClose: undefined,
      })
    } else if (fileName && uploadProgress.progress === 100) {
      showPopover(
        {
          icon: <CheckmarkIcon name='checkmark' />,
          message: (
            <PopoverMessage>
              {t('files.uploadFile.popovers.uploadComplete', {
                fileName: fileName,
              })}
            </PopoverMessage>
          ),
        },
        true
      )
    }
  }, [uploadProgress, fileName, showPopover])

  const showErrorPopover = (errorMessage: string) => {
    showPopover({
      icon: <WarningIcon name='alertTriangle' />,
      message: (
        <PopoverMessageFullWidth>{errorMessage}</PopoverMessageFullWidth>
      ),
    })
  }
  const handleFileInputChange = useCallback(
    async (file: File | undefined) => {
      if (fileUploadIsLockedUntilPayment) {
        showErrorPopover(t('files.uploadFile.popovers.uploadsDisabled'))
        return
      }
      setFileName(undefined)
      setUploadProgress({
        progress: 0,
        speed: 0,
      })

      if (file) {
        setFileName(file.name)
        try {
          const fileResult = await fileController.uploadFileToSpace(
            file,
            setUploadProgress
          )
          if (fileResult?.id) {
            const updatedFileUploads = Object.assign({}, fileUploads)
            updatedFileUploads[fileResult.id] = {
              ...updatedFileUploads[fileResult.id],
              name: file.name,
            }
            setFileUploads(updatedFileUploads)
          }
        } catch (error) {
          showErrorPopover(
            t('files.uploadFile.popovers.errorUploading', {
              fileName: file.name,
            })
          )
        }
      }
    },
    [fileController, fileUploads, fileUploadIsLockedUntilPayment]
  )

  return {
    handleFileUpload: handleFileInputChange,
    setUploadProgress,
  }
}
