export const sheetIcon = (
  <svg
    width='1.25em'
    height='1.25em'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.5 8.33333H17.5M2.5 11.6667H17.5M10 8.33333V15M4.16667 15H15.8333C16.7538 15 17.5 14.2538 17.5 13.3333V6.66667C17.5 5.74619 16.7538 5 15.8333 5H4.16667C3.24619 5 2.5 5.74619 2.5 6.66667V13.3333C2.5 14.2538 3.24619 15 4.16667 15Z'
      stroke='currentColor'
      strokeWidth='1.5'
      opacity='0.8'
    />
  </svg>
)
