import {
  DefaultApi,
  Edge,
  EnumDetails,
  FieldConfig,
  Job,
  Plan,
  Sheet,
} from '@flatfile/api'
import { uniq } from 'lodash'
import { GroupBase } from 'react-select'
import { Primitive, arr } from '../../resources/common'
import { Observable } from '../observable'
import { SheetMap } from './JobController'
import { EnumRule, Rule } from './RuleController'
import { SheetController } from './SheetController'

export class PlanController {
  public src: SheetController
  public dest: SheetController
  public pendingRequests: number

  constructor(
    public plan: Plan,
    public readonly job: Job,
    public readonly sheets: SheetMap,
    private httpClient: DefaultApi,
    public updatePendingRequests: (n: number) => void
  ) {
    this.src = new SheetController(sheets.src, job)
    this.dest = new SheetController(sheets.dest, job)
    this.pendingRequests = 0
  }

  /**
   * Shortcut helper to get the source Sheet
   */
  get sourceSheet(): Sheet {
    return this.sheets.src
  }

  /**
   * Shortcut helper to get the destination sheet
   */
  get destSheet(): Sheet {
    return this.sheets.dest
  }

  /**
   * Return a lazy observable to be used in an action
   */
  public execute() {
    return new Observable(() =>
      this.httpClient.executeJob({
        jobId: this.job.id,
      })
    ).lazy
  }

  public getDestinationOptions(): GroupOrOption[] {
    const options = this.dest.getFieldOptions()
    const used = this.usedDestKeys

    if (!used.length) {
      return options
    }

    return [
      {
        label: 'Unused',
        options: options.filter((o) => !used.includes(o.value)),
      },
      {
        label: 'Used',
        options: options.filter((o) => used.includes(o.value)),
      },
    ]
  }

  /**
   * Update a rule and send to the server. The server will return a new plan
   */
  public updateRule() {
    const updater = new Observable<Plan, { rule: Rule }>(async ({ rule }) => {
      this.pendingRequests += 1
      this.updatePendingRequests(this.pendingRequests)
      const jobPlan = await this.httpClient.updateJobExecutionPlanFields({
        jobId: this.job.id,
        updateJobExecutionPlanFieldsRequest: rule.dest
          ? {
              fieldMapping: [this.ruleToEdge(rule)],
            }
          : { unmappedSourceFields: [this.ruleToEdge(rule)] },
      })

      return { data: jobPlan.data?.plan! }
    }).lazy

    updater.addEventListener('data', (e) => {
      this.plan = updater.ensured.data
      this.pendingRequests -= 1
      this.updatePendingRequests(this.pendingRequests)
    })

    updater.addEventListener('error', (e) => {
      this.pendingRequests -= 1
      this.updatePendingRequests(this.pendingRequests)
    })

    return updater
  }

  /**
   * This combines all available edges from the server response. The server
   * provides a complex response that needs to be optimized in the future. This
   * function converts everything the server provides into a simple list of
   * rules
   *
   * @todo we need to fix the server to provide a better response
   */
  public getRules(filter?: {
    enum?: boolean
    source?: string | string[]
  }): Rule[] {
    const edges = arr(this.plan.fieldMapping?.map((e) => this.edgeToRule(e)))

    const unused = arr(
      this.sourceSheet.config?.fields
        .filter((f) => !edges.find((e) => f.key === e.src))
        .filter((f) => !f.constraints?.some((c) => c.type === 'computed'))
        .map((e) => ({ src: e.key, preview: [] }))
    )

    let merged: Rule[] = [...unused, ...edges]

    if (filter?.enum) {
      merged = merged.filter(
        (r) => this.dest.getProperty(r.dest)?.type === 'enum'
      )
    }

    if (['self', 'exact'].includes(filter?.source as string)) {
      merged = merged.filter((r) =>
        arr(filter!.source).includes(r.metadata?.source as string)
      )
    }
    if (filter?.source === 'system') {
      merged = merged.filter(
        (r) => !['self', 'exact'].includes(r.metadata?.source as string)
      )
    }
    const sourceFieldsOrder =
      this.sourceSheet.config?.fields.map((item) => item.key) || []

    const mergedSortedAsSource = merged
      .slice()
      .sort(
        (a, b) =>
          sourceFieldsOrder.indexOf(a.src || '') -
          sourceFieldsOrder.indexOf(b.src || '')
      )

    return mergedSortedAsSource
  }

  /**
   * Convert from the full API structure to something more manageable
   *
   * @param sourceField
   * @param destinationField
   * @param enumDetails
   * @param metadata
   * @param preview
   */
  private edgeToRule({
    sourceField,
    destinationField,
    enumDetails,
    metadata,
    preview,
  }: Partial<Edge>): Rule {
    return {
      src: sourceField?.key,
      dest: destinationField?.key,
      metadata,
      preview: preview || [],
      enums: getEnums(destinationField, enumDetails),
    }
  }

  /**
   * Convert from the full API structure to something more manageable
   *
   * @param rule
   */
  private ruleToEdge({ src, dest, enums }: Rule): Edge {
    return {
      sourceField: this.src.getProperty(src)!,
      destinationField: this.dest.getProperty(dest)!,
      enumDetails: enums
        ? {
            mapping: enums.map((m) => ({
              sourceValue: m.src! as string,
              destinationValue: m.dest as string,
            })),
            unusedDestinationValues: enums
              .filter((e) => !e.src)
              .map((m) => m.dest! as string),
            unusedSourceValues: enums
              .filter((e) => !e.dest)
              .map((m) => m.src as string),
          }
        : undefined,
    }
  }

  /**
   * Get a list of "already mapped" destination fields as a list of keys
   */
  private get usedDestKeys(): string[] {
    return uniq(
      this.getRules()
        .map((r) => r.dest)
        .filter((v) => v) as string[]
    )
  }
}

/**
 * A basic helper function for sorting rules.
 *
 * @todo This only supports an alphabetic key sort right now and will be
 *   confusing on labels that are different like Postal Code > zip.
 *   Users will not be sure why "Postal Code" is at the end of the list
 *
 * @param side
 */
export function sortByKey(side: 'src' | 'dest' = 'src') {
  return (a: Rule, b: Rule) => (!a[side] ? 1 : a[side]! > b[side]! ? 1 : -1)
}

/**
 * This is a typing helper that matches what react-select needs.
 */
type GroupOrOption<
  T = {
    label: string | JSX.Element
    value: string
  }
> = GroupBase<T> | T

function getEnums(
  destinationField?: FieldConfig,
  enumDetails?: EnumDetails
): EnumRule[] {
  if (!destinationField || destinationField?.type !== 'enum') return []

  const mappedValues =
    enumDetails?.mapping?.map((m) => {
      const srcValue =
        m.sourceValue === undefined ? null : (m.sourceValue as Primitive)
      const destValue =
        m.destinationValue === undefined
          ? null
          : (m.destinationValue as Primitive)
      return {
        src: srcValue,
        dest: destValue,
      }
    }) || []

  const unusedValues =
    enumDetails?.unusedSourceValues?.map((m) => {
      const srcValue = m === undefined ? null : (m as Primitive)
      return {
        src: srcValue,
        dest: null,
      }
    }) || []

  const combinedValues = mappedValues.concat(unusedValues)
  const uniqueValues = combinedValues.filter(
    (v, i, a) => a.findIndex((v2) => v2.src === v.src) === i
  )

  return uniqueValues
}
