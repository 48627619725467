import { Property, Sheet, Workbook } from '@flatfile/api'
import { ColumnConfigProps, EColumnType } from '@flatfile/turntable'
import { useCallback, useContext, useMemo } from 'react'

import { SpaceContext } from '../../../contexts/SpaceContext'

interface UseFieldDataProps {
  sheet?: Sheet
  workbook: Workbook
}

const DEFAULT_FIELD_DATA = [{ value: undefined, options: undefined }]

export const useFieldData = ({ sheet, workbook }: UseFieldDataProps) => {
  const { httpClient } = useContext(SpaceContext)

  const getFieldOptions = useCallback(
    (field: Property) => {
      if (field.type !== 'reference' && field.type !== 'enum') {
        return undefined
      }

      if (field.type === 'enum') {
        return field.config?.options?.map(({ value, label, description }) => ({
          value,
          label,
          description,
          key: value,
        }))
      }

      const sheet = workbook.sheets?.find(
        (s) => s.config?.slug === field.config?.ref
      )
      const fieldKey = field.config?.key

      if (!sheet || !fieldKey) return undefined

      return async (
        searchValue?: string,
        pageNumber?: number,
        pageSize?: number
      ) => {
        const params = {
          sheetId: sheet.id,
          fieldKey,
          pageNumber,
          pageSize,
          distinct: true,
        }
        const options = await httpClient.getCellValues(
          searchValue ? { ...params, searchValue } : params
        )
        return (
          options?.data?.[fieldKey]?.map((item) => ({
            value: item.value ?? '',
            label: (item.value ?? '').toString(),
            key: item.value ?? '',
          })) || []
        )
      }
    },
    [workbook.sheets]
  )

  const columnConfig = useMemo(() => {
    const fields = sheet?.config?.fields
    if (!fields || !fields.length) {
      return DEFAULT_FIELD_DATA
    }
    return fields.reduce((memo: ColumnConfigProps[], field: Property) => {
      const fieldKey = field.key
      const fieldLabel = field.label ?? field.key
      const fieldType =
        field.type === 'reference' ? 'enum' : field.type ?? 'string'

      memo.push({
        value: fieldKey,
        label: fieldLabel,
        description: field.description,
        disabled: field.readonly,
        type: fieldType as EColumnType,
        required: !!field.constraints?.find((c) => c?.type === 'required'),
        options: getFieldOptions(field),
      })
      return memo
    }, [])
  }, [sheet])

  return { columnConfig }
}
