import styled from 'styled-components'
import { Row } from '../../../elements/Row'

export const FieldCardContainer = styled.div`
  border: 1px solid var(--color-border);
  background-color: var(--color-page);
  //box-shadow: rgba(0, 0, 0, 0.05) 3px 3px 20px;
  border-radius: 5px;
  margin-bottom: 1.5em;
`
export const CardHeader = styled.div`
  background: var(--color-page-100);
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  padding: 1em 1.25em;
`
export const Content = styled.div`
  padding: 1.25em;
`

export const CountPill = styled.span<{ invalid?: boolean }>`
  background: ${(props) =>
    props.invalid ? 'var(--color-danger-light)' : 'var(--color-skeleton)'};
  border-radius: 20px;
  margin-left: 10px;
  float: right;
  display: inline-block;
  padding: 1px 13px 0 12px;
  font-size: 80%;
  vertical-align: middle;
  text-transform: none;
  color: ${(props) => (props.invalid ? 'var(--color-danger)' : 'inherit')};
`

export const IconWrapper = styled.span`
  font-size: 20px;

  svg {
    vertical-align: middle;
    margin: -6px 3px -3px -7px;
  }
`

export const RuleHeader = styled(Row)`
  margin-bottom: 1.2em;
  font-size: var(--size-small);
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-text-light);
`

export const Code = styled.code`
  font-size: 85%;
  background-color: aliceblue;
  padding: 0 6px;
`
