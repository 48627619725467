import { Button } from '@flatfile/design-system'
import styled from 'styled-components'

interface ICommandMenuPanelFooter {
  onApply: () => void
  onCancel: () => void
}
export const CommandMenuPanelFooter = (props: ICommandMenuPanelFooter) => {
  const { onApply, onCancel } = props

  return (
    <FooterFlexWrapper>
      <Button
        onPress={onCancel}
        variant='tertiary-border'
        label='Cancel'
      ></Button>
      <Button onPress={onApply} variant='primary' label='Apply'></Button>
    </FooterFlexWrapper>
  )
}

const FooterFlexWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4px;
`
