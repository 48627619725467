import styled from 'styled-components'

export const SpacePageLayoutContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`

export const StyledSpan = styled.span`
  color: var(--color-primary);
`
