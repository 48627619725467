import styled from 'styled-components'

export const MatchField = styled.div`
  flex-basis: 40%;
  flex-grow: 1;

  strong {
    color: var(--color-text);
    font-size: var(--size-p);
    font-weight: 500;
  }
`
