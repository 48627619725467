import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SpaceContext } from '../../contexts/SpaceContext'
import { SpacesUISkeleton } from '../../packages/SpacesUISkeleton'
import { getRouteForSpace } from './Space/SpaceUtils'

export function SpaceRouteRedirector() {
  const navigate = useNavigate()
  const { documents, space, workbooks, metadata } = useContext(SpaceContext)
  const hasNonFileWorkbooks = workbooks.some(
    (wb) => !wb.labels?.includes('file')
  )

  useEffect(() => {
    const href = getRouteForSpace(
      space,
      documents,
      workbooks,
      metadata?.sidebarConfig?.defaultPage || {},
      hasNonFileWorkbooks
    )

    if (space && href) {
      navigate(`/space/${space.id}${href}`)
    }
  }, [documents, space, workbooks, metadata?.sidebarConfig?.defaultPage])

  return SpacesUISkeleton() // always loading until navigate() takes us away
}
