import { Action, JobTypeEnum, ModelFile, Workbook } from '@flatfile/api'
import {
  AMP_TOOLTIP_ID,
  Button,
  CheckboxInput,
  combineClassnames,
  List,
  MenuCell,
  Spinner,
} from '@flatfile/design-system'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { remove } from 'lodash'
import styled from 'styled-components'

import {
  DownloadFileProps,
  Files,
} from '../../../api/controllers/FileController'
import { FileInfo, FileJobs, FileMode } from '../types'
import {
  ActiveJobs,
  getCurrentlyRunningJobs,
} from '../../../contexts/JobsContext'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { formatBytes } from '../../../utils/formatBytes'
import { formatTime } from '../../../utils/formatTime'
import { ActionLink } from '../../../elements/ActionLink'

const FileNameData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const FileStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 32px;
`

export const getFileUpload = (
  file: ModelFile,
  activeJobs: FileJobs | undefined
) => {
  return activeJobs?.find(
    (j) => j.source === file.id && j.type === JobTypeEnum.File
  )
}

export const getFileStatus = (
  file: ModelFile,
  activeJobs: FileJobs | undefined
) => {
  const fileUpload = getFileUpload(file, activeJobs)
  const fileInfo = fileUpload?.info ?? ''

  if (fileUpload?.progress !== 100 && fileUpload?.status === 'executing') {
    const progress = fileUpload?.progress ? fileUpload?.progress + '% ' : ''
    return (
      <>
        {progress + fileInfo}
        <Spinner size={16} />
      </>
    )
  }

  return fileInfo
}

export const getFileNameField = (
  fileReady: boolean,
  file: ModelFile,
  fileFailed: boolean
) => {
  if (fileReady && file.workbookId && !fileFailed) {
    return (
      <ActionLink to={`../workbook/${file.workbookId}`}>{file.name}</ActionLink>
    )
  } else {
    return file.name
  }
}

export const getSheetLink = (file: ModelFile, workbooks: Workbook[]) => {
  const sheet = workbooks
    .flatMap((wb) => wb.sheets)
    .find((sheet) => sheet?.id === file.sheetId)

  if (file.sheetId && sheet?.workbookId) {
    return (
      <ActionLink to={`../workbook/${sheet.workbookId}/sheet/${file.sheetId}`}>
        {sheet.name}
      </ActionLink>
    )
  }

  return <></>
}

export const getTooltipMessage = (
  isUpload: boolean,
  file: ModelFile,
  fileReady: boolean,
  hasNonFileWorkbooks: boolean
) => {
  if (!isUpload) {
    return
  }
  if (!file.name.includes('.')) {
    return 'files.uploadsTables.tooltips.noExtension'
  }
  if (!(fileReady && file.workbookId && hasNonFileWorkbooks)) {
    return 'files.uploadsTables.tooltips.cannotImport'
  }
}

export const FilesTable = ({
  fileMode,
  filesResponse,
  activeJobs,
  setFilesToDelete,
  onClickDownload,
  onClickCustomAction,
  selectedFiles,
  setSelectedFiles,
}: {
  fileMode: FileMode
  filesResponse: Files
  activeJobs: ActiveJobs
  selectedFiles: FileInfo[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileInfo[]>>
  setFilesToDelete: React.Dispatch<React.SetStateAction<FileInfo[]>>
  onClickDownload: (props?: DownloadFileProps[] | undefined) => void
  onClickCustomAction: (operation: string, fileId: string) => () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isUpload = fileMode === 'import'
  const { workbooks } = useContext(SpaceContext)
  const hasNonFileWorkbooks = workbooks.some(
    (wb) => !wb.labels?.includes('file')
  )

  const handleFileSelection = useCallback(
    (file: FileInfo) => {
      if (isFileSelected(file.fileId)) {
        const newSelectedFiles = Array.from(selectedFiles)
        remove(newSelectedFiles, file)
        setSelectedFiles(newSelectedFiles)
      } else {
        setSelectedFiles([...selectedFiles, file])
      }
    },
    [selectedFiles, setSelectedFiles]
  )

  const isFileReady = useCallback(
    (file: ModelFile) => {
      if (file.size === null) {
        return false
      }
      return !getCurrentlyRunningJobs(activeJobs)?.find(
        (job) => job.source === file.id
      )
    },
    [activeJobs]
  )

  const isFileSelected = useCallback(
    (fileId: string) => selectedFiles.map((f) => f.fileId).includes(fileId),
    [selectedFiles]
  )

  const goToImport = useCallback(
    (fileId: string) => navigate(`./${fileId}/import`),
    [filesResponse]
  )

  const getFileAction = (
    file: ModelFile,
    fileReady: boolean,
    fileStatusFailed: boolean
  ) => {
    const showImportBtn =
      fileReady && file.workbookId && hasNonFileWorkbooks && !fileStatusFailed
    if (!isUpload) {
      return fileReady
        ? formatBytes(file.size)
        : t('files.downloadsTable.processing')
    }
    if (showImportBtn) {
      return (
        <Button
          variant='tertiary-border'
          onPress={() => goToImport(file.id)}
          size='sm'
          label={t('files.uploadsTables.importButton')}
        />
      )
    }
    return null
  }

  const getFileMenu = (fileParams: FileInfo, fileReady: boolean, file: any) => {
    if (!fileReady) {
      return null
    }
    return (
      <MenuCell
        options={[
          {
            label: t('files.contextMenu.delete'),
            key: 'delete',
            onAction: () => setFilesToDelete([fileParams]),
          },
          {
            label: t('files.contextMenu.download'),
            key: 'download',
            onAction: () => onClickDownload([fileParams]),
          },
        ].concat(
          file.actions?.map((a: Action) => ({
            label: a.label,
            key: a.operation!,
            onAction: onClickCustomAction(a.operation!, fileParams.fileId),
          })) || []
        )}
      />
    )
  }

  const headers = useMemo(
    () => [
      { key: 'checkBox', content: '', width: '42px' },
      {
        key: 'name',
        content: isUpload
          ? t('files.uploadsTables.headings.name')
          : t('files.downloadsTable.headings.name'),
        width: '30%',
      },
      {
        key: 'time',
        content: isUpload
          ? t('files.uploadsTables.headings.uploadedDate')
          : t('files.downloadsTable.headings.createdDate'),
      },
      {
        key: 'info',
        content: isUpload
          ? t('files.uploadsTables.headings.status')
          : t('files.downloadsTable.headings.sourceSheet'),
        width: '25%',
      },
      {
        key: 'action',
        content: isUpload ? '' : t('files.downloadsTable.headings.size'),
      },
      { key: 'menu', content: '', width: '32px' },
    ],
    [isUpload]
  )

  const rows = filesResponse.files.map((file, i) => {
    const fileReady = isFileReady(file)
    const fileStatusFailed = file.status === 'failed' // isFileReady is used in too many places to throw this file status check inside of it
    const fileSelected = isFileSelected(file.id)
    const fileParams = { fileId: file.id, fileName: file.name }
    const data = {
      checkBox: (
        <CheckboxInput
          color='var(--color-primary)'
          checked={fileSelected}
          onChange={() => handleFileSelection(fileParams)}
        />
      ),
      name: (
        <FileNameData>
          {getFileNameField(fileReady, file, fileStatusFailed)}
        </FileNameData>
      ),
      time: formatTime(file.createdAt!),
      info: isUpload ? (
        <FileStatus>{getFileStatus(file, activeJobs)}</FileStatus>
      ) : (
        getSheetLink(file, workbooks)
      ),
      action: getFileAction(file, fileReady, fileStatusFailed),
      menu: getFileMenu(fileParams, fileReady, file),
    }
    const tooltip = getTooltipMessage(
      isUpload,
      file,
      fileReady,
      hasNonFileWorkbooks
    )

    return {
      data,
      hoverable: true,
      testId: 'file-row',
      className: combineClassnames({
        disabled: !fileReady,
        selected: fileSelected,
      }),
      'data-tooltip-id': AMP_TOOLTIP_ID,
      'data-tooltip-float': true,
      'data-tooltip-content': tooltip && t(tooltip),
    }
  })

  return <List headers={headers} data={rows} />
}
