import { useEffect, useState } from 'react'
import { isImagePathValid } from '../../default-apps/SpaceApp/Theme/ThemeUtils'
import { SidebarLogoContainer } from './elements'

export const SidebarLogo = ({ logo }: { logo?: string }) => {
  const [logoSrc, setLogoSrc] = useState<string | undefined>(undefined)

  useEffect(() => {
    isImagePathValid(logo).then((isValid) => {
      if (isValid) setLogoSrc(logo)
    })
  }, [logo])

  if (!logoSrc) return null

  return (
    <SidebarLogoContainer data-testid='custom-logo-container'>
      <img src={logoSrc} data-testid='custom-logo' />
    </SidebarLogoContainer>
  )
}
