import { Workbook } from '@flatfile/api'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResource } from '../../api/resources'
import { InjectResource } from '../../api/resources/InjectResource'
import { ErrorState } from '../../elements/EmptyState'
import { SpacesUISkeleton } from '../../packages/SpacesUISkeleton'
import { useRouteParams } from '../../hooks/useRouteParams'

export const WorkbookRouteRedirector: FC<{}> = () => {
  const { workbookId } = useRouteParams()
  const navigate = useNavigate()
  const [workbook] = useResource<Workbook>(workbookId, false)
  useEffect(() => {
    if (workbook && workbook.sheets?.length) {
      navigate(`./sheet/${workbook.sheets[0].id}`, { replace: true })
    }
  }, [workbook])
  return (
    <InjectResource<Workbook>
      id={workbookId}
      errorContent={() => {
        return <ErrorState title='Workbook not found' />
      }}
    >
      {(workbook) => {
        if (!workbook.sheets?.length) {
          return (
            <ErrorState
              title='Workbook contains no sheets'
              message='A workbook must contain at least one sheet. None were found.'
            />
          )
        }
        return SpacesUISkeleton()
      }}
    </InjectResource>
  )
}
