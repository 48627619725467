import { Banner, ProgressBar, Typography } from '@flatfile/design-system'
import { useEffect, useState } from 'react'
import Markdown from 'react-remarkable'
import { useSearchParams } from 'react-router-dom'
import { useAssistant } from '../../../hooks/useAssistant'
import { CommandMenuPanelFooter } from '../CommandMenuPanelFooter/CommandMenuPanelFooter'
import { parseCommand } from '../utils'
import { EllipsisMessage } from './EllipsisMessage'
import { Code, Explain, FFQL } from './styles'

export const CommandMenuAiQueryPanel = ({
  value,
  onValueChange,
  dismissHandler,
  setInputDisabled,
  setInputFocus,
  keyboardSubmitActivated,
  deactivateKeyboardSubmit,
}: {
  value: string
  onValueChange: (value: string) => void
  dismissHandler: () => void
  setInputDisabled: (disabled: boolean) => void
  setInputFocus: () => void
  keyboardSubmitActivated: boolean
  deactivateKeyboardSubmit: () => void
}) => {
  enum PanelState {
    ReadyToAsk,
    Asking,
    ReadyToApply,
    Applying,
    DoneApplying,
  }

  const [panelState, setPanelState] = useState(PanelState.ReadyToAsk)

  const [searchParams, setSearchParams] = useSearchParams()

  const { generateQuery, isError, queryResponse, queryIsSuccess } =
    useAssistant()

  const queryData = queryResponse?.data
  const query = queryData?.query

  const queryMetadata = queryData?.metadata
  const explainQuery = queryMetadata?.explain

  const messages = ['Thinking', 'Writing', 'Doing', 'Finishing']
  const instruction =
    "Describe how you'd like the AI assistant to query your data:"

  useEffect(() => {
    if (keyboardSubmitActivated && panelState === PanelState.ReadyToAsk) {
      askForQuery()
      deactivateKeyboardSubmit()
    } else if (
      keyboardSubmitActivated &&
      panelState === PanelState.ReadyToApply
    ) {
      applyQuery()
      deactivateKeyboardSubmit()
    }
  }, [keyboardSubmitActivated, panelState])

  // Advance the panel state when an operation succeeds
  useEffect(() => {
    if (panelState === PanelState.Asking && queryIsSuccess) {
      afterSuccessfulAskForQuery()
    }
  }, [panelState, queryIsSuccess])

  // If the user changes the value, reset the panel state
  useEffect(() => {
    setPanelState(PanelState.ReadyToAsk)
  }, [value])

  // When there's an error, re-enable the input
  useEffect(() => {
    if (isError) {
      setInputDisabled(false)
    }
  }, [isError])

  const askForQuery = () => {
    /* c8 ignore next */
    if (!value) return

    setPanelState(PanelState.Asking)
    setInputDisabled(true)

    const { command, prompt } = parseCommand(value)
    generateQuery(prompt)
  }

  const afterSuccessfulAskForQuery = () => {
    setPanelState(PanelState.ReadyToApply)
    setInputDisabled(false)
    setInputFocus()
  }

  // What happens when we tell the assistant to apply the mutation?
  const applyQuery = async () => {
    if (query) {
      // Close the command menu
      dismissHandler()

      setPanelState(PanelState.ReadyToAsk)

      // Get rid of existing search fields
      searchParams.delete('searchValue')
      searchParams.delete('searchField')
      searchParams.delete('q')

      // And set the ffql query
      searchParams.set('q', query)
      setSearchParams(searchParams)

      // Wipe out the value
      onValueChange('')
    }
  }

  return (
    <>
      {panelState === PanelState.ReadyToAsk && (
        <>
          <Typography type='actionMd' color='var(--color-primary)'>
            {instruction}
          </Typography>
          <CommandMenuPanelFooter
            onApply={askForQuery}
            onCancel={dismissHandler}
          />
        </>
      )}

      {isError && panelState === PanelState.Asking && (
        <Banner
          title='Something went wrong. Please try again.'
          variant='error'
        />
      )}

      {panelState === PanelState.Asking && !isError && (
        <>
          <Typography type='actionMd' color='var(--color-primary)'>
            <EllipsisMessage messages={messages} interval={7000} />
          </Typography>
          <ProgressBar />
        </>
      )}

      {panelState === PanelState.ReadyToApply && (
        <>
          <Explain type='actionMd' color='var(--color-text-ultralight)'>
            Here's what I came up with. Read the step by step explanation and
            the query, and if it looks good, click below to apply it.
          </Explain>
          <Typography type='actionMd' color='var(--color-primary)'>
            Query
          </Typography>
          <FFQL>{query}</FFQL>
          <Typography type='actionMd' color='var(--color-primary)'>
            Explanation
          </Typography>
          <Code>
            {explainQuery && (
              <Markdown source={explainQuery} options={{ html: false }} />
            )}
          </Code>
          <CommandMenuPanelFooter
            onApply={applyQuery}
            onCancel={dismissHandler}
          />
        </>
      )}
    </>
  )
}
