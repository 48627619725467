import {
  CellValue,
  Filter,
  Record,
  RecordCounts,
  RecordData,
  Records,
  SortDirection,
  ValidationMessage,
  ValidationMessageTypeEnum,
} from '@flatfile/api'
import {
  CellChangeDetail,
  CellData,
  ColumnConfigProps,
  RowChangeDetail,
  RowData,
} from '@flatfile/turntable'
import { RecordDataPayload } from '../hooks/useRecordMutation'
import { SearchFields } from '../hooks/useSearchFilters'
import { ToolbarRecordCounts } from '../hooks/useSheetViewData'

const SortDirectionEnum = {
  ascending: SortDirection.Asc,
  descending: SortDirection.Desc,
}

export const getCurrentRowCount = (
  searchFields: SearchFields,
  counts?: ToolbarRecordCounts
): number => {
  if (!counts) {
    return 0
  }
  if (searchFields.filterField || searchFields.searchValue || searchFields.q) {
    return counts.filtered ?? 0
  }
  return getFilteredCount(searchFields, counts)
}

const getFilteredMessages = (
  value: CellValue,
  filter: ValidationMessageTypeEnum
): string[] | undefined => {
  const messages: string[] =
    value?.messages?.reduce(
      (memo: string[], { message, type }: ValidationMessage) => {
        if (type === filter && message) {
          memo.push(message)
        }
        return memo
      },
      []
    ) ?? []
  return messages.length ? messages : undefined
}

/*
  Takes cell data from the API and formats it as a Turntable cell collection
*/
export const formatTurntableCells = (
  values: RecordData,
  columnConfig?: ColumnConfigProps[]
): CellData[] => {
  return (
    columnConfig?.map((column, index) => {
      const cellData = values?.[column.value]
      return {
        value: cellData.value,
        column: index,
        mutated: cellData?.messages?.some((m) => m.source === 'is-artifact'),
        status: {
          error: getFilteredMessages(cellData, ValidationMessageTypeEnum.Error),
          info: getFilteredMessages(cellData, ValidationMessageTypeEnum.Info),
          warning: getFilteredMessages(
            cellData,
            ValidationMessageTypeEnum.Warn
          ),
        },
      }
    }) ?? []
  )
}

/*
  Takes record data from the API and formats it as a Turntable row collection
*/
export const formatTurntableRows = (
  records: Records,
  skip: number,
  columnConfig?: ColumnConfigProps[]
): RowData[] | undefined => {
  return records?.map((record: Record, index: number) => ({
    id: record.id,
    cells: formatTurntableCells(record.values, columnConfig),
    position: index + skip,
    rowIndex: index + skip + 1,
  }))
}

/*
  Takes a Turntable patch object and converts it into a payload for API requests
*/
export const recordData = (
  change: RowChangeDetail,
  columnConfig: ColumnConfigProps[]
): RecordDataPayload => {
  return change.patch.reduce(
    (memo: RecordDataPayload, change: CellChangeDetail<unknown>) => {
      // change.column is the index in the columnConfig array
      const key = columnConfig[change.column].value
      memo[key] = { value: change.value }
      return memo
    },
    {}
  )
}

export const getSortParams = ({
  columnConfig,
  columnIndex,
  order,
}: {
  columnConfig: ColumnConfigProps[]
  columnIndex: number | undefined
  order: 'ascending' | 'descending' | 'noOrder'
}): {
  sortField: string | undefined
  sortDirection: string | undefined
} => {
  const field = columnConfig[columnIndex ?? -1]?.value
  if (!field || order === 'noOrder') {
    return { sortDirection: undefined, sortField: undefined }
  }
  return { sortField: field, sortDirection: SortDirectionEnum[order] }
}

export function getFilteredCount(
  searchFields: SearchFields,
  counts: RecordCounts
): number {
  let filtered
  switch (searchFields.filter) {
    case Filter.Valid:
      filtered = counts.valid
      break
    case Filter.Error:
      filtered = counts.error
      break
    case Filter.All:
    case Filter.None:
    case undefined:
      filtered = counts.total
      break
    default:
      assertUnreachable(searchFields.filter)
  }
  return filtered ?? 0
}

export function getToolbarCounts(
  searchFields: SearchFields,
  counts: RecordCounts,
  filteredCounts: RecordCounts
): ToolbarRecordCounts {
  const filtered = getFilteredCount(searchFields, filteredCounts)
  return { ...counts, filtered }
}

export function assertUnreachable(_x: never): never {
  throw new Error('Should never reach this code')
}
