import {
  CreateJobRequest,
  GetRecordsRequest,
  JobTriggerEnum,
  JobTypeEnum,
} from '@flatfile/api'
import { ColumnConfigProps, EColumnType } from '@flatfile/turntable'
import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'
import { JobOperationEnum } from '../../../contexts/JobsContext'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { BulkRowJobConfig } from './useBulkRowActions'
import { SearchFields } from './useSearchFilters'
import { useTranslation } from 'react-i18next'

type FilterType = Omit<SearchFields, 'sortDirection' | 'sortField'>

const useFindAndReplace = ({
  sheetId,
  refetchRows,
  columnConfig,
  searchFields,
  getJobConfig,
  isFiltered,
}: {
  sheetId: string
  refetchRows: () => Promise<void>
  columnConfig: ColumnConfigProps[]
  searchFields: SearchFields
  getJobConfig: () => BulkRowJobConfig
  isFiltered: boolean
}) => {
  const { httpClient } = useContext(SpaceContext)
  const { t } = useTranslation()
  const { mutateAsync: findCellCounts } = useMutation(
    ['findCellCounts'],
    async (payload: GetRecordsRequest) => await httpClient.getCounts(payload),
    { useErrorBoundary: false }
  )
  const { mutateAsync: replaceCellValues } = useMutation(
    ['replaceCellValues'],
    async (payload: CreateJobRequest) => await httpClient.createJob(payload)
  )

  const getColumnType = (fieldKey: string) =>
    columnConfig.find((column) => column.value === fieldKey)?.type

  /*
    Our backend uses 'null' to distinguish empty values
  */
  const prepareBackendValue = (
    searchValue: string,
    exactMatchesOnly: boolean,
    columnType?: EColumnType
  ) => {
    if (columnType === 'enum' && searchValue)
      return searchValue === 'null' ? null : `"${searchValue}"`
    if (columnType === 'string' && searchValue && !exactMatchesOnly)
      return searchValue
    return searchValue === 'null' || !searchValue ? null : `"${searchValue}"`
  }

  const getFindAndReplaceProps = async ({
    searchValue,
    fieldId,
    exactMatchesOnly,
    ignoreFilter,
  }: {
    searchValue: string
    fieldId: string
    exactMatchesOnly: boolean
    ignoreFilter?: boolean
  }) => {
    const columnType = getColumnType(fieldId)
    const {
      workbookId: unusedWorkbookId,
      sheetId: unusedSheetId,
      // ids are not passed to the counts endpoint yet, but should be
      // https://github.com/FlatFilers/X/issues/3019
      ids: unusedIds,
      ...config
    } = getJobConfig()

    // ignoreFilter comes from checkbox in find and replace modal labeled 'Run on all records' when a filter is applied
    if (ignoreFilter) {
      delete config.filter
      delete config.filterField
      delete config.searchValue
      delete config.searchField
    }

    if (config.searchField || config.searchValue || config.q) {
      /*
      Counts are incorrect when a search filter is applied, so we return nothing
      to prevent as much confusion as possible until this can be fixed.
      https://github.com/FlatFilers/X/issues/2008
      */
      return { count: '', error: undefined }
    }

    const preparedSearchValue = prepareBackendValue(
      searchValue,
      exactMatchesOnly,
      columnType
    )

    try {
      const { data: countData } = await findCellCounts({
        ...config,
        sheetId: sheetId!,
        searchField: fieldId,
        searchValue:
          // ignored because this counts query will be changing soon. see comment above
          /* c8 ignore next */
          preparedSearchValue === null ? 'null' : preparedSearchValue,
      })
      return {
        count: countData?.counts?.total || 0,
        error: undefined,
      }
    } catch (err) {
      return {
        count: 0,
        error: t(
          'sheet.table.contextMenu.findAndReplace.modal.errors.findError'
        ),
      }
    }
  }

  const handleFindAndReplace = async ({
    replaceValue,
    searchValue,
    fieldId,
    exactMatchesOnly,
    ignoreFilter,
  }: {
    replaceValue: string
    searchValue: string
    fieldId: string
    exactMatchesOnly: boolean
    ignoreFilter?: boolean
  }) => {
    const columnType = getColumnType(fieldId)
    const { workbookId, sheetId: unusedSheetId, ...config } = getJobConfig()
    // ignoreFilter comes from checkbox in find and replace modal labeled 'Run on all records' when a filter is applied
    if (ignoreFilter) {
      delete config.filter
      delete config.filterField
      delete config.searchValue
      delete config.searchField
      delete config.ids
    }
    try {
      await replaceCellValues({
        jobConfig: {
          type: JobTypeEnum.Sheet,
          operation: JobOperationEnum.FindReplace,
          trigger: JobTriggerEnum.Immediate,
          source: sheetId!,
          mode: 'foreground',
          config: {
            ...config,
            fieldKey: fieldId,
            replace: replaceValue,
            find: prepareBackendValue(
              searchValue,
              exactMatchesOnly,
              columnType
              // I wish I didn't have to do this but something is funky in openapi generated types
              // the type is nullable, but typescript is complaining that type string | null can't
              // be assigned to the openapi generated type for this property. I'm hoping switching
              // to fern generated types will allow us to remove this
            ) as string,
          },
        },
      })
      return refetchRows()
    } catch (error) {
      return {
        error: t(
          'sheet.table.contextMenu.findAndReplace.modal.errors.replaceError'
        ),
      }
    }
  }

  return { getFindAndReplaceProps, handleFindAndReplace }
}

export default useFindAndReplace
