import {
  Action,
  EventDomainEnum,
  EventTopic,
  JobSubjectType,
  JobTypeEnum,
  Sheet,
  Workbook,
} from '@flatfile/api'
import { ActionStatus, useBulkRowActionsContext } from '@flatfile/turntable'
import { useContext } from 'react'
import { JobController } from '../../../api/controllers/JobController'
import { EnvironmentsContext } from '../../../contexts/EnvironmentsContext'
import { BulkRowActionItem, BulkRowJobConfig } from './useBulkRowActions'
import { useRecordMutation } from './useRecordMutation'
import { useTranslation } from 'react-i18next'

export interface UseCustomActionsProps {
  spaceId: string
  environmentId?: string
  workbook?: Workbook
  sheet?: Sheet
  getJobConfig: () => BulkRowJobConfig
  rowsSelectedCount: {
    download: number
    delete: number
    copy: number
  }
}

export const useCustomActions = ({
  environmentId,
  workbook,
  sheet,
  spaceId,
  getJobConfig,
  rowsSelectedCount,
}: UseCustomActionsProps) => {
  const { accountId } = useContext(EnvironmentsContext)
  const { setActionStatus } = useBulkRowActionsContext()
  const { t } = useTranslation()

  const { createEvent } = useRecordMutation(sheet?.id ?? '')
  const sheetSlug = sheet?.config?.slug ?? ''
  const sheetKey = sheetSlug.includes('/') ? sheetSlug.split('/')[1] : sheetSlug

  const eventPayload = (actionName: string) => {
    const { workbookId, sheetId, ...config } = getJobConfig()
    const payload = {
      eventConfig: {
        domain: EventDomainEnum.Workbook,
        topic: 'action:triggered' as EventTopic,
        context: {
          accountId: accountId ?? '',
          spaceId,
          actionName: `${sheetKey}:${actionName}`,
          sheetId,
          environmentId: environmentId ?? '',
          workbookId,
          sheetSlug,
          slugs: {
            sheet: sheetSlug,
          },
        },
        payload: {
          actionName: `${sheetKey}:${actionName}`,
          ...config,
        },
      },
    }
    return payload
  }
  const handleAction = async (
    domain: JobTypeEnum,
    resource: Sheet | Workbook,
    action: Action
  ) => {
    setActionStatus(ActionStatus.inProgress)
    try {
      // Todo: move this to the backend as a legacy event
      if (action.slug) {
        await createEvent(eventPayload(action.slug))
      } else {
        //when there is no slug there must be an operation
        const operation = action.operation!
        const { workbookId, sheetId, ...config } = getJobConfig()
        // TODO pass this through to job config once it's generic
        // Leaving the above comment because I don't think it's generic yet
        // but need to pass filters to generate data_url for custom actions

        const isSheet = (item: Sheet | Workbook): item is Sheet =>
          (item as Sheet).workbookId !== undefined

        const subject = isSheet(resource)
          ? {
              type: JobSubjectType.Collection,
              resource: 'records',
              query: config,
              params: {
                sheetId: resource.id,
              },
            }
          : {
              type: JobSubjectType.Resource,
              id: resource.id,
            }

        await JobController.create({
          type: domain,
          fromAction: action,
          source: resource.id,
          operation: operation,
          mode: action.mode,
          subject,
        })
      }

      setActionStatus(ActionStatus.success)
      return { success: true }
    } catch (error) {
      setActionStatus(ActionStatus.failed)
      return { success: false }
    }
  }

  const getCustomTranslation = (key?: string) => {
    if (!key) return ''
    const translation = t(key)
    if (translation === key) return key
    return translation
  }

  const customActions: BulkRowActionItem[] =
    sheet?.config?.actions?.map((action) => {
      return {
        key: getCustomTranslation(action.label),
        label: getCustomTranslation(action.label),
        //counting records the same way as the delete action: no checkmarks set means 0 rows selected instead of all
        disabled: action.requireSelection
          ? rowsSelectedCount.delete === 0
          : false,
        primary: !!action.primary,
        action: () => handleAction('sheet', sheet, action),
        tooltip: {
          disabled: t('sheet.toolbar.customActions.disabledTooltip'),
        },
        //icon: (action as any).icon || 'ThreeRectangles',
        modalText: {
          heading: getCustomTranslation(action.label),
          description: getCustomTranslation(action.description),
          confirmButtonText: t(
            'sheet.toolbar.customActions.modal.confirmButton'
          ),
          loadingText: t('sheet.toolbar.customActions.modal.loadingText'),
          errorMessage: t('sheet.toolbar.customActions.modal.errorMessage'),
        },
      }
    }) ?? []

  const workbookActions =
    workbook?.actions?.map((action) => {
      return {
        key: action.operation || action.slug!,
        label: getCustomTranslation(action.label),
        primary: !!action.primary,
        action: () => handleAction('workbook', workbook, action),
        confirm: action.confirm,
        modalText: {
          heading: getCustomTranslation(action.label),
          description: getCustomTranslation(action.description),
          confirmButtonText: t(
            'sheet.toolbar.customActions.modal.confirmButton'
          ),
          loadingText: t('sheet.toolbar.customActions.modal.loadingText'),
          errorMessage: t('sheet.toolbar.customActions.modal.errorMessage'),
        },
      }
    }) ?? []

  return {
    customActions,
    workbookActions,
    handleAction,
    eventPayload,
  }
}
