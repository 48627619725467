interface CommandPrompt {
  command: string
  prompt: string
}

export function parseCommand(value: string): CommandPrompt {
  // Turns e.g. "/query what is  my name" into { command: "/query", prompt: "what is  my name"}
  const [command, ...prompt] = value.split(' ')
  return { command, prompt: prompt.join(' ') }
}
