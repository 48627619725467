import { IconButton, Pill, PillContainer } from '@flatfile/design-system'
import styled from 'styled-components'
import { ISpaceUser } from '../../../hooks/useGuestManagement'

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .disabled-user-row {
    opacity: 0.5;
  }
`

export const PendingUserCellWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;

  a {
    color: var(--color-action);
    text-decoration: underline;

    ${({ disabled }) => !disabled && 'cursor: pointer;'}
  }
`
const WorkbookCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    color: var(--color-action);
  }
`

export const PendingUserCell = ({
  disabled,
  onClickResend,
}: {
  disabled: boolean
  onClickResend?: () => void
}) => {
  return (
    <PendingUserCellWrapper disabled={disabled}>
      <a data-testid='pending-user-cell-link' onClick={onClickResend}>
        Resend invite email
      </a>
    </PendingUserCellWrapper>
  )
}

export const WorkbookAccessCell = ({
  user,
  onClickEdit,
  workbooksCount,
}: {
  user: ISpaceUser
  onClickEdit?: () => void
  workbooksCount?: number
}) => {
  const workbooks = user.workbooks ?? []
  const pillCount = 2
  const userWorkbooksCount = workbooks.length
  const spaceWorkbooksCount = workbooksCount ?? 0
  /*
    We show two pills with a workbook name, and if there are more than two
    workbooks, show the remainder as a pill that says "+ $remaining",
    hence userWorkbooksCount - 2.
  */
  const remainingWorkbooksCount = userWorkbooksCount - pillCount
  const wholeSpaceAccess =
    !userWorkbooksCount || userWorkbooksCount === spaceWorkbooksCount

  return (
    <WorkbookCellWrapper>
      {wholeSpaceAccess ? (
        'Whole Space'
      ) : (
        <PillContainer>
          {workbooks[0] && <Pill>{workbooks[0].name}</Pill>}
          {workbooks[1] && <Pill>{workbooks[1].name}</Pill>}
          {remainingWorkbooksCount > 0 ? (
            <Pill>+ {remainingWorkbooksCount}</Pill>
          ) : null}
        </PillContainer>
      )}
      {onClickEdit && (
        <IconButton name='edit' size={16} onPress={onClickEdit} />
      )}
    </WorkbookCellWrapper>
  )
}
