import { getFont } from '@flatfile/design-system'
import { Command } from 'cmdk'
import styled from 'styled-components'

// CMDK Components
export const CommandMenuRoot = styled(Command.Dialog)`
  max-width: 640px;
  width: 100%;
  background: var(--color-page);
  border-radius: 2px;
  padding: 8px 0;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-border);
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
`

export const CommandMenuList = styled(Command.List)`
  padding: 8px 16px;
`

export const CommandMenuInput = styled(Command.Input)`
  ${getFont('b1')}
  border: none;
  width: 100%;
  padding: 8px 8px;
  outline: none;

  &::placeholder {
    color: var(--color-text-ultralight);
  }
`

export const CommandMenuGroup = styled(Command.Group)<{ $colorVar: string }>`
  padding: 4px 0;

  > [cmdk-group-heading] {
    ${getFont('b1')}
    padding: 0 16px;
    font-weight: 600;
    margin: 4px 0;
    color: ${(props) =>
      props.$colorVar ? props.$colorVar : 'var(--text-color)'};
  }
`

// Custom Components
export const CommandMenuInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px 8px;
  border-bottom: 1px solid var(--color-page-200);
`
