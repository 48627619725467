import { Button, Spinner, ZIndex } from '@flatfile/design-system'
import { useContext } from 'react'
import styled from 'styled-components'

import { SpaceContext } from '../../contexts/SpaceContext'
import { ErrorState } from '../../elements/EmptyState'
import { FileUploadButton } from '../FilesApp/components/FileUploadButton'
import { SpinnerContainer } from '../../elements/SpinnerContainer'
import { noActivityIcon } from '../../resources/icons/no-activity'
import GenericFile from '../../resources/images/generic.svg'

const Overlay = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndex.level800};
`

const Content = styled.div`
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  button {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`

const ImageContainer = styled.div`
  display: flex;
  gap: 28px;
  justify-content: center;
  height: 40px;
`

const PreparingWorkbookMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PreparingWorkbookState = ({
  percentComplete,
}: {
  percentComplete: number
}) => {
  if (percentComplete === -1) {
    return null
  }
  return (
    <PreparingWorkbookMessage data-testid='preparing-workbook-state'>
      <h1>{percentComplete}%</h1>
      <span>Preparing workbook</span>
    </PreparingWorkbookMessage>
  )
}

export const SheetEmptyStateOverlay = ({
  hideOverlay,
  canEditSheet,
  canAddToSheet,
  canDeleteFromSheet,
  canImportIntoSheet,
  noAccessLimitations,
}: {
  hideOverlay: () => void
  canEditSheet?: boolean
  canAddToSheet?: boolean
  canDeleteFromSheet?: boolean
  canImportIntoSheet?: boolean
  noAccessLimitations?: boolean
}) => {
  const { space } = useContext(SpaceContext)
  const allowManualEdit = canAddToSheet === true || noAccessLimitations
  if (!allowManualEdit && !(canImportIntoSheet || noAccessLimitations)) {
    return null
  }
  return (
    <Overlay>
      <h3>Let's get started</h3>
      <Content>
        {(canImportIntoSheet || noAccessLimitations) && (
          <>
            <span>Drag and drop or upload a file to get started</span>
            <ImageContainer>
              <img alt='genericfile' src={GenericFile} />
            </ImageContainer>
            <FileUploadButton space={space} size='md' />
          </>
        )}
        {allowManualEdit && (
          <Button
            variant='tertiary-border'
            iconLeft='edit'
            onPress={hideOverlay}
            data-testid='manual-edit-button'
            label='Manually enter data'
          />
        )}
      </Content>
    </Overlay>
  )
}

export const SheetEmptyState = ({
  hasError,
  hasSheet,
  isFirstLoad,
  readOnlySheet,
  canAddToSheet,
  canImportIntoSheet,
  sheetIsLockedUntilPayment,
}: {
  hasError?: boolean
  hasSheet?: boolean
  isFirstLoad?: boolean
  readOnlySheet?: boolean
  canAddToSheet?: boolean
  canImportIntoSheet?: boolean
  sheetIsLockedUntilPayment?: boolean
}) => {
  if (hasError && hasSheet) {
    return <ErrorState />
  }

  if (isFirstLoad) {
    return (
      <SpinnerContainer data-testid='sheet-loading-state'>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (!hasSheet) {
    return (
      <ErrorState
        title='Sheet not found'
        message={`This sheet doesn't seem to exist.`}
        refresh={false}
      />
    )
  }

  if (sheetIsLockedUntilPayment) {
    return (
      <ErrorState
        title='Adding records is currently disabled'
        message={`If you think this is an error, please reach out to support.`}
        refresh={false}
      />
    )
  }

  if (
    readOnlySheet ||
    (canAddToSheet === false && canImportIntoSheet === false)
  ) {
    return (
      <ErrorState
        title='Awaiting data'
        message={`Sheets that don't accept new rows and/or edits commonly receive dynamically populated data. There is no action you can take at take at this time.`}
        refresh={false}
      />
    )
  }

  return (
    <ErrorState
      icon={noActivityIcon}
      title='Nothing to see yet!'
      message='This sheet appears to be empty.'
      refresh={false}
    />
  )
}
