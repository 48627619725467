import { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FileMode, Workbook } from '@flatfile/api'
import { FileController } from '../../../api/controllers/FileController'
import { useController } from '../../../api/controllers/useController'
import { useObservable } from '../../../api/observable'
import { SpaceContext } from '../../../contexts/SpaceContext'

const importAction = {
  key: 'import',
  label: 'Import',
  primary: true,
  modalText: {
    heading: '',
    description: '',
    confirmButtonText: '',
    loadingText: '',
    errorMessage: '',
  },
}

export const useSheetFile = (workbook: Workbook) => {
  const { space, httpClient } = useContext(SpaceContext)

  const navigate = useNavigate()

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const [uploads] = useObservable(fileController.getFiles(), {
    mode: FileMode.Import,
  })

  const isFile = useMemo(
    () => workbook.labels?.includes('file'),
    [workbook.labels]
  )

  const file = useMemo(() => {
    return uploads?.data?.files.find((file) => file.workbookId === workbook.id)
  }, [workbook.id, uploads?.data])

  const fileActions = useMemo(() => {
    if (!file) return []

    const action = () => navigate(`/space/${space.id}/files/${file?.id}/import`)
    return [
      {
        ...importAction,
        action,
        preAction: action,
      },
    ]
  }, [file, space])

  return { isFile, file, fileActions }
}
