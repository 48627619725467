import {
  Button,
  Modal,
  ModalButtonRow,
  ModalCloseButton,
  ModalHeader,
  ModalText,
  Typography,
} from '@flatfile/design-system'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { FormLabel } from '../../../elements/FormLabel'
import { ITag, TagTextArea } from '../../../elements/TagTextArea'
import { IInviteGuests } from '../../../hooks/useGuestManagement'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
`

const SectionContainer = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? 4}px;
  max-height: 500px;
`

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid var(--color-border-strong);
  border-radius: 2px;
  resize: none !important;
  overflow: auto;
  font-family: var(--text-font);
  padding: 8px !important;
  box-sizing: border-box;
  font-size: 14px;

  ::placeholder {
    color: var(--color-text-ultralight);
  }

  &:focus {
    outline: 2px solid var(--color-action);
  }
`

const StyledLink = styled.a`
  color: var(--color-action);
`

const DEFAULT_MESSAGE = "Let's collaborate!"

export const InviteGuestModal = ({
  guestInviteLink,
  sendInvite,
  onClose,
  copyInviteLink,
  showCopyMessage,
  hasInviteLimit,
}: {
  guestInviteLink?: string
  sendInvite: (inviteGuests: IInviteGuests) => void
  onClose(): void
  copyInviteLink: () => Promise<void>
  showCopyMessage: boolean
  hasInviteLimit?: boolean
}) => {
  const [message, setMessage] = useState<string>(DEFAULT_MESSAGE)
  const [guestEmails, setGuestEmails] = useState<ITag[]>([])

  const addGuests = useCallback(() => {
    sendInvite({
      guestEmails: guestEmails.map((g) => g.label),
      message,
    })
  }, [guestEmails, message])

  const sendDisabled = useMemo(
    () =>
      guestEmails.some((e) => e.invalid) ||
      !message.trim() ||
      !guestEmails.length,
    [message, guestEmails]
  )

  const handleTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value)
    },
    [setMessage]
  )

  const content = (
    <>
      <ModalHeader>Invite guests</ModalHeader>
      {hasInviteLimit && (
        <ModalText>
          Upgrade this Space to Project to be able to invite more than one guest
          to collaborate with you.{' '}
          <StyledLink
            href='https://flatfile.com/docs/core-paths/collaborate'
            target='_blank'
          >
            Learn more
          </StyledLink>
          .
        </ModalText>
      )}
      <ContentContainer>
        <TagTextArea
          tags={guestEmails}
          tagLimit={hasInviteLimit ? 1 : undefined}
          setTags={setGuestEmails}
          label='Who would you like to collaborate with?'
          placeholder='Invite by email, comma separated'
          validateInput={isEmailValid}
        />
        <SectionContainer>
          <FormLabel>Message</FormLabel>
          <TextArea
            onChange={handleTextAreaChange}
            rows={4}
            placeholder={DEFAULT_MESSAGE}
            role='textarea'
          />
        </SectionContainer>
        {guestInviteLink ? (
          <CopyLinkSection
            copyInviteLink={copyInviteLink}
            showCopyMessage={showCopyMessage}
          />
        ) : null}
      </ContentContainer>
      <ModalButtonRow>
        <ModalCloseButton onClose={onClose} label='Cancel' />
        <Button
          onPress={addGuests}
          isDisabled={sendDisabled}
          label='Send Invite'
        />
      </ModalButtonRow>
    </>
  )

  return <Modal content={content} data-testid='invite-guest-modal' />
}

const CopyLinkSection = ({
  copyInviteLink,
  showCopyMessage,
}: {
  copyInviteLink: () => Promise<void>
  showCopyMessage: boolean
}) => {
  return (
    <SectionContainer gap={8}>
      <FormLabel>Access link</FormLabel>
      <Button
        fullWidth
        variant='secondary'
        iconRight='link'
        iconSize={16}
        onPress={copyInviteLink}
        label={showCopyMessage ? 'Copied!' : 'Copy link'}
      />
      <Typography type='b1' color='var(--color-text-light)'>
        This link is valid for 24hrs. Anyone with the link can access the Space
        and upload, edit and otherwise manage data.
      </Typography>
    </SectionContainer>
  )
}

export const isEmailValid = (email: string, emails: string[]): boolean => {
  return (
    /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i.test(email) &&
    !emails.includes(email)
  )
}
