import {
  EventTopic,
  Filter,
  JobTriggerEnum,
  JobTypeEnum,
  RecordCounts,
  Sheet,
  Space,
  Workbook,
} from '@flatfile/api'
import {
  CheckmarkIcon,
  PopoverContext,
  PopoverMessage,
} from '@flatfile/shared-ui'
import {
  ActionStatus,
  BulkRowSelection,
  ColumnConfigProps,
  RowData,
  useBulkRowActionsContext,
  useDataBufferContext,
} from '@flatfile/turntable'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { FileController } from '../../../api/controllers/FileController'
import { useController } from '../../../api/controllers/useController'
import { JobOperationEnum } from '../../../contexts/JobsContext'
import { SpaceContext } from '../../../contexts/SpaceContext'
import { useEventSubscriber } from '../../../hooks/useEventSubscriber'
import { capitalizeFirst } from '../../../utils/capitalizeFirst'
import { downloadFile } from '../../../utils/downloadFile'
import { pluralize } from '../../../utils/pluralize'
import {
  ActionType,
  BulkRowActionModalText,
  BulkRowActionTooltipText,
  actionTenses,
  determineFilter,
  getBulkActionModalText,
  getRowsSelectedCount,
} from '../utils/bulkRowUtils'
import { useCustomActions } from './useCustomActions'
import { useRecordMutation } from './useRecordMutation'
import { SearchFields } from './useSearchFilters'
import { OnRefetchRows } from './useSheetViewData'
import { useTranslation } from 'react-i18next'

interface UseBulkRowActionsProps {
  workbook: Workbook
  sheet?: Sheet
  space: Space
  columnConfig: ColumnConfigProps[]
  searchFields: SearchFields
  refetchRows: OnRefetchRows
  toolbarCounts: RecordCounts | undefined
  exitSelectionState: (isSelecting?: boolean) => void
  getFormattedSelection: () => BulkRowSelection
  canDeleteFromSheet?: boolean
}

export interface BulkRowActionItem {
  key: string
  label: string
  disabled?: boolean
  action: () => void
  preAction?: () => void
  icon?: string
  primary?: boolean
  tooltip?: BulkRowActionTooltipText
  modalText: BulkRowActionModalText
}

export interface BulkRowJobConfig {
  sheetId: string
  workbookId: string
  filter?: Filter
  filterField?: string
  searchField?: string
  searchValue?: string
  q?: string
  ids?: string[]
}

export const useBulkRowActions = ({
  workbook,
  sheet,
  space,
  searchFields,
  refetchRows,
  toolbarCounts,
  exitSelectionState,
  getFormattedSelection,
  canDeleteFromSheet,
}: UseBulkRowActionsProps) => {
  const [isBulkDeleting, setIsBulkDeleting] = useState(false)
  const { setActionStatus, bulkRowActionsModal } = useBulkRowActionsContext()
  const { httpClient } = useContext(SpaceContext)
  const { deleteRecords } = useRecordMutation(sheet?.id!)
  const { showPopover } = useContext(PopoverContext)
  const selection = getFormattedSelection()
  const { buffer } = useDataBufferContext()
  const { t } = useTranslation()

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )

  const originatedJobIdsRef = useRef<string[]>([])
  const addOriginatedJobId = (jobId: string) => {
    originatedJobIdsRef.current.push(jobId)
  }

  const showActionSuccessPopover = ({
    action,
    count,
    type,
  }: {
    action: ActionType
    count: number | undefined
    type?: 'record' | 'cell'
  }) => {
    const actionOnType = type ?? 'record'
    const pastVerb = actionTenses.verb[action].past
    const popover = {
      icon: <CheckmarkIcon name='checkmark' />,
      message: (
        <PopoverMessage>
          {count
            ? `${count.toLocaleString()} ${pluralize(
                actionOnType,
                count
              )} ${pastVerb}`
            : `${capitalizeFirst(actionOnType)}s ${pastVerb}`}
        </PopoverMessage>
      ),
      onClose: undefined,
    }
    showPopover(popover, true)
  }

  const rowsSelectedCount = useMemo(() => {
    if (toolbarCounts) {
      return getRowsSelectedCount({
        selection,
        searchFields,
        filterCounts: toolbarCounts,
      })
    }
    return { download: 0, delete: 0, copy: 0 }
  }, [selection, searchFields, toolbarCounts])

  const getSyncedRecordIds = () => {
    if (
      selection.type === 'all' &&
      toolbarCounts?.total &&
      toolbarCounts?.total <= 100
    ) {
      return buffer.bufferedRecords.reduce((memo: string[], row: RowData) => {
        if (row.id && !selection.exceptions.includes(row.id)) {
          memo.push(row.id)
        }
        return memo
      }, [] as string[])
    } else if (selection.type === 'none' && selection.exceptions.length > 0) {
      return selection.exceptions
    }
    return undefined
  }

  const getJobConfig = useCallback((): BulkRowJobConfig => {
    const ids = getSyncedRecordIds()
    if (ids?.length) {
      return { ids, workbookId: workbook.id, sheetId: sheet?.id! }
    }
    return {
      workbookId: workbook.id,
      sheetId: sheet?.id!,
      filter: determineFilter(selection, searchFields),
      filterField: searchFields.filterField,
      searchField: searchFields.searchField,
      searchValue: searchFields.searchValue,
      q: searchFields.q,
      ids: selection.exceptions.length ? selection.exceptions : undefined,
    }
  }, [searchFields, selection, sheet, toolbarCounts])

  const handleBulkActionStart = (action: ActionType) => {
    if (action == 'download') {
      //hide modal for download when job has started
      setActionStatus(ActionStatus.success)
    } else {
      setActionStatus(ActionStatus.inProgress)
    }

    if (action === 'delete') {
      setIsBulkDeleting(true)
    }
  }

  const handleDownload = (fileId: string) => {
    if (!document.hidden) {
      fileController.downloadFile({ fileId: fileId }).then((data) => {
        downloadFile(data)
      })
    }
  }

  const handleBulkActionSuccess = async (
    action: ActionType,
    count: number | undefined,
    fileId?: string,
    jobId?: string
  ) => {
    if (action === 'delete') {
      setActionStatus(ActionStatus.success)
      showActionSuccessPopover({ action, count })
      await refetchRows({ counts: true })
      setIsBulkDeleting(false)
    }
    if (action === 'download' && fileId) {
      if (jobId && originatedJobIdsRef.current.includes(jobId)) {
        await handleDownload(fileId)
      }
      setActionStatus(ActionStatus.success)
    }
    exitSelectionState(true)
    bulkRowActionsModal.close()
  }

  const handleBulkActionFailure = () => {
    setActionStatus(ActionStatus.failed)
    setIsBulkDeleting(false)
  }

  const handleDelete = async () => {
    /* If the number of rows to delete is 100 or less and we have the row ids, perform a sync update */
    const { workbookId, sheetId, ids, ...config } = getJobConfig()
    const syncedIds = getSyncedRecordIds()
    if (syncedIds?.length && syncedIds?.length <= 100) {
      handleBulkActionStart('delete')
      const { data } = await deleteRecords({ sheetId, ids: syncedIds })
      if (data?.success) {
        await handleBulkActionSuccess('delete', rowsSelectedCount.delete)
      } else if (data && !data.success) {
        handleBulkActionFailure()
      }
    } else if (selection.type) {
      /* Otherwise, perform a bulk update */
      await httpClient.createJob({
        jobConfig: {
          type: JobTypeEnum.Workbook,
          operation: JobOperationEnum.DeleteRecords,
          trigger: JobTriggerEnum.Immediate,
          source: workbookId,
          config: { sheet: sheetId, exceptions: ids, ...config },
        },
      })
      handleBulkActionStart('delete')
    }
  }

  const exportToFile = async () => {
    const { workbookId, sheetId, ...config } = getJobConfig()
    const jobResponse = await httpClient.createJob({
      jobConfig: {
        type: JobTypeEnum.Sheet,
        operation: JobOperationEnum.Export,
        trigger: JobTriggerEnum.Immediate,
        source: sheetId,
        config: {
          options: config,
        },
      },
    })
    if (jobResponse.data?.id) {
      addOriginatedJobId(jobResponse.data.id)
    }
    handleBulkActionStart('download')
  }

  const handleSubscriberEvent = useCallback(
    async (event: any) => {
      const eventResponse = JSON.parse(event?.message ?? '{}')

      if (
        eventResponse?.payload?.operation === JobOperationEnum.DeleteRecords
      ) {
        if (
          eventResponse.context &&
          eventResponse.topic === EventTopic.Jobcompleted
        ) {
          await handleBulkActionSuccess('delete', rowsSelectedCount.delete)
        } else if (
          eventResponse.context &&
          eventResponse.topic === EventTopic.Jobfailed
        ) {
          handleBulkActionFailure()
        }
      }
      if (eventResponse?.payload?.operation === JobOperationEnum.Export) {
        if (
          eventResponse.context &&
          eventResponse.topic === EventTopic.Jobcompleted
        ) {
          await handleBulkActionSuccess(
            'download',
            rowsSelectedCount.delete,
            eventResponse.context?.fileId,
            eventResponse.context?.jobId
          )
        } else if (
          eventResponse.context &&
          eventResponse.topic === EventTopic.Jobfailed
        ) {
          handleBulkActionFailure()
        }
      }
    },
    [rowsSelectedCount]
  )

  // Subscribe to bulk action jobs
  useEventSubscriber(
    [EventTopic.Jobfailed, EventTopic.Jobcompleted, EventTopic.Jobupdated],
    handleSubscriberEvent
  )

  const bulkRowActionsText = useMemo(
    () =>
      getBulkActionModalText({
        count: rowsSelectedCount,
        filter: searchFields.filter,
        selection,
      }),
    [selection, rowsSelectedCount, searchFields]
  )

  const activeDownloadTooltip = useMemo(() => {
    if (selection.type === 'none' && selection.exceptions.length === 0) {
      if (searchFields.filter) {
        return searchFields.filter === Filter.Valid
          ? t(
              'sheet.toolbar.buttons.download.tooltip.active.validFilterApplied'
            )
          : t(
              'sheet.toolbar.buttons.download.tooltip.active.invalidFilterApplied'
            )
      }
      return t(
        'sheet.toolbar.buttons.download.tooltip.active.noRecordsSelected'
      )
    }
    return t('sheet.toolbar.buttons.download.tooltip.active.recordsSelected')
  }, [selection, searchFields])

  const actions: BulkRowActionItem[] = [
    {
      key: 'delete',
      label: 'Delete',
      disabled: rowsSelectedCount.delete === 0 || !canDeleteFromSheet,
      action: handleDelete,
      icon: 'trash',
      primary: true,
      tooltip: {
        active: t('sheet.toolbar.buttons.delete.tooltip.active'),
        disabled: canDeleteFromSheet
          ? t('sheet.toolbar.buttons.delete.tooltip.disabled.noRecordsSelected')
          : t('sheet.toolbar.buttons.delete.tooltip.disabled.deleteDisabled'),
      },
      modalText: bulkRowActionsText.delete,
    },
    {
      key: 'download',
      label: 'Download',
      disabled: rowsSelectedCount.download === 0,
      action: exportToFile,
      icon: 'download',
      primary: true,
      tooltip: {
        active: activeDownloadTooltip,
        disabled: t('sheet.toolbar.buttons.download.tooltip.disabled'),
      },
      modalText: bulkRowActionsText.download,
    },
  ]

  const { customActions, workbookActions } = useCustomActions({
    environmentId: workbook.environmentId,
    spaceId: space.id,
    workbook,
    sheet,
    getJobConfig,
    rowsSelectedCount,
  })

  const allActions = actions.concat(customActions)
  const bulkRowActions = allActions
    .filter((x) => x.primary)
    .concat(allActions.filter((x) => !x.primary))

  const [currentWorkbookAction, setCurrentWorkbookAction] =
    useState<BulkRowActionItem | null>(null)

  const workbookActions2 = workbookActions?.map((action) => {
    return {
      ...action,
      preAction: action.confirm
        ? () => setCurrentWorkbookAction(action)
        : action.action,
    }
  })

  const resetCurrentWorkookAction = useCallback(
    () => setCurrentWorkbookAction(null),
    [setCurrentWorkbookAction]
  )
  const submitCurrentWorkbookAction = useCallback(async () => {
    setCurrentWorkbookAction(null)
    if (currentWorkbookAction) {
      await currentWorkbookAction.action()
    }
  }, [currentWorkbookAction, setCurrentWorkbookAction])

  return {
    handleDelete,
    handleDownload: exportToFile,
    handleSubscriberEvent,
    showActionSuccessPopover,
    getJobConfig,
    bulkRowActions,
    isBulkDeleting,
    rowsSelectedCount,
    workbookActions: workbookActions2,
    currentWorkbookAction,
    resetCurrentWorkookAction,
    submitCurrentWorkbookAction,
  }
}
