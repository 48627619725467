import { Filter } from '@flatfile/api'
import { BulkRowSelection, FilterCounts } from '@flatfile/turntable'
import { capitalizeFirst } from '../../../utils/capitalizeFirst'
import { pluralize } from '../../../utils/pluralize'
import { SearchFields } from '../hooks/useSearchFilters'
import { getCurrentRowCount } from './tableUtils'

type KeysUnder<T, K extends PropertyKey> = T extends object
  ? {
      [P in keyof T]-?: (P extends K ? keyof T[P] : never) | KeysUnder<T[P], K>
    }[keyof T]
  : never

export type ActionType = KeysUnder<ActionTenses, 'verb'>

export interface BulkRowActionTooltipText {
  active?: string
  disabled?: string
}

export interface BulkRowActionModalText {
  heading: string
  description: string
  confirmButtonText: string
  loadingText: string
  errorMessage: string | undefined
}

type BulkRowActionModalTexts = {
  [K in ActionType]: BulkRowActionModalText
}

export const actionTenses = {
  verb: {
    download: {
      past: 'downloaded',
      present: 'download',
      progressive: 'downloading',
    },
    delete: {
      past: 'deleted',
      present: 'delete',
      progressive: 'deleting',
    },
    copy: {
      past: 'copied',
      present: 'copy',
      progressive: 'copying',
    },
  },
}

type ActionTenses = typeof actionTenses

export const determineFilter = (
  selection: { type: 'all' | 'none'; exceptions: string[] },
  searchFields: SearchFields
) => {
  const filter =
    searchFields.filterField && !searchFields.filter
      ? Filter.Error
      : searchFields.filter
  if (selection.type === 'none' && !selection.exceptions.length) {
    return filter
  }
  return selection.type === 'none' ? selection.type : filter || selection.type
}

export const getRowsSelectedCount = ({
  selection,
  searchFields,
  filterCounts,
}: {
  selection: BulkRowSelection
  searchFields: SearchFields
  filterCounts: FilterCounts
}) => {
  const counts: { [K in ActionType]: number | undefined } = {
    download: 0,
    delete: 0,
    copy: 0,
  }

  const getCount = (action: ActionType) => {
    //if rows are selected individually
    if (selection.type === 'none' && selection.exceptions.length > 0) {
      return selection.exceptions.length
    }
    const rowCount = getCurrentRowCount(searchFields, filterCounts)
    //if no rows are selected
    if (selection.type === 'none' && selection.exceptions.length === 0) {
      if (action === 'delete') return 0
      return rowCount
    }
    //if rows are all selected
    return rowCount - selection.exceptions.length
  }

  return { download: getCount('download'), delete: getCount('delete'), copy: 0 }
}

export const getBulkActionModalText = ({
  count,
  filter,
  selection,
}: {
  count: { [K in ActionType]: number } | undefined
  filter: Filter | undefined
  selection: BulkRowSelection
}) => {
  const actions = actionTenses.verb
  const allText = {} as BulkRowActionModalTexts
  Object.keys(actions).forEach((action) => {
    const getDescription = () => {
      const nothingSelected =
        selection.type === 'none' && selection.exceptions.length === 0
      const selectedFilter = filter
        ? filter === Filter.Valid
          ? filter
          : 'invalid'
        : ''
      const descriptionText = `Would you like to ${action} ${
        nothingSelected && count && count[action as ActionType] > 1
          ? 'all'
          : 'the'
      } ${
        count && count[action as ActionType]
          ? count[action as ActionType].toLocaleString()
          : ''
      } ${nothingSelected ? '' : 'selected'} ${selectedFilter} ${
        count ? pluralize('record', count[action as ActionType]) : 'records'
      }?`
      return descriptionText.replace(/ +(?= )/g, '')
    }

    allText[action as ActionType] = {
      heading: `${capitalizeFirst(action)} records`,
      description: getDescription(),
      confirmButtonText: `${capitalizeFirst(action)} records`,
      loadingText: `${capitalizeFirst(
        actionTenses.verb[action as ActionType].progressive
      )} records...`,
      errorMessage: `Unable to ${action} records. Please try again or modify your selection.`,
    }
  })
  return allText
}
