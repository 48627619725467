import { Workbook } from '@flatfile/api'
import { NavLink } from 'react-router-dom'

import { TabStrip } from '../../elements/TabStrip'
import { ElementOrSpinner } from '../../packages/ElementOrSpinner'
import { ReadOnlyIcon } from '../../packages/ReadOnly'
import {
  SheetTabIconContainer,
  SheetTabName,
  SheetTabsContainer,
} from './elements'

export const SheetTabs = ({
  workbook,
  path,
}: {
  workbook: Workbook
  path: string
}) => {
  return (
    <SheetTabsContainer>
      <TabStrip>
        {workbook.sheets?.map((sheet) => {
          const name = sheet.name ?? sheet.id
          return (
            <NavLink key={sheet.id} to={`${path}/${sheet.id}`} title={name}>
              <SheetTabName>{name}</SheetTabName>
              {(sheet.config?.readonly ||
                (sheet.config.access && sheet.config.access.length == 0)) && (
                <SheetTabIconContainer>
                  <ReadOnlyIcon />
                </SheetTabIconContainer>
              )}
              <ElementOrSpinner color='var(--color-primary)' id={sheet.id} />
            </NavLink>
          )
        })}
      </TabStrip>
    </SheetTabsContainer>
  )
}
