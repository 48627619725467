import styled from 'styled-components'

export const MatchIconWrap = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  .spinner {
    width: 31px;
    height: 18px;
    margin-top: 3px;
    svg {
      width: 18px;
      float: right;
    }
  }
`
