import {
  Button,
  Modal,
  ModalHeader,
  ModalText,
  ModalButtonRow,
  ModalCloseButton,
} from '@flatfile/design-system'
import { pluralize } from '../../../utils/pluralize'
import { ISpaceUser } from '../../../hooks/useGuestManagement'

interface RemoveGuestProps {
  users: ISpaceUser[]
  onClose: () => void
  onConfirm: () => void
  isRemovingUsers: boolean
}

export const RemoveGuestModal = ({
  users,
  onClose,
  onConfirm,
  isRemovingUsers,
}: RemoveGuestProps) => {
  const otherUserCount = users.length - 1
  const content = (
    <>
      <ModalHeader>Revoke access</ModalHeader>
      <ModalText>
        Are you sure you want to remove {users[0].name ?? users[0].email}
        {otherUserCount
          ? ` and ${otherUserCount} other ${pluralize('guest', otherUserCount)}`
          : ''}{' '}
        from this Space?
      </ModalText>
      <ModalButtonRow>
        <ModalCloseButton onClose={onClose} label='Cancel' />
        <Button
          onPress={onConfirm}
          label={isRemovingUsers ? 'Removing...' : 'Confirm'}
          variant='warning'
          loading={isRemovingUsers}
        />
      </ModalButtonRow>
    </>
  )

  return <Modal content={content} data-testid='remove-guest-modal' />
}
