import { Action } from '@flatfile/api'
import {
  Button,
  Modal,
  ModalHeader,
  ModalText,
  Portal,
} from '@flatfile/design-system'
import { useContext } from 'react'
import styled from 'styled-components'
import { SpaceContext } from '../contexts/SpaceContext'
import { RuntimeParameters } from '../packages/RuntimeParameters'
const { isGuest } = RuntimeParameters()

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div:nth-child(2) {
    margin: 8px 0 20px;
  }
`

export const CelebrateModal = ({
  job,
  resource,
}: {
  job: any
  resource: any
}) => {
  const { httpClient } = useContext(SpaceContext)
  const onCelebrate = (id: string) => async () => {
    await httpClient.acknowledgeJobOutcome({
      jobId: id,
    })
  }
  const action =
    job && resource && resource.actions
      ? resource.actions.find((a: Action) => a.operation === job.operation)
      : undefined
  const resourceName = resource?.name || 'this resource'
  const jobName = action?.label || 'Job'

  const heading = job?.outcome?.heading || 'Success!'
  const message =
    job?.outcome?.message || `${jobName} ran on ${resourceName} successfully`

  const buttonText = job?.outcome?.buttonText || 'Continue'

  if (!isGuest && job.outcomeAcknowledgedAt !== undefined) {
    return null
  }

  return (
    <Portal>
      <Modal
        overlayType='opaque'
        content={
          <Container>
            <ModalHeader>{heading}</ModalHeader>
            <ModalText>{message}</ModalText>
            <Button
              variant='primary'
              onPress={onCelebrate(job.id)}
              label={buttonText}
            />
          </Container>
        }
      />
    </Portal>
  )
}
