import { JobModeEnum, JobStatusEnum } from '@flatfile/api'
import { Modal } from '@flatfile/design-system'
import { useMutation } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { JobsContext } from '../../contexts/JobsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { AcknowledgeModalContent } from '../../elements/AcknowledgeModalContent'
import { CelebrateModal } from '../../elements/CelebrateModal'
import { ForegroundJobModalContent } from '../../elements/ForegroundJobModalContent'
import { NoListenerModalContent } from '../../elements/NoListenerModalContent'
import { useBlockingJobs } from '../../hooks/useBlockingJobs'
import { useFinishedJobs } from '../../hooks/useFinishedJobs'
import { useJobsWithNoResponse } from '../../hooks/useJobsWithNoResponse'
import { useResource } from './useResource'
import { useTranslation } from 'react-i18next'

const exampleModalText = {
  heading: "You've submitted 475 records to ClickUp!",
  description:
    'ClickUp can configure this section with a custom message. What’s next for this data owner? This is a chance for IM to set the stage for what is coming.',
  buttonText: 'Continue to ClickUp',
}

const NegativeMarginForBlur = styled.div`
  margin: -10px;
  height: 100%;
  width: calc(100% + 20px);
  position: relative;
`

const Blur = styled.div<{ blurred: boolean }>`
  padding: 10px;
  filter: ${(props) => (props.blurred ? 'blur(2px)' : undefined)};
  display: flex;
  flex-direction: columm;
  height: 100%;
  width: 100%;
  transition: filter 0.5s ease-in-out, background-color 0.5s ease-in-out;
`

export const BlurResource = ({
  id,
  children,
}: {
  id: string | undefined
  children: React.ReactNode
}) => {
  const modes: JobModeEnum[] = [JobModeEnum.Foreground]
  //keep local state of jobs that have been acknowledged for faster UX response
  const [outcomeAckdJobIds, setOutcomeAckdJobIds] = useState<string[]>([])
  const locallyOutcomeAck = (jobId: string) => {
    setOutcomeAckdJobIds((prev) => [...prev, jobId])
  }
  const { activeJobs } = useContext(JobsContext)
  const { httpClient } = useContext(SpaceContext)
  const { t } = useTranslation()

  const blockingJobs = useBlockingJobs(id, modes)
  const locked = blockingJobs.length > 0

  const finishedJobs = useFinishedJobs(id)

  const jobsToAcknowledge = finishedJobs.filter(
    (j) =>
      (j?.outcome as any)?.next?.type !== 'wait' &&
      !j.outcomeAcknowledgedAt &&
      !outcomeAckdJobIds.includes(j.id)
  )
  const jobsToCelebrate = Object.values(activeJobs ?? [])
    .filter((j) => j.mode && modes.includes(j.mode))
    .filter((j) => id && j.source === id)
    .filter(
      (j) =>
        j.status == JobStatusEnum.Complete &&
        (j?.outcome as any)?.next?.type == 'wait'
    )

  const jobsWithNoResponse = useJobsWithNoResponse(id)

  const { mutate: cancelJob } = useMutation<unknown, unknown, string>(
    ['cancelJob'],
    async (id: string) => {
      console.log('cancelling job')
      await httpClient.cancelJob({
        jobId: id,
        jobCancelDetails: { info: t('jobs.cancelledByUser') },
      })
    },
    {
      onSuccess: () => {},
    }
  )

  const [resource] = useResource<any>(id, false)

  return (
    <NegativeMarginForBlur>
      <Blur
        blurred={locked}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {children}
      </Blur>

      {locked && (
        <Modal
          overlayType='limited'
          content={
            <ForegroundJobModalContent
              job={blockingJobs[0]}
              resource={resource}
            />
          }
        />
      )}

      {jobsWithNoResponse.length > 0 && (
        <Modal
          overlayType='limited'
          content={
            <NoListenerModalContent
              job={jobsWithNoResponse[0]}
              deleteJob={() => cancelJob(jobsWithNoResponse[0].id)}
            />
          }
        />
      )}

      {jobsToCelebrate.map((job) => (
        <CelebrateModal key={job.id} job={job} resource={resource} />
      ))}

      {jobsToAcknowledge.length > 0 && (
        <Modal
          overlayType='limited'
          content={
            <AcknowledgeModalContent
              job={jobsToAcknowledge[0]}
              resource={resource}
              locallyOutcomeAck={locallyOutcomeAck}
            />
          }
        />
      )}
    </NegativeMarginForBlur>
  )
}
