import { isEqual } from 'lodash'
import { BaseEvent } from '../../hooks/useEventSubscriber'
import { Resource } from './Resource'
import { fetchers, getTargetIdFromEvent, resourceFetcherFromId } from './utils'

export class resources {
  /**
   * Cache resources
   * @private
   */
  private static CACHE: Map<string, Resource> = new Map()

  /**
   * Clear the cache
   */
  public static clearCache() {
    resources.CACHE = new Map()
  }

  /**
   * Get a resource from the cache
   *
   * @param id
   */
  public static get<T extends { updatedAt: Date }>(
    id: string | undefined | null
  ): Resource<T> {
    if (!id) {
      throw new Error('Cannot get a resource without an id')
    }
    return this.obtainCacheEntry(id)
  }

  /**
   * Obtain a resource but return a promise to resolve it
   *
   * @param id
   */
  public static async getWithPromise<T extends { updatedAt: Date }>(
    id: string | undefined | null
  ): Promise<Resource<T>> {
    if (!id) {
      throw new Error('Cannot get a resource without an id')
    }
    const existing = this.CACHE.get(id)

    // todo: there is a chance this results in double-requests sometimes
    if (
      existing?.observable.data ||
      existing?.observable?.lastLoadedParams == 'MOCKING'
    ) {
      return Promise.resolve(existing)
    } else {
      try {
        const fetcher = resourceFetcherFromId(id)
        const res = await fetcher(id)
        return this.exchangeForResource(res.data)
      } catch (e) {
        console.log('error', e)
        return Promise.reject(e)
      }
    }
  }

  /**
   * Set or update the value of a cached resource - will propagate
   * changes to all observers
   *
   * @param id
   * @param data
   */
  public static set<T extends { updatedAt: Date }>(
    id: string,
    data: T
  ): Resource<T> {
    const incomingUpdatedAt = data.updatedAt
    const resource = this.obtainCacheEntry(id, data) as Resource<T>
    const existingUpdatedAt = resource.maybeData?.updatedAt
    if (
      !resource.maybeData ||
      !existingUpdatedAt ||
      (existingUpdatedAt < incomingUpdatedAt &&
        !isEqual(resource.maybeData, data))
    ) {
      resource.apply(data)
    }

    return resource
  }

  /**
   * Exchange anything with an id for a related observable
   *
   * @param entity
   */
  public static exchangeForResource<
    E extends string | { id: string } | Resource
  >(entity: E): Resource {
    if (entity instanceof Resource) {
      return entity
    }
    if (typeof entity === 'string') {
      return this.get(entity)
    }
    if (typeof entity === 'object' && 'id' in entity) {
      return this.set(entity.id, entity as any)
    }
    throw new Error(`Cannot exchange ${typeof entity} for a resource.`)
  }

  /**
   * Capture a raw entity and pass back its original value
   *
   * @param entity
   */
  public static storeInCache<E extends { id: string }>(entity: E): E {
    try {
      this.exchangeForResource(entity)
    } catch (e) {
      console.warn(
        'Attempt to capture entity failed. Skipping cache.',
        entity,
        e
      )
    }
    return entity
  }

  /**
   * Trigger events on any known entities
   *
   * @param event
   */
  public static emitResourceEvent<E extends BaseEvent>(event: E) {
    try {
      const [resource, topic] = event.topic.split(':')

      const getIdsFromContext = (context: any): string[] => {
        const { slugs, ...rest } = context
        return Object.values(rest)
      }

      const ids = getIdsFromContext(event.context)
      const targetId = getTargetIdFromEvent(resource, event)
      if (targetId) {
        try {
          const target = this.get<any>(targetId)
          if (['updated', 'created', 'outcome-acknowledged'].includes(topic)) {
            target.reloadIfStale(
              (event as any).attributes?.targetUpdatedAt ??
                (event as any).createdAt
            )
          }
        } catch (e) {
          // do nothing
        }
      }

      ids.forEach((id) => {
        if (!id) return
        const [, res] = id.split('_')
        if (!(fetchers as any)[res]) {
          return
        }
        try {
          this.get(id).emit(event.topic, event)
        } catch (e) {
          console.log(`🛑 [${targetId}] ${event.topic} @ ${id}`, event)
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  /**
   * Obtain a cache entry for this resource by id
   *
   * @param id
   * @param data
   */
  private static obtainCacheEntry<T extends { updatedAt: Date }>(
    id: string,
    data?: T
  ): Resource<T> {
    const existing = this.CACHE.get(id)

    if (existing) {
      return existing
    } else {
      const resource = new Resource<T>(id, data)

      this.CACHE.set(id, resource)
      return resource
    }
  }
}
// @ts-ignore
window.resources = resources
