import PubNub from 'pubnub'
import { HttpClient } from './HttpClient'

export class EventSubscriber {
  public static getClient(spaceId: string) {
    return HttpClient.spaces
      .getEventToken({ spaceId })
      .then(({ data }) => {
        if (data) {
          const pubnub = new PubNub({
            subscribeKey: data.subscribeKey!,
            uuid: data.accountId!,
          })
          pubnub.setToken(data.token!)
          return pubnub
        }
      })
      .catch((e) => {
        console.warn('Unable to get event token: ', e)
      })
  }
}
