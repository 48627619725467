import { useMemo, useRef, useState } from 'react'
import { Icon, Spinner, useOnClickOutside } from '@flatfile/design-system'
import styled from 'styled-components'
import { JobsPanel } from './JobsPanel'
import { JobStatus, useJobStatus } from '../hooks/useJobStatus'
import { Job } from '@flatfile/api'

interface JobStatusWrapperProps {
  status: JobStatus
  hover: boolean
}
const JobStatusWrapper = styled.div<JobStatusWrapperProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => backgroundColor(props)};
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 21px;
  width: fit-content;
  height: 28px;
  cursor: pointer;

  svg {
    color: ${(props) =>
      props.status !== 'none' || props.hover
        ? 'var(--color-title)'
        : 'var(--color-text-ultralight)'};
  }

  span {
    font-weight: 600;
    font-size: var(--size-small);

    &.progress {
      font-weight: normal;
    }
  }
`

export const JobsStatus: React.FC<{
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}> = ({ isOpen, onClose, onOpen }) => {
  const { status, currentDisplayState, currentlyRunningJobs } = useJobStatus()
  const [showHoverState, setShowHoverState] = useState<boolean>(false)
  const averageProgress = useMemo(
    () => calculateAverageProgress(currentlyRunningJobs),
    [currentlyRunningJobs]
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, onClose)
  return (
    <div ref={ref}>
      <JobStatusWrapper
        data-testid='job-status'
        status={status}
        hover={status === 'none' && (showHoverState || isOpen)}
        onMouseEnter={() => setShowHoverState(status === 'none')}
        onMouseLeave={() => setShowHoverState(false)}
        onClick={onOpen}
      >
        {status === 'active' && (
          <>
            <Spinner color='var(--color-primary)' size={16} />
            {!!averageProgress && (
              <span className='progress'>{averageProgress}%</span>
            )}
          </>
        )}
        <span data-testid={`status-${status}`}>
          {(showHoverState || (isOpen && status === 'none')) && 'Jobs'}

          {currentlyRunningJobs.length > 1 && status === 'active'
            ? `${currentlyRunningJobs.length} jobs running`
            : currentDisplayState?.text}
        </span>
        <Icon name='truck' />
      </JobStatusWrapper>
      {isOpen && <JobsPanel onClose={onClose} />}
    </div>
  )
}

export const backgroundColor = (props: JobStatusWrapperProps) => {
  if (props.hover) return 'var(--color-primary-light)'
  switch (props.status) {
    case 'active':
      return 'var(--color-primary-light)'
    case 'complete':
      return 'var(--color-success-light)'
    case 'failed':
      return 'var(--color-danger-light)'
    case 'none':
      return 'transparent'
  }
}

export const calculateAverageProgress = (currentlyRunningJobs: Job[]) => {
  const progresses = currentlyRunningJobs
    .map((job) => job.progress)
    .filter((value): value is number => !!value)
  return Math.floor(progresses.reduce((a, b) => a + b, 0) / progresses.length)
}
