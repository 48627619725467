import { Workbook } from '@flatfile/api'
import { Modal } from '@flatfile/design-system'
import { UsageBanner } from '@flatfile/shared-ui'
import { Table, Toolbar, useBulkRowSelection } from '@flatfile/turntable'
import { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BlurResource } from '../../api/resources/BlurResource'
import { EnvironmentsContext } from '../../contexts/EnvironmentsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ActionConfirmModalContent } from '../../elements/ActionConfimModalContent'
import { HeadingRow, PrimaryHeading } from '../../elements/PrimaryHeading'
import { SheetTabs } from './SheetTabs'
import {
  SheetContainer,
  SheetTableContainer,
  SheetHeaderContainer,
} from './elements'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { usePaymentAccess } from '../../hooks/usePaymentAccess'
import { useSheetAccess } from '../../hooks/useSheetAccess'
import { CommandMenu } from '../../packages/CommandMenu/CommandMenu'
import { getTurntableTheme } from '../SpaceApp/Theme/ThemeUtils'
import { PrimaryActions } from './PrimaryActions'
import { useBulkRowActions } from './hooks/useBulkRowActions'
import { useEmptyState } from './hooks/useEmptyState'
import { useFieldData } from './hooks/useFieldData'
import useFindAndReplace from './hooks/useFindAndReplace'
import { useSearchFilters } from './hooks/useSearchFilters'
import { useSheetViewData } from './hooks/useSheetViewData'
import { useSheetFile } from './hooks/useSheetFile'

const DEFAULT_COUNTS = { total: 0, error: 0, valid: 0 }

export const SheetView = ({
  spaceUrl,
  workbook,
}: {
  spaceUrl: string
  workbook: Workbook
}) => {
  const { space, metadata, sidebarCollapsed } = useContext(SpaceContext)

  const { features } = useContext(EnvironmentsContext)

  useEffect(() => {
    if (sidebarCollapsed) {
      /* Set the sidebar state to whatever is stored for sheets */
      const sheetSidebarCollapsed = sidebarCollapsed.get({ sheet: true })
      sidebarCollapsed.set(sheetSidebarCollapsed)
    }
  }, [])

  const { sheetId } = useParams()

  const { exitSelectionState, getFormattedSelection } = useBulkRowSelection()

  const sheet = useMemo(
    () => workbook.sheets?.find((x) => x.id === sheetId),
    [workbook.sheets, sheetId]
  )

  const { isFile, fileActions } = useSheetFile(workbook)

  const {
    readOnlySheet,
    canEditSheet,
    canAddToSheet,
    canDeleteFromSheet,
    canImportIntoSheet,
    noAccessLimitations,
    sheetIsLockedUntilPayment,
  } = useSheetAccess({ currentWorkbook: workbook, currentSheet: sheet })

  useDocumentTitle(sheet?.name, space.name)

  const turntableTheme = useMemo(() => getTurntableTheme(metadata), [metadata])

  const { columnConfig } = useFieldData({ workbook, sheet })

  const {
    isFiltered,
    searchFields,
    sortOverride,
    filteredResultsLoading,
    searchByValueLoading,
    setFilteredResultsLoading,
    setSearchByValueLoading,
    handleClickFilter,
    handleClickFilterField,
    handleSearchByValue,
    handleSort,
  } = useSearchFilters({ columnConfig })

  const {
    rowCount,
    additionalRowCount,
    isLoading,
    isLoadingCounts,
    isLoadingEmpty,
    isEmpty,
    hasCountsError,
    tableError,
    refetchRows,
    counts,
    filteredCounts,
    errorFields,
    showEmptyOverlay,
    hideEmptyOverlay,
    getErrorsByFieldCounts,
  } = useSheetViewData({
    sheetId: sheetId!,
    sheet,
    columnConfig,
    searchFields,
    readOnlySheet,
    canDeleteFromSheet,
    canAddToSheet,
    handleSearchByValue,
  })

  const selection = getFormattedSelection()

  const {
    bulkRowActions,
    workbookActions,
    currentWorkbookAction,
    resetCurrentWorkookAction,
    submitCurrentWorkbookAction,
    handleDownload,
    showActionSuccessPopover,
    getJobConfig,
  } = useBulkRowActions({
    workbook,
    sheet,
    space,
    columnConfig,
    searchFields,
    refetchRows,
    toolbarCounts: counts,
    exitSelectionState,
    getFormattedSelection,
    canDeleteFromSheet,
  })

  const { getFindAndReplaceProps, handleFindAndReplace } = useFindAndReplace({
    sheetId: sheetId!,
    refetchRows,
    columnConfig,
    searchFields,
    getJobConfig,
    isFiltered,
  })

  useEffect(() => {
    if (!isLoading && !isLoadingCounts) {
      setSearchByValueLoading(false)
      if (!isLoadingCounts) {
        setFilteredResultsLoading(false)
      }
    }
  }, [isLoading, isLoadingCounts])

  //if filter by error is active but all errors are resolved, remove filter field
  useEffect(() => {
    if (searchFields.filterField && counts?.error === 0) {
      handleClickFilterField()
    }
  }, [searchFields, counts])

  const showInitialEmptyStateOverlay = useMemo(
    () =>
      !rowCount &&
      !isEmpty &&
      !isFiltered &&
      !readOnlySheet &&
      !!showEmptyOverlay,
    [
      rowCount,
      searchFields,
      isEmpty,
      isFiltered,
      readOnlySheet,
      showEmptyOverlay,
    ]
  )

  const { emptyState, emptyStateOverlay } = useEmptyState({
    space,
    sheet,
    tableError,
    isLoading: isLoading || isLoadingCounts,
    readOnlySheet,
    canEditSheet,
    canAddToSheet,
    canDeleteFromSheet,
    canImportIntoSheet,
    noAccessLimitations,
    sheetIsLockedUntilPayment,
    hideEmptyOverlay,
  })

  const { usageBannerProps } = usePaymentAccess()

  return (
    <>
      {features?.aiAssist && (
        <CommandMenu
          searchFields={searchFields}
          selection={selection}
          onSearchByValue={handleSearchByValue}
        />
      )}
      {currentWorkbookAction && (
        <Modal
          content={
            <ActionConfirmModalContent
              closeModal={resetCurrentWorkookAction}
              onCancel={resetCurrentWorkookAction}
              onSubmit={submitCurrentWorkbookAction}
              text={currentWorkbookAction.modalText}
            />
          }
        />
      )}
      <SheetContainer data-testid='expandable-container'>
        <SheetHeaderContainer>
          <HeadingRow
            height={40}
            action={
              <PrimaryActions
                workbook={workbook}
                actions={isFile ? fileActions : workbookActions}
              />
            }
          >
            <PrimaryHeading>{workbook.name}</PrimaryHeading>
          </HeadingRow>
          <UsageBanner {...usageBannerProps} />
        </SheetHeaderContainer>

        <>
          <BlurResource id={workbook.id}>
            <SheetTabs
              workbook={workbook}
              path={`${spaceUrl}/workbook/${workbook.id}/sheet`}
            />
            <BlurResource id={sheetId}>
              {!isFile && (
                <Toolbar
                  counts={hasCountsError ? counts ?? {} : counts}
                  filteredCounts={
                    hasCountsError ? DEFAULT_COUNTS : filteredCounts
                  }
                  disabled={isLoadingEmpty}
                  theme={turntableTheme}
                  errorColumns={errorFields}
                  actionsList={bulkRowActions}
                  searchFields={searchFields}
                  onClickFilter={handleClickFilter}
                  onClickFilterField={handleClickFilterField}
                  onSearchValue={handleSearchByValue}
                  loadingResults={filteredResultsLoading}
                  loadingSearchResults={searchByValueLoading}
                  onOpenFilterByError={getErrorsByFieldCounts}
                />
              )}
              <SheetTableContainer>
                {showInitialEmptyStateOverlay && emptyStateOverlay}
                <Table
                  readOnly={readOnlySheet || !canEditSheet}
                  key={sheetId}
                  tableId={`${sheetId}-table`}
                  additionalRows={additionalRowCount}
                  columnConfig={columnConfig}
                  onSort={handleSort}
                  sortOverride={sortOverride}
                  totalRowCount={rowCount}
                  emptyState={emptyState}
                  theme={turntableTheme}
                  onDownloadTable={handleDownload}
                  onFindAndReplace={handleFindAndReplace}
                  getFindAndReplaceModalProps={getFindAndReplaceProps}
                  bulkSelectionEnabled={!isFile}
                  onSearchValue={handleSearchByValue}
                  onActionSuccess={showActionSuccessPopover}
                  isFiltered={isFiltered}
                />
              </SheetTableContainer>
            </BlurResource>
          </BlurResource>
        </>
      </SheetContainer>
    </>
  )
}
