import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as en from '../public/locales/en.json'
import * as es from '../public/locales/es.json'

i18n.on('missingKey', (key) => {
  console.warn(`Missing translation key: ${key}`)
})

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      // commenting out until all translations are complete and this is ready for customer consumption
      // es: {
      //   translation: es,
      // },
    },
  })

export default i18n
