import { JobStatusEnum } from '@flatfile/api'
import {
  Button,
  Modal,
  ModalButtonRow,
  ModalHeader,
  ModalText,
} from '@flatfile/design-system'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { EnvironmentsContext } from '../../contexts/EnvironmentsContext'
import { JobOperationEnum, JobsContext } from '../../contexts/JobsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { Container } from '../../elements/Container'
import { ContentContainer } from '../../elements/ContentContainer'
import { getDevBanner } from '../../elements/DevBanner'
import { Expand } from '../../elements/Expand'
import { SpacesUISkeleton } from '../../packages/SpacesUISkeleton'
import { useRouteParams } from '../../hooks/useRouteParams'
import { RuntimeParameters } from '../RuntimeParameters'
import { Sidebar, SidebarContainer, SidebarExpandTrigger } from '../Sidebar'
import { SpacePageLayoutContainer, StyledSpan } from './elements'

const WAIT_TIME_SPACE_CREATION_FALLBACK = 10000

export function SpacePageLayout({ children }: { children: ReactNode }) {
  const [showEmptySpaceModal, setEmptySpaceModal] = useState<boolean>(false)
  const [autoConfigureInProgress, setAutoConfigureInProgress] = useState<
    boolean | undefined
  >(undefined)
  const { space, sidebarCollapsed, metadata } = useContext(SpaceContext)
  const { activeJobs } = useContext(JobsContext)
  const { workbookId } = useRouteParams()
  const { environment } = useContext(EnvironmentsContext)
  const { isGuest } = RuntimeParameters()

  const showSidebar = metadata?.sidebarConfig?.showSidebar
  const hideSidebar = showSidebar === false
  const collapsed = sidebarCollapsed?.get()
  const toggleSidebar = () => {
    /*
      The toggle is only for sheets, so it should always set the sidebar
      collapsed value for sheets when called.
    */
    sidebarCollapsed?.set(!collapsed, { sheet: true })
  }

  useEffect(() => {
    const inProgress = activeJobs?.some(
      (job) =>
        job.operation === JobOperationEnum.Configure &&
        job.status !== JobStatusEnum.Complete &&
        job.type === 'space'
    )
    if (inProgress !== autoConfigureInProgress) {
      setAutoConfigureInProgress(inProgress)
    }
  }, [activeJobs])

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      if (autoConfigureInProgress) {
        setEmptySpaceModal(true)
      }
    }, WAIT_TIME_SPACE_CREATION_FALLBACK)

    return () => {
      clearTimeout(idTimeout)
    }
  }, [autoConfigureInProgress])

  if (!space || autoConfigureInProgress === undefined) {
    return SpacesUISkeleton()
  }

  if (autoConfigureInProgress) {
    return (
      <>
        {showEmptySpaceModal ? (
          <Modal
            content={
              <>
                <ModalHeader data-testid='modal-header-content'>
                  Empty space created
                </ModalHeader>
                <ModalText>
                  A space was created, but no configuration was applied. Set up
                  a <StyledSpan>space:created</StyledSpan> listener to add
                  structure and functionality to this space.
                </ModalText>
                <ModalButtonRow>
                  <Button
                    type='submit'
                    onPress={() =>
                      window.open(
                        'https://flatfile.com/docs/concepts/events',
                        '_blank'
                      )
                    }
                    label='Learn more'
                  />
                </ModalButtonRow>
              </>
            }
          />
        ) : (
          SpacesUISkeleton(space.name, 'Preparing space...')
        )}
      </>
    )
  }

  return (
    <>
      {getDevBanner(environment)}
      <SpacePageLayoutContainer>
        {!(hideSidebar && isGuest) && (
          <SidebarContainer collapsed={collapsed}>
            {!collapsed && <Sidebar />}
            {workbookId && (
              <SidebarExpandTrigger
                collapsed={collapsed}
                adminOnly={hideSidebar && !isGuest}
                onClick={toggleSidebar}
              />
            )}
          </SidebarContainer>
        )}
        <ContentContainer>
          <Expand>
            <Container>{children}</Container>
          </Expand>
        </ContentContainer>
      </SpacePageLayoutContainer>
    </>
  )
}
