import { DefaultApi, WorkbookResponse } from '@flatfile/api'
import { HttpClient } from '../../packages/HttpClient'
import { Observable } from '../observable'
import { Workbook } from '@flatfile/api'

export class WorkbookController {
  constructor(private httpClient: DefaultApi) {}

  /**
   * Get a workbook
   *
   * @todo this should be refactored to return an observable
   * @param id
   */
  public getWorkbookById(id: string): Promise<WorkbookResponse> {
    return this.httpClient.getWorkbookById({ workbookId: id })
  }

  public getAllWorkbooksWithCounts({
    spaceId,
  }: {
    spaceId: string
  }): Observable<Workbook[]> {
    return new Observable(() =>
      HttpClient.workbooks.getAllWorkbooks({
        spaceId,
        includeCounts: true,
      })
    )
  }
}
