import { Job } from '@flatfile/api'
import { ReactElement, useContext } from 'react'
import { JobController } from '../../api/controllers/JobController'
import { ReadyObservable, useObservable } from '../../api/observable'
import { guard } from '../../api/resources/GuardResources'
import { DialogPortal } from '../../elements/DialogPortal'
import { useRouteParams } from '../../hooks/useRouteParams'
import { MapGuard } from './map/MapGuard'
import { SpaceContext } from '../../contexts/SpaceContext'
import { JobOperationEnum } from '../../contexts/JobsContext'
import { Container } from '../../elements/Container'

/**
 * Load the job and once loaded, present the right job handling UI
 *
 * @todo register some listeners here as well for job events related to this job
 * @todo if job enters execution mode it should go into progress
 * @todo if job is in progress mode and completes it should show a success
 * @todo if job is taking a while you should be able to request a notification
 * @todo job not found
 */
export function JobRouter() {
  const params = useRouteParams('jobId')
  const { httpClient } = useContext(SpaceContext)
  const [job] = useObservable(JobController.getJob(params.jobId, httpClient))

  return (
    <DialogPortal>
      <Container>{guard(job) ?? getJobHandler(job.ensured)}</Container>
    </DialogPortal>
  )
}

/**
 * Return the appropriate job handler UI component
 *
 * @param job
 * @private
 */
function getJobHandler(job: ReadyObservable<Job>): ReactElement {
  switch (job.data.operation!) {
    case JobOperationEnum.Map:
      return <MapGuard job={job.data} />
    default:
      return (
        <div data-testid='generic-job-unhandled'>
          [todo] show something for generic jobs
        </div>
      )
  }
}
