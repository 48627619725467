import {
  Job as APIJob,
  GetJobsRequest,
  JobStatusEnum,
  SortDirection,
} from '@flatfile/api'
import { createContext, useContext, useMemo } from 'react'
import { JobController } from '../api/controllers/JobController'
import { Observable, useObservable } from '../api/observable'
import { useResourceStream } from '../hooks/useResourceStream'
import { SpaceContext } from './SpaceContext'

export const JobOperationEnum = {
  Extract: 'extract',
  Map: 'map',
  DeleteRecords: 'delete-records',
  Export: 'export',
  Configure: 'configure',
  FindReplace: 'find-replace',
}
export type JobOperationEnum =
  (typeof JobOperationEnum)[keyof typeof JobOperationEnum]

export type Job = APIJob

export type ActiveJobs = Job[] | undefined

interface JobsContextType {
  activeJobs: ActiveJobs
  latestUpdatedJob?: Job
  latestUpdatedRunningJob?: Job
  jobsRequest?: Observable<APIJob[], GetJobsRequest>
}

export const JobsContext = createContext<JobsContextType>({
  activeJobs: undefined,
})

export const JobsContextProvider = (props: { children: JSX.Element }) => {
  const { activeJobs, latestJob, latestRunningJob, jobsRequest } =
    useJobsContext()
  return (
    <JobsContext.Provider
      value={{
        activeJobs,
        latestUpdatedJob: latestJob,
        latestUpdatedRunningJob: latestRunningJob,
        jobsRequest,
      }}
    >
      {props.children}
    </JobsContext.Provider>
  )
}

const useJobsContext = () => {
  const { space } = useContext(SpaceContext)

  const [jobsRequest] = useObservable(JobController.getJobsForSpace, {
    spaceId: space.id,
    sortDirection: SortDirection.Desc,
    pageSize: 100,
  })

  const activeJobsStream = useResourceStream('job', jobsRequest)?.filter(
    (job) =>
      job.status &&
      job.status !== JobStatusEnum.Planning &&
      job.status !== JobStatusEnum.Created &&
      //TODO remove this hack when mapping jobs are handled so they start in Planning state
      !(
        job.operation == JobOperationEnum.Map &&
        job.status == JobStatusEnum.Ready
      )
  )

  const { latestJob, latestRunningJob, activeJobs } = useMemo(() => {
    const activeUnacknowledgedJobs = activeJobsStream?.filter(
      (job) => !job.outcomeAcknowledgedAt
    )
    let latest = activeUnacknowledgedJobs?.[0]
    activeUnacknowledgedJobs?.forEach((job) => {
      if (
        latest &&
        new Date(job.updatedAt).getTime() > new Date(latest.updatedAt).getTime()
      ) {
        latest = job
      }
    })

    const runningJobs =
      activeJobsStream?.filter(
        (job) => job.status == 'executing' || job.status == 'ready'
      ) ?? []

    let latestRunning = runningJobs?.[0]
    runningJobs?.forEach((job) => {
      if (
        latestRunning &&
        new Date(job.updatedAt).getTime() >
          new Date(latestRunning.updatedAt).getTime()
      ) {
        latestRunning = job
      }
    })

    return {
      latestJob: latest,
      latestRunningJob: latestRunning,
      activeJobs: activeJobsStream,
    }
  }, [activeJobsStream])

  return { activeJobs, latestJob, latestRunningJob, jobsRequest }
}

export const getCurrentlyRunningJobs = (activeJobs: ActiveJobs) =>
  activeJobs?.filter(
    (job) => job.status == 'executing' || job.status == 'ready'
  ) ?? []

export const getCompletedJobs = (activeJobs: ActiveJobs) =>
  activeJobs?.filter((job) => job.status == 'complete') ?? []

export const getFailedJobs = (activeJobs: ActiveJobs) =>
  activeJobs?.filter((job) => job.status == 'failed') ?? []
