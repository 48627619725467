import { Plan } from '@flatfile/shared-ui'
import { useQuery } from '@tanstack/react-query'
import { debounce } from 'lodash'
import { useCallback, useContext } from 'react'
import { EnvironmentsContext } from '../contexts/EnvironmentsContext'
import { SpaceContext } from '../contexts/SpaceContext'
import { RuntimeParameters } from '../packages/RuntimeParameters'
import { useEventSubscriber } from './useEventSubscriber'
const { isGuest, returnUrl } = RuntimeParameters()

const cutoffDate = new Date('July 12, 2023 00:00:00 GMT+00:00')
const gracePeriodEnd = new Date('September 1, 2023 00:00:00 GMT+00:00')

export const usePaymentAccess = () => {
  const { environment, account } = useContext(EnvironmentsContext)
  const { httpClient } = useContext(SpaceContext)
  const billingEnabled = environment
    ? !!(environment.features as any)?.billingEnabled
    : false

  const inGracePeriod =
    account && account.createdAt < cutoffDate && new Date() < gracePeriodEnd
  const { data: limitsData } = useQuery(['getLimits'], () =>
    httpClient.getLimits()
  )

  const fileUploadIsLockedUntilPayment =
    inGracePeriod || !billingEnabled
      ? false
      : limitsData?.data?.fileUploadIsLockedUntilPayment
  const sheetIsLockedUntilPayment =
    inGracePeriod || !billingEnabled
      ? false
      : limitsData?.data?.sheetIsLockedUntilPayment

  // This violates the rules of hooks by early-returning, however, because
  // isGuest is constant between renders, it should not cause any problems
  if (isGuest) {
    return {
      fileUploadIsLockedUntilPayment,
      sheetIsLockedUntilPayment,
      usageBannerProps: {
        filesUsageObject: { used: 0, limit: 0 },
        pdvUsagePercentage: 0,
        plan: 'pro-discount' as Plan, // no banners are shown for pro-discount
        billingUrl: `${returnUrl}/billing`,
      },
      freeFileLimit: 50,
      freePdvLimit: 10_000_000,
    }
  }

  const { data, isLoading, refetch } = useQuery(['getPlan'], () =>
    httpClient.getPlan()
  )
  const { data: dataSubscriptions, isLoading: isLoadingSubscription } =
    useQuery(['getSubscription'], () => httpClient.getSubscriptions())

  const refetchDebounced = useCallback(debounce(refetch, 500), [refetch])
  useEventSubscriber('*', () => {
    refetchDebounced()
  })

  if (!billingEnabled || isLoading || isLoadingSubscription) {
    return {
      fileUploadIsLockedUntilPayment: false,
      sheetIsLockedUntilPayment: false,
      usageBannerProps: {
        filesUsageObject: { used: 0, limit: 0 },
        pdvUsagePercentage: 0,
        plan: 'pro-discount' as Plan, // no banners are shown for pro-discount
        billingUrl: `${returnUrl}/billing`,
      },
      freeFileLimit: 50,
      freePdvLimit: 10_000_000,
    }
  }

  const filesUsage = data!.data!.files!
  const pdvUsage = data!.data!.pdvs!

  const freeFileLimit = 50
  const freePdvLimit = 10_000_000

  const filesUsageObject = { used: filesUsage, limit: freeFileLimit }
  const pdvUsagePercentage = (pdvUsage / freePdvLimit) * 100
  const plan = data?.data?.plan as Plan
  const subscription = dataSubscriptions?.data?.[0]

  const result = {
    fileUploadIsLockedUntilPayment,
    sheetIsLockedUntilPayment,
    usageBannerProps: {
      filesUsageObject,
      pdvUsagePercentage,
      plan: inGracePeriod ? 'grace-period' : plan,
      billingUrl: `${returnUrl}/billing`,
    },
    subscription,
  }
  return result
}
