import { Space } from '@flatfile/api'
import { Icon } from '@flatfile/design-system'
import { createRef, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { PopoverContext } from '@flatfile/shared-ui'

const UploadIcon = styled(Icon)`
  margin: 0 10px 0 20px;
`

const SpaceName = styled.span`
  display: flex;
  justify-content: center;
`

export const useFileDragAndDrop = (
  onFileUpload: (file: File) => void,
  space: Space
) => {
  const { showPopover, hidePopover } = useContext(PopoverContext)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const dropRef = createRef<HTMLDivElement>()
  const { t } = useTranslation()
  useEffect(() => {
    const cancelDefaults = (e: DragEvent) => {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
    const onDrag = () => {
      setIsDragging(true)
      showPopover({
        icon: <Icon name='upload' />,
        message: (
          <SpaceName>
            {t('files.uploadFile.popovers.onDragPopoverConfirmation')}{' '}
            <UploadIcon name='grid' /> {space.name}
          </SpaceName>
        ),
        onClose: undefined,
      })
    }
    const dropZone = dropRef.current
    const dragEnterListener = (e: DragEvent) => {
      cancelDefaults(e)
      onDrag()
    }

    const dragLeaveListener = (e: DragEvent) => {
      cancelDefaults(e)
      setIsDragging(false)
      hidePopover()
    }

    const dragOverListener = (e: DragEvent) => {
      cancelDefaults(e)
      onDrag()
    }

    const dropListener = (e: DragEvent) => {
      cancelDefaults(e)
      setIsDragging(false)
      hidePopover()
      let dt = e.dataTransfer
      let files = dt?.files
      if (files && files.length > 0) {
        const file = files[0]
        onFileUpload(file)
      }
    }

    if (dropZone) {
      dropZone.addEventListener('dragenter', dragEnterListener)
      dropZone.addEventListener('dragleave', dragLeaveListener)
      dropZone.addEventListener('dragover', dragOverListener)
      dropZone.addEventListener('drop', dropListener)
    }
    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragenter', dragEnterListener)
        dropZone.removeEventListener('dragleave', dragLeaveListener)
        dropZone.removeEventListener('dragover', dragOverListener)
        dropZone.removeEventListener('drop', dropListener)
      }
    }
  }, [dropRef, setIsDragging, onFileUpload, space.name])

  return {
    dropRef,
    isDragging,
  }
}
