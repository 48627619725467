import { Button, getFont } from '@flatfile/design-system'
import { CommandMenuItem } from '../CommandMenuItem/CommandMenuItem'
import { CommandMenuGroup } from '../elements'

interface ICommandMenuMainPanel {
  value: String
  onMenuItemSelection: (itemValue: string) => void
}

export const CommandMenuMainPanel = (props: ICommandMenuMainPanel) => {
  const { value, onMenuItemSelection } = props

  const menuGroups = [
    {
      id: 'build-with-ai',
      label: 'Build with AI',
      color: 'var(--color-jade-900)',
      commandColor: 'var(--color-jade-100)',
      items: [
        { id: '/transform', label: 'ask AI to transform your data' },
        { id: '/query', label: 'ask AI to write a query for you in FFQL' },
      ],
    },
    {
      id: 'commands',
      label: 'Commands',
      color: 'var(--color-electric-900)',
      commandColor: 'var(--color-electric-100)',
      items: [
        { id: '/search', label: 'search the whole sheet' },
        {
          id: '/filter',
          label: (
            <>
              <span>write an FFQL statement</span>
              <Button
                label='learn how'
                onPress={() => onClickLearnHow()}
                variant='ghost'
              />
            </>
          ),
        },
        { id: '/in', label: 'search within a field' },
      ],
    },
  ]

  const onClickLearnHow = () => {
    const font = getFont()
    if (window) {
      window.open(
        'https://flatfile.com/docs/developer-tools/flatfile_query_language',
        '_blank'
      )
    }
  }

  const MenuGroup = ({ color, commandColor, label, items }: any) => (
    <>
      <CommandMenuGroup $colorVar={color} heading={label}>
        {items.map((item: any) => (
          <CommandMenuItem
            onMenuItemSelection={onMenuItemSelection}
            displayCommand={true}
            key={item.id}
            color={color}
            commandColor={commandColor}
            id={item.id}
          >
            {item.label}
          </CommandMenuItem>
        ))}
      </CommandMenuGroup>
    </>
  )

  return (
    <>
      {menuGroups
        .map((group) => ({
          ...group,
          items: group.items.filter((item) => {
            return item.id.startsWith(value.toLowerCase())
          }),
        }))
        .filter((group) => group.items.length > 0)
        .map((group) => (
          <MenuGroup key={group.id} {...group} />
        ))}
    </>
  )
}
