import { Icon } from '@flatfile/design-system'
import { OnSearchValue } from '@flatfile/turntable'
import React, { useEffect, useRef, useState } from 'react'
import { SearchFields } from '../../default-apps/WorkbookApp/hooks/useSearchFilters'
import {
  CommandMenuAiQueryPanel,
  CommandMenuAiTransformPanel,
} from './CommandMenuAiAssist'
import { CommandMenuCommandsPanel } from './CommandMenuCommandsPanel/CommandMenuCommandsPanel'
import { CommandMenuMainPanel } from './CommandMenuMainPanel/CommandMenuMainPanel'
import {
  CommandMenuInput,
  CommandMenuInputWrapper,
  CommandMenuList,
  CommandMenuRoot,
} from './elements'

enum PanelState {
  MainPanel = 'main-panel',
  SearchPanel = 'search-panel',
  AiTransformPanel = 'ai-transform-panel',
  AiQueryPanel = 'ai-query-panel',
  CommandPanel = 'command-panel',
}

export function CommandMenu({
  searchFields,
  selection,
  onSearchByValue,
}: {
  searchFields: SearchFields
  selection: {
    type: 'all' | 'none'
    exceptions: string[]
  }
  onSearchByValue: OnSearchValue
}) {
  // The value of the input
  const [value, setValue] = useState('')

  // The state which determines which panel is displayed
  const [panelState, setPanelState] = useState(PanelState.MainPanel)
  // Is the menu open
  const [open, setOpen] = useState(false)
  const [inputDisabled, setInputDisabled] = useState(false)
  const [activateKeyboardSubmit, setActivateKeyboardSubmit] = useState(false)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const listRef = useRef(null)

  // This is not used right now, so I'm skipping coverage for it.
  /* c8 ignore start */
  const [child, setChild] = useState<any>(null)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (child && child.handleKeyDown) {
      child.handleKeyDown(e)
    }
  }
  /* c8 ignore stop */

  // Open / close menu with cmd+k'
  useEffect(() => {
    const down = (event: KeyboardEvent) => {
      if (event.key === 'k' && event.metaKey) {
        event.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  /**
   * Applies a global blur effect to the spaces-ui main container
   * when modal is opened
   *
   * TODO: Create a repeatable, globally managed blur resource which
   * is more akin to the styled-component approach (i.e. consider a context)
   */
  useEffect(() => {
    const appRoot = document.getElementById('spaces-ui-main')

    if (appRoot && open) {
      appRoot.classList.add('cmd-k-blur')
    } else if (appRoot) {
      appRoot.classList.remove('cmd-k-blur')
    }
  }, [open])

  /**
   * Intakes the input value from the search and evaluates if a
   * command-specific panel should be displayed to facilitate
   * unique behaviors (i.e. Panel to inform user on how to use AI to
   * generate an FFQL query)
   * @param inputValue
   */
  const evaluateCurrentPanel = (inputValue: string) => {
    const aiTransformPanelMatcher = /^\/(transform)/
    const aiQueryPanelMatcher = /^\/(query)/

    const commandsPanelMatcher = /^\/(filter|in|search)/
    const listPanelMatcher = /^[/]/

    switch (true) {
      case aiTransformPanelMatcher.test(inputValue): {
        setPanelState(PanelState.AiTransformPanel)
        break
      }

      case aiQueryPanelMatcher.test(inputValue): {
        setPanelState(PanelState.AiQueryPanel)
        break
      }
      case commandsPanelMatcher.test(inputValue): {
        setPanelState(PanelState.CommandPanel)
        break
      }
      case listPanelMatcher.test(inputValue): {
        setPanelState(PanelState.MainPanel)
        break
      }
      case !inputValue: {
        setPanelState(PanelState.MainPanel)
        break
      }
      default: {
        setPanelState(PanelState.CommandPanel)
        break
      }
    }
  }

  const onValueChange = (value: string) => {
    evaluateCurrentPanel(value)
    setActivateKeyboardSubmit(false)
    setValue(value)
  }

  // If we selected a menu item (e.g. /search), we need to add a space
  // so that the user's typing doesn't get appended to the command
  const onMenuItemSelection = (value: string) => {
    onValueChange(value + ' ')
    inputRef.current!.focus()
  }

  /**
   * Simple handler to give child components the ability to give the input
   * field focus after performing asynchronous actions (i.e. requesting a query)
   */
  const focusInputHandler = () => {
    inputRef.current!.focus()
  }

  /**
   * Handler for keyDown on the Input field to evaluate if the
   * Keyboard-Submit state should be activated
   *
   * @param e KeyboardEvent | event emitted on press of any keyDown
   */
  const activateKeyboardSubmitHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && panelState !== PanelState.MainPanel) {
      setActivateKeyboardSubmit(true)
    }
  }

  return (
    <>
      <div data-testid='command-menu' />
      <CommandMenuRoot
        open={open}
        onOpenChange={setOpen}
        onKeyDown={handleKeyDown}
        shouldFilter={false}
      >
        <CommandMenuInputWrapper>
          <Icon size={16} name='search' color={'var(--color-text-light)'} />
          <CommandMenuInput
            ref={inputRef}
            autoFocus
            placeholder='Find or do anything...'
            value={value}
            onValueChange={onValueChange}
            onKeyDown={activateKeyboardSubmitHandler}
            disabled={inputDisabled}
          />
        </CommandMenuInputWrapper>
        <CommandMenuList ref={listRef}>
          {panelState === PanelState.MainPanel && (
            <CommandMenuMainPanel
              value={value}
              onMenuItemSelection={onMenuItemSelection}
            />
          )}
          {panelState === PanelState.CommandPanel && (
            <CommandMenuCommandsPanel
              dismissHandler={() => setOpen(false)}
              inputValue={value}
              onSearchByValue={onSearchByValue}
              keyboardSubmitActivated={activateKeyboardSubmit}
              deactivateKeyboardSubmit={() => setActivateKeyboardSubmit(false)}
            />
          )}

          {panelState === PanelState.AiTransformPanel && (
            <CommandMenuAiTransformPanel
              value={value}
              onValueChange={onValueChange}
              searchFields={searchFields}
              selection={selection}
              dismissHandler={() => setOpen(false)}
              setInputDisabled={setInputDisabled}
              setInputFocus={focusInputHandler}
              keyboardSubmitActivated={activateKeyboardSubmit}
              deactivateKeyboardSubmit={() => setActivateKeyboardSubmit(false)}
            />
          )}

          {panelState === PanelState.AiQueryPanel && (
            <CommandMenuAiQueryPanel
              value={value}
              onValueChange={onValueChange}
              dismissHandler={() => setOpen(false)}
              setInputDisabled={setInputDisabled}
              setInputFocus={focusInputHandler}
              keyboardSubmitActivated={activateKeyboardSubmit}
              deactivateKeyboardSubmit={() => setActivateKeyboardSubmit(false)}
            />
          )}
        </CommandMenuList>
      </CommandMenuRoot>
    </>
  )
}
