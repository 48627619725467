import { Filter, SortDirection } from '@flatfile/api'
import {
  ColumnConfigProps,
  OnSearchValue,
  OnSort,
  useBulkRowSelection,
} from '@flatfile/turntable'
import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getSortParams } from '../utils/tableUtils'

type SearchParam = (string | undefined)[]

export interface SearchFields {
  sortDirection?: SortDirection
  sortField?: string
  filter?: Filter
  filterField?: string
  searchValue?: string
  searchField?: string
  q?: string
}

export interface UseSearchFiltersProps {
  columnConfig: ColumnConfigProps[]
}

export const useSearchFilters = ({ columnConfig }: UseSearchFiltersProps) => {
  const [search, setSearchParams] = useSearchParams()
  const { exitSelectionState } = useBulkRowSelection()
  const [searchByValueLoading, setSearchByValueLoading] = useState(false)
  const [filteredResultsLoading, setFilteredResultsLoading] = useState(false)

  const searchFields = useMemo(
    () => ({
      sortDirection:
        (search.get('sortDirection') as SortDirection) || undefined,
      sortField: search.get('sortField') || undefined,
      filter: (search.get('filter') as Filter) || undefined,
      filterField: search.get('filterField') || undefined,
      searchValue: search.get('searchValue') || undefined,
      searchField: search.get('searchField') || undefined,
      q: search.get('q') || undefined,
    }),
    [search]
  )

  //TODO: update & remove when turntable allows for initial values
  const sortOverride = useMemo(() => {
    const columnIndex = columnConfig.findIndex(
      (column) => column.value === searchFields.sortField
    )
    if (columnIndex === -1) {
      return { sortOrder: undefined, sortColumn: undefined }
    }
    return {
      sortOrder: searchFields.sortDirection,
      sortColumn: columnIndex,
    }
  }, [search, columnConfig])

  const getParamsChanged = useCallback(
    (params: SearchParam[]): boolean => {
      for (let i = 0; i < params.length; i++) {
        const type = params[i][0]
        const value = params[i][1] ?? ''
        const currentValue = searchFields[type as keyof SearchFields] ?? ''

        if (type && value !== currentValue) {
          return true
        }
      }
      return false
    },
    [searchFields]
  )

  const handleSearchParams = useCallback(
    (searchParams: SearchParam[]) => {
      let updatedSearchParams = new URLSearchParams(search.toString())
      searchParams.map((params: any) => {
        if (!params[1]) {
          return updatedSearchParams.delete(params[0])
        }
        updatedSearchParams.set(params[0], params[1])
      })
      setSearchParams(updatedSearchParams)
    },
    [search]
  )

  const handleClickFilter = useCallback(
    (filter?: Filter) => {
      exitSelectionState(true)
      const params = [['filter', filter]]

      if (filter === Filter.Error || filter === Filter.Valid) {
        /* If clicking away from the All tab, remove any FFQL queries */
        params.push(['q'])
      }

      if (getParamsChanged(params)) {
        handleSearchParams(params)

        if (searchFields.searchValue || searchFields.searchField) {
          setFilteredResultsLoading(true)
        }
      }
    },
    [search]
  )

  const handleClickFilterField = useCallback(
    (errorFilter?: string) => {
      exitSelectionState(true)

      const params = [['filterField', errorFilter], ['q']]

      if (getParamsChanged(params)) {
        handleSearchParams(params)
        setFilteredResultsLoading(true)
      }
    },
    [search]
  )

  const handleSort: OnSort = useCallback(
    ({ columnIndex, order }) => {
      const { sortDirection, sortField } = getSortParams({
        columnConfig,
        columnIndex,
        order,
      })
      const params = [
        ['sortDirection', sortDirection],
        ['sortField', sortField],
      ]
      if (getParamsChanged(params)) {
        handleSearchParams(params)
      }
    },
    [search]
  )

  const handleSearchByValue: OnSearchValue = useCallback(
    ({ searchValue, searchField, q }) => {
      exitSelectionState(true)

      let params = []

      if (q) {
        params = [
          ['q', q],
          ['filter'],
          ['filterField'],
          ['searchValue'],
          ['searchField'],
        ]
      } else {
        params = [
          ['searchField', searchField],
          ['searchValue', searchValue],
          ['q'],
        ]
      }

      if (getParamsChanged(params)) {
        setSearchByValueLoading(true)
        setFilteredResultsLoading(true)
        handleSearchParams(params)
      }
    },
    [search]
  )

  const isFiltered: boolean = useMemo(() => {
    return !!(
      searchFields.filter ||
      searchFields.searchValue ||
      searchFields.searchField ||
      searchFields.filterField ||
      searchFields.q
    )
  }, [searchFields])

  return {
    searchFields,
    sortOverride,
    filteredResultsLoading,
    searchByValueLoading,
    isFiltered,
    setSearchByValueLoading,
    setFilteredResultsLoading,
    handleSearchParams,
    handleClickFilter,
    handleClickFilterField,
    handleSearchByValue,
    handleSort,
  }
}
