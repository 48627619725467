import { Workbook } from '@flatfile/api'
import { useState } from 'react'
import { sortWorkbooks } from '../utils/workbooks'

export const useChecklistView = (workbooks: Workbook[]) => {
  const [selectedWorkbook, setSelectedWorkbook] = useState<
    React.Key | undefined
  >(undefined)
  const [selectedSheet, setSelectedSheet] = useState<React.Key | undefined>(
    undefined
  )
  const hasNonFileWorkbooks = workbooks.some(
    (wb) => !wb.labels?.includes('file')
  )

  // ensure pinned workbooks appear first in dropdown
  const nonFileWorkbooks = sortWorkbooks(workbooks)

  return {
    selectedWorkbook,
    setSelectedWorkbook,
    selectedSheet,
    setSelectedSheet,
    hasNonFileWorkbooks,
    nonFileWorkbooks,
  }
}
