import { Sheet, SheetAccess, Workbook } from '@flatfile/api'
import { useMemo } from 'react'
import { usePaymentAccess } from './usePaymentAccess'

export const useSheetAccess = ({
  currentWorkbook,
  currentSheet,
}: {
  currentWorkbook: Workbook
  currentSheet?: Sheet
}) => {
  const { sheetIsLockedUntilPayment } = usePaymentAccess()
  const isFile = useMemo(
    () => currentWorkbook && currentWorkbook?.labels?.includes('file'),
    [currentWorkbook]
  )

  const noAccessLimitations =
    !currentSheet?.config?.access || currentSheet?.config?.access?.includes('*')

  const checkAccess = (access: SheetAccess) => {
    if (!currentSheet) return true
    return currentSheet?.config?.access?.includes(access) || noAccessLimitations
  }

  const readOnlySheet = useMemo(
    () =>
      (currentSheet && currentSheet?.config?.readonly) ||
      (currentSheet?.config?.access &&
        currentSheet?.config?.access.length == 0) ||
      isFile,
    [isFile, currentSheet]
  )

  const canEditSheet = useMemo(
    () => !readOnlySheet && checkAccess('edit'),
    [currentSheet]
  )

  const canAddToSheet = useMemo(
    () => !readOnlySheet && !sheetIsLockedUntilPayment && checkAccess('add'),
    [currentSheet]
  )

  const canDeleteFromSheet = useMemo(
    () => !readOnlySheet && checkAccess('delete'),
    [currentSheet]
  )

  const canImportIntoSheet = useMemo(
    () => !readOnlySheet && !sheetIsLockedUntilPayment && checkAccess('import'),
    [currentSheet]
  )

  return {
    readOnlySheet,
    canEditSheet,
    canAddToSheet,
    canDeleteFromSheet,
    canImportIntoSheet,
    noAccessLimitations:
      !readOnlySheet && !sheetIsLockedUntilPayment && noAccessLimitations,
    sheetIsLockedUntilPayment,
  }
}
