import { Action, Job, JobStatusEnum } from '@flatfile/api'
import { Icon, Spinner } from '@flatfile/design-system'
import { CheckmarkIcon, Panel, WarningIcon } from '@flatfile/shared-ui'
import { format } from 'date-fns'
import { useContext } from 'react'
import styled from 'styled-components'
import { FileController } from '../api/controllers/FileController'
import { useController } from '../api/controllers/useController'
import { useResource } from '../api/resources'
import { guard } from '../api/resources/GuardResources'
import { JobsContext } from '../contexts/JobsContext'
import { SpaceContext } from '../contexts/SpaceContext'
import { ActionLink } from '../elements/ActionLink'
import { TruncatedSpan } from '../elements/TruncatedSpan'
import { useCancelJob } from '../hooks/useCancelJob'
import { differenceInTime } from '../utils/differenceInTime'
import { downloadFile } from '../utils/downloadFile'
import { Platform } from './HttpClient'

export const CancelledIcon = styled(Icon)`
  align-self: center;
  stroke: var(--color-danger);
`

type StringValueOf<T> = T[keyof T] & string

const JobsContainer = styled.div`
  overflow-y: auto;
  height: inherit;
  padding: 0 24px;
`

const EmptyJobsContainer = styled(JobsContainer)`
  color: var(--color-text-ultralight);
  font-style: italic;
  font-weight: 400;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-page-200);
`

const DateContainer = styled.div`
  padding: 16px 33px;
`

export const JobsPanel = ({ onClose }: { onClose: () => void }) => {
  const { space } = useContext(SpaceContext)
  const { activeJobs } = useContext(JobsContext)
  const jobs = activeJobs?.sort((a, b) => {
    return b.createdAt.getTime() - a.createdAt.getTime()
  })
  const groupedJobsByDate = jobs?.reduce((acc, job) => {
    const date = format(job.createdAt, 'MMM dd, yyyy')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(job)
    return acc
  }, {} as { [key: string]: Job[] })

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    Platform
  )

  // todo: can this be abstracted into the job configuration
  const handleDownload = (fileId: string) => {
    return fileController.downloadFile({ fileId: fileId }).then((data) => {
      downloadFile(data)
    })
  }
  return (
    <Panel heading='Jobs' onClose={onClose}>
      <JobsContainer>
        {jobs?.length === 0 && (
          <div data-testid='jobs-empty-state'>
            Jobs will appear here when they're running or completed.
          </div>
        )}
        {Object.entries(groupedJobsByDate ?? []).map(([date, jobsGroup]) => (
          <div key={date}>
            <DateContainer>{date}</DateContainer>
            <Divider />
            {jobsGroup.map((job) => (
              <JobDetail
                key={job.id}
                job={job}
                spaceId={space.id}
                handleDownload={handleDownload}
              />
            ))}
          </div>
        ))}
      </JobsContainer>
    </Panel>
  )
}

const JobStatusContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
`

const JobStatusContent = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
`

const JobInfo = styled.div`
  display: flex;
  flex-direction: column;

  //hardcoded to make space for one action button
  padding-right: 24px;

  .job-status {
    .emphasized {
      font-weight: 600;
    }
  }

  .job-data {
    font-size: var(--size-small);
    font-weight: 600;
    height: 20px;
  }

  .job-times {
    font-size: var(--size-small);
  }

  .job-starttime {
    margin-right: 8px;
  }
`

const IconWrapper = styled.div`
  width: 25px;
`

const DownloadLink = styled.a`
  color: var(--color-action);
  cursor: pointer;
`

const AlignRight = styled.div`
  text-align: right;
`

const JobActionIcons = styled.div`
  position: absolute;
  top: 14px;
  right: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
`

const builtinActionNames = ['delete-records', 'extract', 'map', 'export']
const builtinStatusLines = {
  'delete-records': {
    running: <span className='emphasized'>Deleting records</span>,
    failed: <span className='emphasized'>Failed to delete records</span>,
    complete: <span className='emphasized'>Deleted records</span>,
    canceled: <span className='emphasized'>Cancelled record deletion</span>,
  },
  extract: {
    running: <span className='emphasized'>Extracting file</span>,
    failed: <span className='emphasized'>Failed to extract file</span>,
    complete: <span className='emphasized'>Extracted file</span>,
    canceled: <span className='emphasized'>Cancelled file extraction</span>,
  },
  map: {
    running: <span className='emphasized'>Mapping to workbook</span>,
    failed: <span className='emphasized'>Failed to map to workbook</span>,
    complete: <span className='emphasized'>Mapped to workbook</span>,
    canceled: <span className='emphasized'>Cancelled mapping to workbook</span>,
  },
  export: {
    running: <span className='emphasized'>Generating download</span>,
    failed: <span className='emphasized'>Failed to generate download</span>,
    complete: <span className='emphasized'>Download generated</span>,
    canceled: <span className='emphasized'>Download cancelled</span>,
  },
}

const customStatusLines = (operation: string) => ({
  running: (
    <span>
      Running job <span className='emphasized'>{operation}</span>
    </span>
  ),
  failed: (
    <span>
      Job <span className='emphasized'>{operation}</span> failed
    </span>
  ),
  complete: (
    <span>
      Job <span className='emphasized'>{operation}</span> completed
    </span>
  ),
  canceled: (
    <span>
      Job <span className='emphasized'>{operation}</span> cancelled
    </span>
  ),
})

const statusCases: {
  [k in StringValueOf<typeof JobStatusEnum>]:
    | 'complete'
    | 'failed'
    | 'running'
    | 'canceled'
} = {
  created: 'running',
  planning: 'running',
  scheduled: 'running',
  ready: 'running',
  executing: 'running',
  complete: 'complete',
  failed: 'failed',
  canceled: 'canceled',
}

const icons = {
  complete: <CheckmarkIcon data-testid='success' name='checkmark' />,
  failed: <WarningIcon data-testid='failure' name='alertTriangle' />,
  running: <Spinner color='var(--color-primary)' />,
  canceled: <CancelledIcon data-testid='cancel' name='ban' />,
}

export const JobDetail = ({
  job,
  spaceId,
  handleDownload,
}: {
  job: Job
  spaceId: string
  handleDownload: (fileId: string) => Promise<void>
}) => {
  const associatedResourceId =
    (job.operation == 'map' || job.operation === 'export') && job.destination
      ? job.destination
      : job.source

  const [_, resourceObservable] = useResource<{
    id: string
    name: string
    updatedAt: Date
  }>(associatedResourceId, false)

  const actions =
    (resourceObservable.data as any)?.actions ||
    (resourceObservable.data as any)?.config?.actions ||
    []

  const actionName: string =
    actions.find((action: Action) => action.operation === job.operation)
      ?.label ?? job.operation!

  const statusCase = statusCases[job.status!]
  const icon = icons[statusCase]

  const resourceName = guard(resourceObservable, {
    loadingContent: <code>{job.source}</code>,
    errorContent: () => <div>Not found</div>,
  }) ?? <TruncatedSpan text={resourceObservable.ensured.data.name ?? ''} />

  const fallbackStatusLine = customStatusLines(actionName)[statusCase]
  const statusLine =
    job.operation && builtinActionNames.includes(job.operation)
      ? builtinStatusLines[job.operation as keyof typeof builtinStatusLines][
          statusCase
        ]
      : fallbackStatusLine

  const action = actionForJob(job, spaceId)

  const { cancelJob } = useCancelJob()

  return (
    <div key={job.id}>
      <JobStatusContainer>
        <JobStatusContent>
          <IconWrapper>{icon}</IconWrapper>
          <JobInfo>
            <span data-testid='job-status' className='job-status'>
              {job.status == 'executing' &&
                job.progress !== undefined &&
                job.progress > 0 && (
                  <span data-testid='progress'>{`${job.progress}% `}</span>
                )}
              {statusLine}
            </span>
            <div className='job-times'>
              <span className='job-starttime'>
                {(job.startedAt || job.createdAt) &&
                  'Started ' +
                    format(job.startedAt || job.createdAt, 'h:mm:ss aa')}
              </span>
              <span>
                {job.startedAt &&
                  job.finishedAt &&
                  (job.status == 'failed' ? 'Failed after ' : 'Completed in ') +
                    differenceInTime(job.startedAt, job.finishedAt)}
              </span>
            </div>
            <div className='job-data'>{resourceName}</div>
            {job.operation === 'export' && job.fileId && (
              <div>
                <DownloadLink onClick={() => handleDownload(job.fileId!)}>
                  Download
                </DownloadLink>
              </div>
            )}
            {action && resourceObservable.data && (
              <div>
                <ActionLink data-testid='action-link' to={action?.to}>
                  {action?.label /*?? 'Go to resource'*/}
                </ActionLink>
              </div>
            )}
          </JobInfo>
          <JobActionIcons>
            {job.status === 'ready' && (
              <Icon
                onClick={() => cancelJob(job.id)}
                data-testid='close'
                name='xCircle'
                style={{ color: 'var(--color-danger)', cursor: 'pointer' }}
              />
            )}
          </JobActionIcons>
        </JobStatusContent>
      </JobStatusContainer>

      <Divider />
    </div>
  )
}

/**
 * todo: make this part of the jobs API
 */
export const actionForJob = (
  job: Job,
  spaceId: string
): { label: string; to: string } | undefined => {
  if (job.status != 'complete') return undefined
  switch (job.operation) {
    case 'map':
      return {
        label: 'Go to workbook',
        to: `/space/${spaceId}/workbook/${job.destination}`,
      }
    case 'extract':
      return {
        label: 'Import',
        to: `/space/${spaceId}/files/${job.source}/import`,
      }
    case 'delete-records':
    default:
      return undefined
  }
}
