import styled from 'styled-components'
import { truncated } from '@flatfile/design-system'
import { TabStripOuter, TabStripInner } from '../../elements/TabStrip'

export const SheetContainer = styled.div`
  position: relative;
  height: 100vh;
  max-height: 100vh;
  max-width: none !important;
  background-color: var(--color-page);

  display: flex;
  flex-direction: column;
`

export const SheetTableContainer = styled.div`
  flex: 1;
  position: relative;
`

export const SheetHeaderContainer = styled.div`
  padding-top: var(--spacing-content-gutter);
  padding-left: var(--spacing-content-gutter);
  padding-right: var(--spacing-content-gutter);
`

export const SheetTabsContainer = styled.div`
  ${TabStripOuter} {
    margin-bottom: 0px;
  }
  ${TabStripInner} {
    padding-left: var(--spacing-content-gutter);
    padding-right: var(--spacing-content-gutter);
  }
`

export const SheetTabName = styled.span`
  ${truncated}
`

export const SheetTabIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 4px;
`

export const PrimaryActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
