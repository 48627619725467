import { useMemo } from 'react'
import { Rule } from '../../../../api/controllers/RuleController'
import { SheetController } from '../../../../api/controllers/SheetController'
import { PlanController } from '../../../../api/controllers/PlanController'
import { useController } from '../../../../api/controllers/useController'
import { updateObservable, useAction } from '../../../../api/observable'
import { MappingSidebar } from '../../../../elements/MappingComponents'
import { StatusText } from '../../../../elements/StatusText'
import { useEventCallbacks } from '../../../../hooks/useEventCallbacks'
import { EnumCard } from '../components/EnumCard'
import { useTranslation } from 'react-i18next'

export const MapEnumsPage = ({
  controller,
  onFocus,
}: {
  controller: PlanController
  onFocus?: (rule: Rule) => void
}) => {
  const [updateRule] = useAction(controller.updateRule(), (data) => {
    updateObservable('plan', data)
  })
  const source = useController(
    SheetController,
    controller.sourceSheet,
    controller.job
  )
  const { t } = useTranslation()

  const rules = useMemo(
    () => controller.getRules({ enum: true }),
    [controller.plan]
  )

  const eventHandlers = useEventCallbacks('EnumCard', {
    onFocus(rule: Rule) {
      onFocus?.(rule)
    },
    onChange(rule: Rule) {
      updateRule({ rule })
    },
  })

  return (
    <MappingSidebar>
      <StatusText>{t('mapping.mapEnums.subheading')}</StatusText>
      {rules.map((rule: Rule, index) => {
        return (
          <EnumCard
            key={`field-card-${index}`}
            rule={rule}
            planController={controller}
            {...eventHandlers}
          />
        )
      })}
    </MappingSidebar>
  )
}
