import { Platform } from '../../packages/HttpClient'
import { Event, Guest, Job, Sheet, Space, User, Workbook } from '@flatfile/api'

type File = any
export type Response<T> = Promise<{ data?: T }>

export const fetchers = {
  ev: (eventId: string) => Platform.getEvent({ eventId }) as Response<Event>,
  fl: (fileId: string) => Platform.getFile({ fileId }) as Response<File>,
  sh: (sheetId: string) => Platform.getSheet({ sheetId }) as Response<Sheet>,
  ge: (guestId: string) =>
    Platform.getSpaceGuestById({ guestId }) as Response<Guest>,
  sp: (spaceId: string) =>
    Platform.getSpaceById({ spaceId }) as Response<Space>,
  usr: (userId: string) => Platform.getUser({ userId }) as Response<User>,
  jb: (jobId: string) => Platform.getJob({ jobId }) as Response<Job>,
  wb: (workbookId: string) =>
    Platform.getWorkbookById({ workbookId }) as Response<Workbook>,
}

/**
 * Helper utility for getting the resource from the ID
 * @param id
 */
export function resourceFetcherFromId<T extends string>(
  id: T
): (typeof fetchers)[keyof typeof fetchers] {
  if (!id) {
    return () => Promise.reject(new Error('No ID provided')) as Response<any>
  }
  const type = id.split('_')[1]
  if (!(fetchers as any)[type]) {
    return () =>
      Promise.reject(
        new Error(`"${id}" is not a recognized resource type.`)
      ) as Response<any>
  }
  return fetchers[type as keyof typeof fetchers]
}

export function getTargetIdFromEvent(
  entity: string,
  event: any
): string | null {
  return (
    event.target || event.origin?.id || event.context?.[`${entity}Id`] || null
  )
}
