import { EventTopic, JobTriggerEnum, JobTypeEnum } from '@flatfile/api'
import { Pagination, useListPagination } from '@flatfile/design-system'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FILES_PAGE_SIZE,
  FileController,
  Files,
} from '../../api/controllers/FileController'
import { useController } from '../../api/controllers/useController'
import { Observable, useAction, useObservable } from '../../api/observable'
import { guard } from '../../api/resources/GuardResources'
import { JobsContext } from '../../contexts/JobsContext'
import { SpaceContext } from '../../contexts/SpaceContext'
import { ErrorState } from '../../elements/EmptyState'
import { MainContent } from '../../elements/MainContent'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useEventSubscriber } from '../../hooks/useEventSubscriber'
import { downloadFile } from '../../utils/downloadFile'
import { FileInfo, FileMode } from './types'
import { FilesHeader } from './components/FilesHeader'
import { FilesEmptyState } from './components/FilesEmptyState'
import { FilesLoadingState } from './components/FilesLoadingState'
import { FilesTable } from './components/FilesTable'
import { DeleteFilesModal } from './components/DeleteFilesModal'

export const FilesView: FC = () => {
  const { t } = useTranslation()
  const [filesToDelete, setFilesToDelete] = useState<FileInfo[]>([])
  const [selectedFiles, setSelectedFiles] = useState<FileInfo[]>([])
  const [search] = useSearchParams()

  const getValidParam = (param: string | null): FileMode => {
    if (param && Object.keys(fileModeObject).includes(param))
      return param as FileMode
    return 'import'
  }

  const fileModeObject: { [k in FileMode]: string } = {
    import: t('files.uploadedFilesTab'),
    export: t('files.availableDownloadsTab'),
  }

  const [fileMode, setFileMode] = useState<FileMode>(
    getValidParam(search.get('mode'))
  )

  const { space, sidebarCollapsed, httpClient } = useContext(SpaceContext)
  const { activeJobs } = useContext(JobsContext)

  const fileController = useController(
    FileController,
    space.id,
    space.environmentId,
    httpClient
  )
  const { currentPage, onChangePage, setCurrentPage } = useListPagination()
  useDocumentTitle('Files', space.name)
  const [request, filesResponse] = useObservable(fileController.getFiles(), {
    pageNumber: currentPage,
    mode: fileMode,
    pageSize: FILES_PAGE_SIZE,
  })

  const fileUrl = `/space/${space.id}/files`

  useEffect(() => {
    sidebarCollapsed?.set(false)
    if (search.get('mode') !== fileMode) request.refetch()
  }, [])

  useEffect(() => {
    request.refetch()
  }, [currentPage])

  useEffect(() => {
    const searchParam = getValidParam(search.get('mode'))
    if (searchParam === fileMode) return
    setFileMode(searchParam)
  }, [search])

  useLayoutEffect(() => {
    request.load()
    setSelectedFiles([])
    setFilesToDelete([])
    setCurrentPage(1)
  }, [fileMode])

  const [onClickDelete, deleteAction] = useAction(
    fileController.deleteFiles(),
    async () => {
      setFilesToDelete([])
      setSelectedFiles([])
      if (
        filesResponse?.files &&
        filesResponse.files.length - 1 <= 0 &&
        filesResponse?.pagination &&
        filesResponse.pagination.currentPage !== 1
      ) {
        setCurrentPage(filesResponse?.pagination.currentPage - 1)
      }
      await request.refetch()
    },
    'deleteFile'
  )

  const handleDelete = useCallback(
    () =>
      onClickDelete({
        fileIds: filesToDelete.map((f) => f.fileId),
      }),
    [filesToDelete]
  )

  const [onClickDownload] = useAction(
    fileController.downloadFiles(),
    (data) => {
      data.forEach((data) => {
        downloadFile(data)
      })
    },
    'downloadFile'
  )

  const onClickCustomAction = (operation: string, fileId: string) => async () =>
    await httpClient.createJob({
      jobConfig: {
        type: JobTypeEnum.File,
        operation: operation,
        trigger: JobTriggerEnum.Immediate,
        source: fileId,
      },
    })

  useEventSubscriber(
    [EventTopic.Filecreated, EventTopic.Filedeleted, EventTopic.Fileupdated],
    (event) => {
      handleFileEvents({
        event,
        spaceId: space.id,
        filesResponse,
        request,
      })
    }
  )

  return (
    <MainContent>
      <FilesHeader
        space={space}
        fileMode={fileMode}
        fileUrl={fileUrl}
        filesResponse={filesResponse}
        selectedFiles={selectedFiles}
        setFilesToDelete={setFilesToDelete}
        onClickDownload={onClickDownload}
        isLoading={request.isLoading}
        fileModeObject={fileModeObject}
      />

      {guard(request, {
        emptyContent: <FilesEmptyState fileMode={fileMode} space={space} />,
        errorContent: () => (
          <ErrorState title={t('files.errors.loadingError')} />
        ),
        loadingContent: <FilesLoadingState />,
        contentKey: 'files',
      }) ?? (
        <>
          {!!filesToDelete.length && (
            <DeleteFilesModal
              files={filesToDelete}
              mode={fileMode}
              loading={deleteAction.isLoading}
              onClose={() => setFilesToDelete([])}
              onConfirm={handleDelete}
            />
          )}
          <FilesTable
            fileMode={fileMode}
            filesResponse={filesResponse}
            activeJobs={activeJobs}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            setFilesToDelete={setFilesToDelete}
            onClickDownload={onClickDownload}
            onClickCustomAction={onClickCustomAction}
          />
          {filesResponse?.pagination && (
            <Pagination
              onPageChange={onChangePage}
              totalCount={filesResponse?.pagination?.totalCount}
              currentPage={currentPage}
              pageSize={FILES_PAGE_SIZE}
            />
          )}
        </>
      )}
    </MainContent>
  )
}

export const handleFileEvents = ({
  event,
  spaceId,
  filesResponse,
  request,
}: {
  event: any
  spaceId: string
  filesResponse: Files | undefined
  request: Observable<Files, any>
}) => {
  const eventResponse = JSON.parse(event.message)
  const { context } = eventResponse
  const currentSpace = context?.spaceId === spaceId
  const fileInView = filesResponse?.files?.some(
    (file) => file?.id === context?.fileId
  )
  if (
    currentSpace &&
    ((eventResponse.topic === EventTopic.Filecreated && !fileInView) ||
      (eventResponse.topic === EventTopic.Filedeleted &&
        (!filesResponse || fileInView)) ||
      eventResponse.topic == EventTopic.Fileupdated)
  ) {
    request.refetch()
  }
}
