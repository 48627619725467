import { storage } from '@flatfile/shared-ui'

const shortCodeMap = {
  r: 'returnUrl',
  t: 'accessToken',
}

interface Runtime {
  returnUrl: string
  accessToken: string
  isGuest: boolean
}

const runtimeStorageKey = ':runtime'

export function resetRuntimeParameters() {
  storage(runtimeStorageKey).clear()
}

export function RuntimeParameters(): Runtime {
  let guestToken: string | undefined = undefined

  const search = window?.location.search

  const unconsumedHashParams: string[] = []

  const storedRuntimeParameters = JSON.parse(
    localStorage.getItem(runtimeStorageKey) ?? '{}'
  )

  if (search.substring(0, 7) === '?token=') {
    guestToken = search.substring(7)
  }

  const runtime = Object.assign(
    {
      returnUrl: '',
      accessToken: '',
    },
    storedRuntimeParameters,
    Object.fromEntries(
      window?.location.hash
        .slice(1)
        .split('&')
        .map((x) => x.split(/=(.*)/s, 2))
        .map(([k, v]) => {
          if (k in shortCodeMap) {
            return [
              shortCodeMap[k as keyof typeof shortCodeMap],
              decodeURIComponent(v),
            ]
          } else {
            if (k.length > 0) {
              unconsumedHashParams.push(`${k}=${v}`)
            }
            return []
          }
        })
        .filter(([k]) => k)
    )
  )

  if (guestToken) {
    runtime.accessToken = guestToken
  }

  runtime.isGuest = checkTokenIsGuest(runtime.accessToken)

  storage(runtimeStorageKey).set(JSON.stringify(runtime))

  location.hash = `#${unconsumedHashParams.join('&')}`
  return runtime
}

export function setRuntimeToken(token: string) {
  const runtime = RuntimeParameters()
  runtime.accessToken = token
  runtime.isGuest = checkTokenIsGuest(token)
  storage(runtimeStorageKey).set(JSON.stringify(runtime))
}

const checkTokenIsGuest = (token: string) => {
  try {
    const decodedToken = decodeToken(token)
    return decodedToken.sub.includes('_g_')
  } catch (e) {
    return false
  }
}

const decodeToken = (token: string) => {
  if (token) {
    try {
      return JSON.parse(atob(token?.split('.')[1]))
    } catch (e) {
      return undefined
    }
  }
  return undefined
}

export const hasExpired = (token: string) => {
  const decodedToken = decodeToken(token)
  const currentTime = Math.floor(Date.now() / 1000)
  if (decodedToken && currentTime > decodedToken.exp) {
    return true
  }
  return false
}
