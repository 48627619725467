import { Button, Icon, Typography } from '@flatfile/design-system'
import styled, { css } from 'styled-components'

const largeButtonStyles = css`
  button {
    height: 40px;
    padding: 12px 20px;
  }
`

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-page-100);
  padding: 24px;
  gap: 16px;
  margin-bottom: 24px;
`

const BannerButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${largeButtonStyles}
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;

  ${largeButtonStyles}
`

const onClickLearnMore = () => {
  const infoUrl = 'https://flatfile.com/docs/core-paths/collaborate'
  window.open(infoUrl, '_blank')
}

const onContactSales = () => {
  const contactUrl = 'https://flatfile.com/contact-us'
  window.location.href = contactUrl
}

export const UpgradeBanner = ({
  canEnableProject,
  onClickEnableProject,
}: {
  canEnableProject: boolean
  onClickEnableProject: () => void
}) => {
  return (
    <BannerContainer data-testid='upgrade-banner'>
      <Icon name='userGroup' size={24} />
      <Typography type='h3Serif'>
        Enjoy a zero trust collaborative Project
      </Typography>
      <Typography type='b0' color='var(--color-text-light)'>
        Upgrade this Space to a Project to be able to invite more than one guest
        to collaborate with you.
      </Typography>
      <BannerButtons>
        {canEnableProject ? (
          <Button
            size='lg'
            label='Enable Project'
            onPress={onClickEnableProject}
          />
        ) : (
          <Button
            size='lg'
            label='Contact sales'
            iconLeft='mail'
            onPress={onContactSales}
          />
        )}
        <Button
          size='lg'
          variant='ghost'
          label='Learn more'
          onPress={onClickLearnMore}
        />
      </BannerButtons>
    </BannerContainer>
  )
}

export const EndProjectFooter = ({
  onClickEndProject,
}: {
  onClickEndProject: () => void
}) => {
  return (
    <FooterContainer data-testid='end-project-footer'>
      <Button
        size='lg'
        variant='warning'
        label='End Project'
        onPress={onClickEndProject}
      />
      <Typography type='b1' color='var(--color-text-light)'>
        When you end a Project, all guests invited to the Space will no longer
        have access to the Space. This is not a reversible action.
      </Typography>
    </FooterContainer>
  )
}
