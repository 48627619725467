import { NavLink } from 'react-router-dom'
import { Space } from '@flatfile/api'
import { Button, getFont } from '@flatfile/design-system'
import { UsageBanner } from '@flatfile/shared-ui'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import styled from 'styled-components'

import { FileInfo, FileMode, FileModeObject } from '../types'
import {
  DownloadFileProps,
  Files,
  FILES_PAGE_SIZE,
} from '../../../api/controllers/FileController'
import { usePaymentAccess } from '../../../hooks/usePaymentAccess'
import { ButtonRow } from '../../../elements/ButtonRow'
import { EmptyWorkbookAlert } from '../../../elements/EmptyWorkbookAlert'
import { HeadingRow, PrimaryHeading } from '../../../elements/PrimaryHeading'
import { TabStrip } from '../../../elements/TabStrip'
import { VerticalDivider } from '../../../elements/VerticalDivider'

import { FileUploadButton } from './FileUploadButton'

const FilesCount = styled.div`
  ${getFont('b1')}
  flex-grow: 1;
  text-align: right;
  color: var(--color-text-light);
`

const FilesTabStrip = ({
  fileUrl,
  fileMode,
  fileModeObject,
}: {
  fileUrl: string
  fileMode: string
  fileModeObject: FileModeObject
}) => {
  return (
    <TabStrip>
      {Object.entries(fileModeObject).map((mode) => (
        <NavLink
          className={(isActive) =>
            fileMode === mode[0] && isActive ? 'active' : undefined
          }
          key={mode[0]}
          to={`${fileUrl}?mode=${mode[0]}`}
        >
          {mode[1]}
        </NavLink>
      ))}
    </TabStrip>
  )
}

export const FilesHeader = ({
  fileMode,
  fileUrl,
  filesResponse,
  fileModeObject,
  selectedFiles,
  setFilesToDelete,
  onClickDownload,
  space,
  isLoading,
}: {
  fileMode: FileMode
  fileUrl: string
  filesResponse: Files
  fileModeObject: FileModeObject
  selectedFiles: FileInfo[]
  setFilesToDelete: React.Dispatch<React.SetStateAction<FileInfo[]>>
  onClickDownload: (props?: DownloadFileProps[] | undefined) => void
  space: Space
  isLoading: boolean
}) => {
  const { usageBannerProps } = usePaymentAccess()
  const { t } = useTranslation()

  const filesCount = useMemo(() => {
    if (!filesResponse?.pagination?.totalCount || !filesResponse?.files) return

    const current = filesResponse?.files.length // Files currently in view
    const total = filesResponse?.pagination.totalCount // Total number of files
    const page = filesResponse?.pagination.currentPage // Current page -- starts at 1
    const offset = (page - 1) * FILES_PAGE_SIZE

    return t('files.counts', {
      start: offset + 1,
      end: offset + current,
      total,
    })
  }, [filesResponse])

  return (
    <>
      <HeadingRow>
        <PrimaryHeading>{t('files.title')}</PrimaryHeading>
      </HeadingRow>

      <EmptyWorkbookAlert />
      <UsageBanner {...usageBannerProps} />

      <FilesTabStrip
        fileMode={fileMode}
        fileUrl={fileUrl}
        fileModeObject={fileModeObject}
      />

      <ButtonRow>
        <Button
          variant='tertiary'
          iconLeft='trash'
          onPress={() => setFilesToDelete(selectedFiles)}
          label={t('files.buttons.delete')}
          isDisabled={selectedFiles.length < 1}
          size='sm'
        />
        <Button
          variant='tertiary'
          iconLeft='download'
          onPress={() => onClickDownload(selectedFiles)}
          label={t('files.buttons.download')}
          isDisabled={selectedFiles.length < 1}
          size='sm'
        />
        {fileMode === 'import' && (
          <>
            <VerticalDivider />
            <FileUploadButton
              space={space}
              variant='tertiary'
              isDisabled={isLoading}
            />
          </>
        )}
        {filesCount ? <FilesCount>{filesCount}</FilesCount> : null}
      </ButtonRow>
    </>
  )
}
