import { datadogLogs } from '@datadog/browser-logs'
import { AMPWrapper } from '@flatfile/design-system'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ChecklistView } from './default-apps/ChecklistApp/ChecklistView'
import { FilesView } from './default-apps/FilesApp/FilesView'
import { ImportFileView } from './default-apps/FilesApp/ImportFileView'
import { JobRouter } from './default-apps/JobsApp/JobRouter'
import { ManageUsersRoute } from './default-apps/ManageUsersApp/ManageUsersView'
import { SecretsView } from './default-apps/SecretsApp/SecretsView'
import { SpaceLoader } from './default-apps/SpaceApp'
import { SpaceRouteRedirector } from './default-apps/SpaceApp/SpaceRouteRedirector'
import { SheetRoute } from './default-apps/WorkbookApp/SheetRoute'
import { WorkbookRouteRedirector } from './default-apps/WorkbookApp/WorkbookRouteRedirector'
import { ErrorState } from './elements/EmptyState'
import { DocumentView } from './packages/DocumentView'
import { I18nextProvider } from 'react-i18next'
import { i18n } from '@flatfile/localization'
import { ResourceRedirector } from './packages/Resources/ResourceRedirector'

if (import.meta.env.VITE_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.VITE_DATADOG_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'spaces',
    env: import.meta.env.VITE_FLATFILE_ENV ?? 'test',
    version: import.meta.env.VITE_VERSION_NUMBER,
  })
}

if (import.meta.env.VITE_VERSION_NUMBER) {
  console.log(`Flatfile version ${import.meta.env.VITE_VERSION_NUMBER}`)
}

export async function main() {
  const hostElement = document?.getElementById('spaces-ui-main')!
  const root = createRoot(hostElement)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })
  const routes = (
    <Routes>
      <Route path='/resource/:resourceId' element={<ResourceRedirector />} />
      <Route path='/space/:spaceId' element={<SpaceLoader />}>
        <Route path='checklist' element={<ChecklistView />} />
        <Route path='manage' element={<ManageUsersRoute />} />
        <Route path='files/'>
          <Route path='' element={<FilesView />} />
          <Route path=':fileId' element={<FilesView />} />
          <Route path=':fileId/import' element={<ImportFileView />} />
        </Route>
        <Route path='job/:jobId' element={<JobRouter />} />
        <Route path='document/:documentId' element={<DocumentView />} />
        <Route path='workbook/:workbookId'>
          <Route path='' element={<WorkbookRouteRedirector />} />
          <Route path='sheet/:sheetId' element={<SheetRoute />} />
          <Route path='*' element={<ErrorState title='Workbook not found' />} />
        </Route>
        <Route path='secrets/' element={<SecretsView />} />
        <Route path='' element={<SpaceRouteRedirector />} />
        <Route path='*' element={<ErrorState title='Space not found' />} />
      </Route>
      <Route path='*' element={<ErrorState title='Not found' />} />
    </Routes>
  )

  root.render(
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <AMPWrapper>
              {routes}
              <ReactQueryDevtools initialIsOpen={false} />
            </AMPWrapper>
          </I18nextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  )
}

main().then()
