import styled from 'styled-components'
import Flatfile from '../../resources/brand/flatfile-full.svg'
import { Icon } from '@flatfile/design-system'
import { useTranslation } from 'react-i18next'

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-page-gutter);
  height: 100%;
  width: 100%;
  background-color: var(--color-page-dark);
`
const ContentContainer = styled.div`
  margin-top: var(--spacing-page-gutter);
  background-color: #ffffff;
  padding: var(--spacing-actions-gutter);
  width: 500px;
  border-radius: var(--layout-border-radius);
`

const Content = styled.div`
  margin-top: 1em;
  color: var(--color-text);
  .email {
    font-weight: 600;
    background-color: var(--color-electric-100);
    padding: 2px 6px;
  }
`

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--color-text-ultralight);
  font-weight: 600;
  font-size: var(--size-h1);
`

const Logo = styled.img`
  height: 32px;
`

const LinkIcon = styled(Icon)`
  color: var(--color-border-strong);
`

const Subheading = styled.div`
  margin-top: var(--spacing-actions-gutter);
  display: flex;
  justify-content: center;
  gap: 10px;
  color: var(--color-text-inverted);
`

const FinePrint = styled.p`
  font-size: var(--size-small);
  color: var(--color-text-light);
`

export const TokenExpirationPage = ({ email }: { email: string }) => {
  const { t } = useTranslation()
  return (
    <PageWrapper>
      <Logo alt='Flatfile' src={Flatfile} />
      <ContentContainer>
        <ContentHeader>
          <span>{t('guestInvite.getNewAccessLink.heading')}</span>
          <LinkIcon name='link' />
        </ContentHeader>
        <Content>
          <p>
            {t('guestInvite.getNewAccessLink.content')}{' '}
            <span className='email'>{email}</span>
          </p>
          <FinePrint>{t('guestInvite.getNewAccessLink.finePrint')}</FinePrint>
        </Content>
      </ContentContainer>
      <Subheading>
        <Icon name='checkmarkShield' />
        Secured by Flatfile
      </Subheading>
    </PageWrapper>
  )
}
