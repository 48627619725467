import {
  GeneratedMutationResponse,
  GeneratedQueryResponse,
  JobResponse,
} from '@flatfile/api'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { SearchFields } from '../default-apps/WorkbookApp/hooks/useSearchFilters'
import { RuntimeParameters } from '../packages/RuntimeParameters'

type MutationWithId = {
  mutateRecord: string
  mutationId?: string
  searchFields?: SearchFields
  selection?: {
    type: 'all' | 'none'
    exceptions: string[]
  }
}

// The assistant can do three things:
// 1. generate a mutation, by hitting the /assistant/generate-mutation endpoint
// 2. apply a mutation, by hitting the /jobs endpoint
// 3. generate a ffql query, by hitting the /assistant/generate-query endpoint
export const useAssistant = () => {
  const { accessToken } = RuntimeParameters()
  const { sheetId = 'sheetId-unset' } = useParams()

  // Here is all the logic related to generating a mutateRecord function.
  const {
    mutate: generateMutation,
    isError: mutationIsError,
    isSuccess: mutationIsSuccess,
    data: mutationResponse,
  } = useMutation({
    mutationFn: async (command: string): Promise<GeneratedMutationResponse> => {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }
      const config = {
        sheetId,
        command,
        model: 'chatgpt',
        modelParams: {
          modelName: 'gpt-4',
        },
      }

      return axios
        .post(
          `${import.meta.env.VITE_BASE_API_URL}/assistant/generate-mutation`,
          config,
          { headers }
        )
        .then((response) => response.data)
    },
  })

  // Here is all the logic related to applying a generated mutateRecord function.
  const {
    mutate: applyMutation,
    isError: applyIsError,
    isSuccess: applyIsSuccess,
    data: applyResponse,
  } = useMutation({
    mutationFn: async (mwid: MutationWithId): Promise<JobResponse> => {
      const { mutateRecord, mutationId, searchFields, selection } = mwid

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }

      const config = {
        sheet: sheetId,
        mutateRecord,
        mutationId,
        ...searchFields,
      } as unknown as any

      // If we have selected rows, set the ids to the row ids
      if (selection && selection.exceptions.length > 0) {
        config['ids'] = selection.exceptions
      }

      // If the selection is type None, the ids are a whitelist of rows to apply the mutation to.
      // That means that we need to remove all the non-id filtering q params from the config, otherwise the backend
      // will treat the ids as a blacklist of rows to exclude. (If the selection is type All, the ids are
      // a blacklist of rows to exclude, so we don't need to do anything.)
      if (
        selection &&
        selection.type === 'none' &&
        selection.exceptions.length > 0
      ) {
        delete config['filter']
        delete config['filterField']
        delete config['searchValue']
        delete config['searchField']
        delete config['q']
      }

      const jobRequest = {
        type: 'sheet',
        operation: 'mutate-records',
        source: sheetId,
        config,
        trigger: 'immediate',
        mode: 'foreground',
        progress: 100,
      }

      return axios
        .post(`${import.meta.env.VITE_BASE_API_URL}/jobs`, jobRequest, {
          headers,
        })
        .then((response) => response.data.data)
    },
  })

  // Here is all the logic related to generating ffql.
  const {
    mutate: generateQuery,
    isError: queryIsError,
    isSuccess: queryIsSuccess,
    data: queryResponse,
  } = useMutation({
    mutationFn: async (command: string): Promise<GeneratedQueryResponse> => {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }
      const config = {
        sheetId,
        command,
        model: 'chatgpt',
        modelParams: {
          modelName: 'gpt-4',
        },
      }

      return axios
        .post(
          `${import.meta.env.VITE_BASE_API_URL}/assistant/generate-query`,
          config,
          { headers }
        )
        .then((response) => response.data)
    },
  })

  const ask = (command: string) => generateMutation(command)

  const apply = (
    mutateRecord: string,
    mutationId?: string,
    searchFields?: SearchFields,
    selection?: {
      type: 'all' | 'none'
      exceptions: string[]
    }
  ) => applyMutation({ mutateRecord, mutationId, searchFields, selection })

  // Consult the AI
  return {
    ask,
    apply,
    generateQuery,
    mutationIsSuccess,
    mutationIsError,
    mutationResponse,
    applyIsSuccess,
    applyIsError,
    applyResponse,
    queryIsSuccess,
    queryIsError,
    queryResponse,
    isError: mutationIsError || applyIsError || queryIsError,
  }
}
