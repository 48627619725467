import { Spinner } from '@flatfile/design-system'
import styled from 'styled-components'

import { Reveal } from '../../../elements/Reveal'

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  .spinner {
    height: 40px;
    width: 40px;
  }
`

export const FilesLoadingState = () => {
  return (
    <Reveal delay={200}>
      <SpinnerContainer data-testid='loading-state'>
        <Spinner />
      </SpinnerContainer>
    </Reveal>
  )
}
