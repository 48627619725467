import { Icon } from '@flatfile/design-system'
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { FormLabel } from './FormLabel'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-left: 10px;
  border: 1px solid var(--color-border-strong);
  border-radius: 2px;

  textarea {
    flex-grow: 1;
    height: 34px;
    border: none;
    border-radius: 5px;
    white-space: break-spaces;
    outline: none;
    resize: none !important;
    font-family: var(--text-font);
    font-size: 14px;
    padding: 8px 0 8px 0 !important;

    ::placeholder {
      color: var(--color-text-ultralight);
    }
  }
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 10px 7px 0;
  padding-left: 10px;
  padding-right: 6px;
  border-radius: 5px;
  background-color: var(--color-page-100);
  white-space: nowrap;
  color: var(--color-title);
  font-size: 14px;
  font-weight: 600;
  height: 20px;
  gap: 4px;

  svg:hover {
    cursor: pointer;
  }

  path {
    stroke: currentColor;
    stroke-width: 0.5px;
  }

  &.invalid {
    color: var(--color-danger);
    background-color: var(--color-danger-light);
  }
`

export interface ITag {
  label: string
  invalid: boolean
}

interface TagTextFieldProps {
  label: string
  tags: ITag[]
  tagLimit?: number
  setTags: Dispatch<SetStateAction<ITag[]>>
  setHasInput?: (hasInput: boolean) => void
  placeholder?: string
  validateInput?: (i: string, existingInputs: string[]) => boolean
}

export const TagTextArea = ({
  label,
  placeholder,
  tags,
  tagLimit,
  setHasInput,
  setTags,
  validateInput,
}: TagTextFieldProps) => {
  const [input, setInput] = useState('')
  const [isKeyReleased, setIsKeyReleased] = useState(false)

  useEffect(() => {
    if (!setHasInput) return

    if (input.trim() || tags.length) {
      setHasInput(true)
    } else {
      setHasInput(false)
    }
  }, [input, tags])

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setInput(value)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }

  const addTag = useCallback(() => {
    if (tags.length === tagLimit) {
      return
    }
    const trimmedInput = input.trim()
    if (trimmedInput.length) {
      const isInputValid = validateInput
        ? validateInput(
            trimmedInput,
            tags.map((t) => t.label)
          )
        : true
      setTags((prevState) => [
        ...prevState,
        { label: trimmedInput, invalid: !isInputValid },
      ])
      setInput('')
    }
  }, [input, tagLimit])

  const onKeyDown = (e: KeyboardEvent) => {
    const { key } = e

    switch (key) {
      case ',':
      case ' ':
      case 'ArrowRight':
      case 'Enter': {
        e.preventDefault()
        addTag()
        break
      }
      case 'Backspace': {
        if (!input.length && tags.length && isKeyReleased) {
          e.preventDefault()
          const tagsCopy = [...tags]
          const removedTag = tagsCopy.pop()

          setTags(tagsCopy)
          if (removedTag) {
            setInput(removedTag.label)
          }
        }
        break
      }
    }
    setIsKeyReleased(false)
  }

  const deleteTag = (index: number) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index))
  }

  return (
    <Content>
      <FormLabel>{label}</FormLabel>
      <TagContainer>
        {tags.map((tag, index) => (
          <Tag
            data-testid={`${tag.label}-tag`}
            className={tag.invalid ? 'invalid' : undefined}
            key={index}
          >
            {tag.label}
            <Icon onClick={() => deleteTag(index)} name='cross' size={16} />
          </Tag>
        ))}
        <textarea
          value={input}
          placeholder={tags.length === 0 ? placeholder : undefined}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={onChange}
          onBlur={() => addTag()}
          role='textarea'
        />
      </TagContainer>
    </Content>
  )
}
