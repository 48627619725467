import styled from 'styled-components'
import { JobsStatus } from '../packages/JobsStatus'
import { Spacer } from './Spacer'
import { useState } from 'react'

export const PrimaryHeading = styled.h1`
  align-items: center;
  color: var(--color-title);
  font-family: var(--text-font);
  font-size: var(--size-h1);
  margin: 0;
  line-height: 1;

  &.has-margin {
    margin-bottom: 1em;
  }
`

const HeadingRowWrapper = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  ${({ height }) => (height ? `min-height: ${height}px;` : '')}
`

export const HeadingRow = ({
  children,
  action,
  height = 40,
}: {
  children?: JSX.Element
  action?: JSX.Element | null
  height?: number
}) => {
  const [openPanel, setOpenPanel] = useState<string | null>(null)

  const handleOpenPanel = (heading: string) => () => {
    setOpenPanel(heading)
  }

  const handleClosePanel = () => {
    setOpenPanel(null)
  }
  return (
    <HeadingRowWrapper height={height}>
      {children} <Spacer />
      <JobsStatus
        isOpen={openPanel === 'Jobs'}
        onOpen={handleOpenPanel('Jobs')}
        onClose={handleClosePanel}
      />
      {action}
    </HeadingRowWrapper>
  )
}
