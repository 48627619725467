import { EventTopic } from '@flatfile/api'
import { usePubNub } from 'pubnub-react'
import { useEffect } from 'react'

export type EventCallback = (event: any, message: Record<string, any>) => void

export const useEventSubscriber = (
  topics: '*' | EventTopic[],
  callback: EventCallback
) => {
  try {
    const pubnub = usePubNub()
    useEffect(() => {
      const cb = (event: any) => {
        try {
          const msg = JSON.parse(event?.message || '{}')
          if (topics === '*' || topics.includes(msg.topic)) {
            callback(event, msg)
          }
        } catch (e) {
          console.warn('PubNub Listener Error\n', e)
        }
      }
      const listener = { message: cb }
      pubnub.addListener(listener)
      return () => {
        pubnub.removeListener(listener)
      }
    }, [])
  } catch (e) {
    console.warn('Pubnub failure', e)
  }
}

export function initPubnubSubscription(spaceId: string) {
  try {
    const pubnub = usePubNub()
    useEffect(() => {
      const channels = [`space.${spaceId}`]
      pubnub.subscribe({ channels })
      return () => {
        pubnub.unsubscribe({ channels })
      }
    }, [spaceId])
  } catch (e) {
    console.warn('Pubnub failure', e)
  }
}

const jobEventTopics = [
  EventTopic.Jobcreated,
  EventTopic.Jobupdated,
  EventTopic.Jobcompleted,
  EventTopic.Jobfailed,
]

/**
 * Todo this is likely unneccessary
 * @param onEvent
 */
export const useJobsSubscription = (onEvent: EventCallback) => {
  useEventSubscriber(jobEventTopics, (event) => {
    /* c8 ignore start */
    onEvent(event, JSON.parse(event.message))
  })
  /* c8 ignore stop */
}

export type BaseEvent = {
  topic: string
  target: string
  context: Record<string, string>
}
