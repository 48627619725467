import { Plan, Property } from '@flatfile/api'
import { Spinner, Tooltip } from '@flatfile/design-system'
import { WarningIcon } from '@flatfile/shared-ui'
import React, { FC, useMemo, useState } from 'react'
import ReactSelect, { components } from 'react-select'
import styled from 'styled-components'
import { PlanController } from '../../../../api/controllers/PlanController'
import {
  Rule,
  RuleController,
} from '../../../../api/controllers/RuleController'
import { FieldOption } from '../../../../api/controllers/SheetController'
import { useController } from '../../../../api/controllers/useController'
import { Observable } from '../../../../api/observable'
import { NewSelectStyles } from '../../../../elements/FancySelect'
import { FieldWrapper } from '../../../../elements/FieldWrapper'
import { MatchField } from '../../../../elements/MatchField'
import { MatchIconWrap } from '../../../../elements/MatchIconWrap'
import { Row } from '../../../../elements/Row'
import { MatchIcon } from '../../../../resources/icons/MatchIcon'
import { Code } from '../elements'

/* These label styles help the description tooltip appear correctly */
const LabelWrapper = styled.span`
  position: absolute;
  top: 6px;
`

const LabelText = styled.span`
  width: 100%;
  display: inline-block;
  height: 100%;
`

export const getOptionProps = (option?: FieldOption, menu?: boolean) => {
  if (!option) return {}
  return {
    'data-tooltip-id': option.description
      ? `${menu ? option.value : ''}field-description`
      : undefined,
    'data-tooltip-content': option.description,
    children: option.label + (option.required ? ' *' : ''),
  }
}

export function RuleCard({
  observable,
  rule,
  planController,
  onChange,
  onFocus,
  disabled = false,
}: Props_RuleCard) {
  const [destVal, setDestVal] = useState<string | undefined | null>(rule.dest)

  const controller = useController(RuleController, rule, planController)
  const groups = useMemo(
    () => planController.getDestinationOptions(),
    [controller]
  )
  const options = useMemo(
    () => planController.dest.getFieldOptions(),
    [controller]
  )
  const currentOption = useMemo(
    () => options.find((o) => o.value === destVal),
    [options, destVal]
  )

  return (
    <FieldWrapper
      key={rule.dest ? `dest_${rule.src}` : `src_${rule.src}`}
      onMouseEnter={() => {
        onFocus?.(rule)
      }}
      data-testid='edge-row'
    >
      <Row>
        <MatchField data-testid='source-field-label'>
          <RawLabel prop={controller.srcProp} />
        </MatchField>
        <MatchIconWrap>
          {observable?.isLoading ? (
            <Spinner />
          ) : observable?.error ? (
            <WarningIcon data-testid='warning-icon' name='alertCircle' />
          ) : (
            <MatchIcon />
          )}
        </MatchIconWrap>
        <MatchField data-testid='destination-field-select'>
          <ReactSelect
            isDisabled={disabled}
            aria-label='option-select'
            menuPortalTarget={document.body}
            isClearable
            options={groups}
            getOptionValue={(o) => o.value}
            value={options.find((o) => o.value === destVal)}
            minMenuHeight={300}
            menuPlacement='auto'
            components={{
              IndicatorSeparator: () => null,
              SingleValue: ({ children, ...props }) => (
                <components.SingleValue {...props}>
                  <LabelWrapper {...getOptionProps(currentOption)} />
                </components.SingleValue>
              ),
              Option: ({ children, ...props }) => {
                const optionProps = getOptionProps(props.data, true)
                const tooltipId = optionProps['data-tooltip-id']
                return (
                  <components.Option {...props}>
                    {tooltipId ? (
                      <Tooltip
                        id={tooltipId}
                        place='right'
                        float
                        positionStrategy='fixed'
                      />
                    ) : null}
                    <LabelText {...optionProps} />
                  </components.Option>
                )
              },
            }}
            styles={NewSelectStyles}
            onChange={(newValue) => {
              setDestVal(newValue?.value)
              onChange?.({ ...rule, dest: newValue?.value })
            }}
          />
        </MatchField>
      </Row>
    </FieldWrapper>
  )
}

const RawLabel: FC<{ prop?: Property | null }> = ({ prop }) => {
  if (!prop) {
    return <div>no source</div>
  }
  if (prop.label === prop.key) {
    return <Code>{prop.key}</Code>
  }
  return <span>{prop.label}</span>
}

type Props_RuleCard = {
  observable?: Observable<Plan>
  rule: Rule
  planController: PlanController
  onFocus?: (rule: Rule) => void
  onChange?: (rule: Rule) => void
  disabled?: boolean
}
